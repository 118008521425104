import React, { useEffect, useRef, useState } from "react";

import { AdbSearch } from "../adb-icons/AdbIcons";
import { AdbListBoxOption } from "./AdbListBox";
import { iconClasses } from "../utils/commonTailwindClasses";

/*
  Seperate component is created for input as input is added when listbox is opened,
  so only way to attach ref to it is to create a new component so that will be
  rendered by React when listbox is opened. If the JSX was written with other JSX
  of listbox, then React won't attach ref to it as it will be dynamically generated.
*/
function SearchTermInput({ value, onChange }) {
  const searchInputRef = useRef(null);

  useEffect(() => {
    setTimeout(function () {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    });
  }, []);

  return (
    <input
      type="text"
      className="tw-w-full tw-rounded tw-text-brand-text-gray placeholder:tw-text-brand-icon-gray"
      placeholder="Search"
      value={value}
      onChange={onChange}
      ref={searchInputRef}
    />
  );
}

function OptionsWithSearch({
  showSearch = true,
  data,
  dataFilter,
  selectedOption,
  optionRenderer,
}) {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <>
      {showSearch && (
        <>
          <div className="tw-flex tw-h-10 tw-items-center tw-gap-2.5 tw-px-3">
            <AdbSearch className={iconClasses} />
            <SearchTermInput
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div className={`tw-max-h-40 tw-overflow-y-auto`}>
            {data.filter(dataFilter(searchTerm)).map((option) => (
              <AdbListBoxOption option={option} selectedOption={selectedOption}>
                {optionRenderer(option)}
              </AdbListBoxOption>
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default OptionsWithSearch;