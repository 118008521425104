import { updateCampaign } from "../../service";

const handleSubmitCampaignSettingChange = async (campaignType, cId, newBudget, biddingStrategy, campaigns, onSuccess, onError, newBaseBudget) => {
    try {
        let bidding;
        if (biddingStrategy) {
            bidding = { ...(campaigns.find(({ campaignId }) => cId === campaignId).bidding) }
            bidding.strategy = biddingStrategy
        }
        const updateData = { campaign_type: campaignType, campaign_id: cId, bidding, daily_budget: newBudget, base_budget: newBaseBudget };

        await updateCampaign(updateData);
        const idx = campaigns.findIndex(({ campaignId }) => cId === campaignId)

        const updatedCampaigns = [...campaigns];

        if (newBudget) {
            updatedCampaigns[idx] = {...updatedCampaigns[idx], daily_budget: newBudget};
        }
        if (newBaseBudget) {
            updatedCampaigns[idx] = {...updatedCampaigns[idx], base_budget: newBaseBudget};
        }
        if (bidding) {
            updatedCampaigns[idx] = {...updatedCampaigns[idx], bidding: bidding};
        }
        onSuccess && onSuccess(updatedCampaigns)
    } catch (e) {
        console.error(e)
        onError && onError(e)
    }
}

export {
    handleSubmitCampaignSettingChange
}