import React, { useState } from "react";
import moment from "moment";

import { useDateRange } from "../../../context/DateRangeContext";
import { AdbCalendar } from "../../../adb-icons/AdbIcons";
import CustomDateRangePicker from "../../../ui/CustomDateRangePicker";
import { iconClasses } from "../../../utils/commonTailwindClasses";

/**
 * Renders Left part of the Main header which includes Date picker.
 */
function HeaderLeft() {
  const {
    rawFromDate,
    rawToDate,
    updateDateRange,
    dateRangeLabel,
    prevRawFromDate,
    prevRawToDate,
    updatePrevDateRange,
    prevDateRangeLabel,
    prevDateRangeDefaultStaticRanges,
    currentDateRangeStaticRanges,
  } = useDateRange();
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [showPrevDateRangeModal, setShowPrevDateRangeModal] = useState(false);

  return (
    <div className="tw-flex tw-h-8 tw-items-stretch tw-gap-2">
      <span
        className="tw-ml-auto tw-inline-flex tw-items-center tw-gap-2 tw-rounded-md tw-bg-white tw-px-2.5 tw-py-0.5 tw-text-xs tw-font-medium tw-capitalize tw-text-brand-text-gray tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-cursor-pointer hover:tw-bg-gray-50"
        onClick={() => setShowDateRangeModal(true)}
      >
        <AdbCalendar className={iconClasses} />
        {dateRangeLabel ||
          `${moment(rawFromDate).format("DD-MM-YYYY")} - ${moment(rawToDate).format("DD-MM-YYYY")}`}
      </span>
      <span className="tw-self-center tw-text-xs tw-font-medium">
        compared to
      </span>
      <span
        className="tw-ml-auto tw-inline-flex tw-items-center tw-gap-2 tw-rounded-md tw-bg-white tw-px-2.5 tw-py-0.5 tw-text-xs tw-font-medium tw-capitalize tw-text-brand-text-gray tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-cursor-pointer hover:tw-bg-gray-50"
        onClick={() => setShowPrevDateRangeModal(true)}
      >
        <AdbCalendar className={iconClasses} />
          Previous period: {`${moment(prevRawFromDate).format("DD-MM-YYYY")} - ${moment(prevRawToDate).format("DD-MM-YYYY")}`}
      </span>
      {showPrevDateRangeModal && (
        <CustomDateRangePicker
          staticRanges={prevDateRangeDefaultStaticRanges}
          rawFromDate={prevRawFromDate}
          rawToDate={prevRawToDate}
          onChange={updatePrevDateRange}
          hide={() => setShowPrevDateRangeModal(false)}
        />
      )}
      {showDateRangeModal && (
        <CustomDateRangePicker
          staticRanges={currentDateRangeStaticRanges}
          rawFromDate={rawFromDate}
          rawToDate={rawToDate}
          onChange={updateDateRange}
          hide={() => setShowDateRangeModal(false)}
        />
      )}
    </div>
  );
}

export default HeaderLeft;
