import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.css';
import 'react-loading-skeleton/dist/skeleton.css'
import "react-resizable/css/styles.css";

import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './config';
import { ProvideAuth } from "./context/AuthContext";
import { ProvideNotification } from "./context/NotificationContext";
import { ProvideNavContext } from './context/NavContext';
import ErrorBoundary from './layout/Components/ErrorBoundary';
import { getAccessTokenFromQuery } from './utils/locationUtils';
import { isSharedEnv } from './service';
import { clearConflictingKeysForSharedEnv } from './utils/localStorageUtils';

window.onbeforeunload = function () {
  if (window.location.href.includes("campaign_launcher")) {
    return "If you reload this page, your changes will be lost";
  } else {
    //Don't return anything
  }
}

if (isSharedEnv) {
  const accessToken = getAccessTokenFromQuery()
  if (accessToken) {
    // To avoid sending outdated info in request header from localstorage
    clearConflictingKeysForSharedEnv()

    localStorage.setItem('credentials', JSON.stringify({ accessToken, refreshToken: accessToken }))
    window.location.href = window.location.pathname
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Router basename={config.basename}>
      <ErrorBoundary>
        <ProvideNavContext>
          <ProvideNotification>
            <ProvideAuth>
              <App />
            </ProvideAuth>
          </ProvideNotification>
        </ProvideNavContext>
      </ErrorBoundary>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
