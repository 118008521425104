export const COLORS = {
    "red": "text-c-red",
    "orange": "text-c-orange",
    "green": "text-c-trendgr",
    "black": "",
    "cyan": "text-c-gr"
}

const DELIVERY_STATUS_VALUE_TO_LABEL = {
    "DELIVERING": "Delivering",
    "ENDED": "Ended",
    "READY_TO_DELIVER": "Ready to deliver",
    "INACTIVE": "Inactive",
}

export const CAMPAIGN_DELIERY_STATUS_VALUE_TO_LABEL = {
    ...DELIVERY_STATUS_VALUE_TO_LABEL,
    "SUSPENDED": "Suspended",
    "ADGROUPS_NOT_RUNNING": "Ad groups not running",
}

export const ADGROUP_DELIVERY_STATUS_VALUE_TO_LABEL = {
    ...DELIVERY_STATUS_VALUE_TO_LABEL,
    "CREATIVES_NOT_RUNNING": "Creatives not running",
}

export const DELIVERY_STATUS_TEXT_COLOR = {
    "DELIVERING": COLORS["green"],
    "ENDED": COLORS["black"],
    "READY_TO_DELIVER": COLORS["cyan"],
    "INACTIVE": COLORS["orange"],
    // Campaign specific
    "SUSPENDED": COLORS["orange"],
    "ADGROUPS_NOT_RUNNING": COLORS["orange"],
    // Adgroup specific
    "CREATIVES_NOT_RUNNING": COLORS["orange"],
}

const BID_STRATEGY_VALUE_TO_LABEL = {
    "SPEND_BUDGET_IN_FULL": "Spend Budget in Full",
    "PRIORITIZE_KPI_TARGET": "Prioritize KPI Target"
}

export const CAMPAIGN_BID_STRATEGY_VALUE_TO_LABEL = {
    ...BID_STRATEGY_VALUE_TO_LABEL,
}

export const ADGROUP_BID_STRATEGY_VALUE_TO_LABEL = {
    ...BID_STRATEGY_VALUE_TO_LABEL,
    "USE_CAMPAIGN_STRATEGY": "Use Campaign Strategy"
}

export const RECURRENCE_TIME_PERIOD_VALUE_TO_LABEL = {
    "DAILY": "Daily",
    "MONTHLY": "Monthly"
}

export const FREQUENCY_TIME_UNIT_VALUE_TO_LABEL = {
    "DAYS": "days",
    "HOURS": "hours",
}
