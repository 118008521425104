import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import windowSize from 'react-window-size';
import Tour from 'reactour';
import { Badge } from 'react-bootstrap';
import Aux from "../../../../../hoc/_Aux";
import NavIcon from "./../NavIcon";
import NavBadge from "./../NavBadge";
import * as actionTypes from "../../../../../store/actions";

const steps = [
    {
        selector: '.step-tour-rulesets',
        content: 'Rulesets are the fundamental building blocks for your PPC automation. Rulesets allow you to specify actions (like promote a keyword, increase bid, etc) to be taken when a specific criteria is met (e.g., when ACoS goes above a certain threshold). You can use a ruleset across multiple campaigns and ad groups.',
    },
    {
        selector: '.step-tour-campaigns',
        content: "Campaign manager gives a high level overview of all your campaigns. You can configure bid management, keyword harvesting, product harvesting and category harvesting by attaching rulesets for each campaigns. You can also control your budgets in Campaign Manager.",
    },
    {
        selector: '.step-tour-rec',
        content: "Once you've configured your keyword, product or category harvesting for campaigns, you can view corresponding recommendations via the Recommendations sections for Keywords, Products, Categories and Bids",
    },
    {
        selector: '.step-tour-change-history',
        content: 'Change History presents you with historical records of all changes make via our platform. Change history specific to Ad Groups are also available alongside Recommendations',
    },
    {
        selector: '.step-tour-product-explore',
        content: 'Product Explorer is the ASIN Analyzer which allows you to track the health of your entire portfolio. You can see how your individual ASINs are performing and track metrics for specific time periods using the date filters. ',
    },
    {
        selector: '.step-tour-target-analyzer',
        content: 'Target Analyzer lists down all the targets you are targeting for your ad-campaign and lets you analyze how each target is performing, It in turn helps you quickly make decision on how to update target for better results.',
    }
];

class NavItem extends Component {

    
    render() {
        let itemTitle = <>
            {this.props.item.title} {this.props.item.badge && <Badge variant={this.props.item.badge.variant} style={{ position: "relative", top: "-1px" }}>{this.props.item.badge.text}</Badge>} {this.props.item.subscript && <sub>{this.props.item.subscript}</sub>}
        </>;
        if (this.props.item.icon) {
            itemTitle = <span className="pcoded-mtext">{itemTitle}</span>;
        }

        let itemTarget = '';
        if (this.props.item.target) {
            itemTarget = '_blank';
        }

        let subContent;
        if(this.props.item.external) {
            subContent = (
                <a href={this.props.item.url} target='_blank' rel='noopener noreferrer'>
                    <NavIcon items={this.props.item}/>
                    {itemTitle}
                    <NavBadge layout={this.props.layout} items={this.props.item}/>
                </a>
            );
        } else if (this.props.item.startTour) {
            subContent = (
                <a href="#" rel='noopener noreferrer' onClick={() => { this.setState({isTourOpen: true});  }}>
                    <NavIcon items={this.props.item}/>
                    {itemTitle}
                    <NavBadge layout={this.props.layout} items={this.props.item}/>
                </a>
            );
        } else if (this.props.item.startChat) {
            const openChatWidget = () =>{
                if (window?.HubSpotConversations) {
                    window.HubSpotConversations.widget.load();
                    window.HubSpotConversations.widget.open();
                }
            }

            subContent = (
                <a href="#" rel='noopener noreferrer' onClick={openChatWidget}>
                    <NavIcon items={this.props.item}/>
                    {itemTitle}
                    <NavBadge layout={this.props.layout} items={this.props.item}/>
                </a>
            );
        } else {
            subContent = (
                <NavLink style={{fontWeight: 500, letterSpacing: 0.2}} to={this.props.item.url} className={`nav-link step-tour-${(this.props.item.data || {}).step}`} exact={true} target={itemTarget}>
                    <NavIcon items={this.props.item}/>
                    {itemTitle}
                    <NavBadge layout={this.props.layout} items={this.props.item}/>
                </NavLink>
            );
        }
        let mainContent = '';
        if (this.props.layout === 'horizontal') {
            mainContent = (
                <li onClick={this.props.onItemLeave}>{subContent}</li>
            );
        } else {
            if (this.props.windowWidth < 992) {
                mainContent = (
                    <li className={this.props.item.classes} onClick={this.props.onItemClick}>{subContent}</li>
                );
            } else {
                mainContent = (
                    <li className={this.props.item.classes}>{subContent}</li>
                );
            }
        }

        return (
            <Aux>
                {mainContent}
                <Tour
                    steps={steps}
                    isOpen={(this.state || {}).isTourOpen || false}
                    onRequestClose={() => {this.setState({isTourOpen: false}); }} />
            </Aux>
        );
    }
}

const mapStateToProps = ({root}) => {
    return {
        layout: root.layout,
        collapseMenu: root.collapseMenu
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onItemClick: () => dispatch({type: actionTypes.COLLAPSE_MENU}),
        onItemLeave: () => dispatch({type: actionTypes.NAV_CONTENT_LEAVE})
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (windowSize(NavItem)));
