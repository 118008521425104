import React from "react";

import HeaderLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";

import { NAVBAR_AND_HEADER_DIMENSIONS } from "../../../store/constant";

/**
 * Main header of the app which is shown on top side of the page.
 */
function MainHeader() {
  return (
    <header
      className={`main-header tw-sticky tw-top-0 tw-z-[1028] tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-200 tw-bg-white tw-px-5`}
      style={{
        height: `${NAVBAR_AND_HEADER_DIMENSIONS.HEADER_HEIGHT}px`,
        width: `calc(100%-${NAVBAR_AND_HEADER_DIMENSIONS.COLLAPSED_NAV_WIDTH}px`
      }}
    >
      <HeaderLeft />
      <HeaderRight />
    </header>
  );
}

export default MainHeader;
