import React from 'react';
import { ALLOWED_ENTITIES, ENTITY } from '../../constants/sharedEntity';

// TODO: change routes for dsp sp
const Dashboard = React.lazy(() => import('../../features/dashboard/CustomDashboardContainer').catch(e => window.location.reload()));

const ENTITY_TO_COMPONENT_MAP = {
    [ENTITY.DASHBOARD]: Dashboard
}

export const routes = [...ALLOWED_ENTITIES].map(entity => (
    {
        key: `guest-${entity}`,
        Component: ENTITY_TO_COMPONENT_MAP[entity]
    }
))