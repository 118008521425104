const DEMO = {
    BLANK_LINK: "#!",
};

const TARGET_INFO_KEYWORDS = {
    'keyword_broad': 'KB',
    'keyword_phrase': 'KP',
    'keyword_exact': 'KE',
    'auto': 'AT',
    'product': 'PT',
    'category': 'CT'
}

const TARGET_TYPE_MAP = {
    'keyword_broad': "Broad Keyword",
    'keyword_exact': "Exact Keyword",
    'keyword_phrase': "Phrase Keyword",
    'product_category': "Product Category",
    'product': "Product",
    'negative_keyword_exact': "Negative Exact Keyword",
    'negative_keyword_phrase': "Negative Phrase Keyword",
    'negative_product': "Negative Product"
};

const RULE_TYPE_TO_TASK_TYPE = {
    'bid': 'bid_optimization',
    'target': 'target_management',
    'budget': 'budget_management',
    'campaign': 'campaign_management',
    'sov': 'sov_optimization',
}

const MATCH_TYPE_MAP = {
    'negativeExact': "Negative Exact",
    'negativePhrase': "Negative Phrase"
}

const COUNTRY_TO_NUMBER_FORMAT = {
	"US": "en-US",
	"CA": "en-CA",
	"MX": "en-MX",
	"BR": "en-BR",
	"UK": "en-UK",
	"DE": "en-DE",
	"FR": "en-FR",
	"ES": "en-ES",
	"IT": "en-IT",
	"NL": "en-NL",
	"AE": "en-AE",
	"SE": "en-SE",
	"SE": "en-SE",
	"PL": "en-PL",
	"JP": "en-JP",
	"AU": "en-AU",
	"SG": "en-SG",
    "IN": "en-IN",
    "EG": "en-EG",
    "BE": "en-BE"
}

const COUNTRY_TO_DOMAIN = {
    "US": "https://www.amazon.com/dp/",
    "CA": "https://www.amazon.ca/dp/",
    "UK": "https://www.amazon.co.uk/dp/",
    "DE": "https://www.amazon.de/dp/",
    "FR": "https://www.amazon.fr/dp/",
    "ES": "https://www.amazon.es/dp/",
    "IT": "https://www.amazon.it/dp/",
    "IN": "https://www.amazon.in/dp/",
    "NL": "https://www.amazon.nl/dp/",
    "JP": "https://www.amazon.co.jp/dp/",
    "AU": "https://www.amazon.com.au/dp/",
    "AE": "https://www.amazon.ae/dp/",
    "BR": "https://www.amazon.com.br/dp/",
    "MX": "https://www.amazon.com.mx/dp/",
    "SA": "https://www.amazon.sa/dp/",
    "SG": "https://www.amazon.sg/dp/",
    "BE": "https://www.amazon.com.be/dp"
}

const COUNTRY_TO_VENDOR_CENTRAL_URL = {
    "US": "https://vendorcentral.amazon.com",
    "CA": "https://vendorcentral.amazon.ca",
    "BR": "https://vendorcentral.amazon.com.br",
    "MX": "https://vendorcentral.amazon.com.mx",
    "ES": "https://vendorcentral.amazon.es",
    "UK": "https://vendorcentral.amazon.co.uk",
    "FR": "https://vendorcentral.amazon.fr",
    "NL": "https://vendorcentral.amazon.nl",
    "DE": "https://vendorcentral.amazon.de",
    "IT": "https://vendorcentral.amazon.it",
    "SE": "https://vendorcentral.amazon.se",
    "PL": "https://vendorcentral.amazon.pl",
    "EG": "https://vendorcentral.amazon.me",
    "TR": "https://vendorcentral.amazon.com.tr",
    "AE": "https://vendorcentral.amazon.me",
    "EG": "https://vendorcentral.amazon.me",
    "SA": "https://vendorcentral.amazon.me",
    "IN": "https://www.vendorcentral.in",
    "SG": "https://vendorcentral.amazon.com.sg",
    "AU": "https://vendorcentral.amazon.com.au",
    "JP": "https://vendorcentral.amazon.co.jp",
    "BE": "https://vendorcentral.amazon.com.be"
}

const COUNTRY_TO_SELLER_CENTRAL_URL = {
    "US": "https://sellercentral.amazon.com",
    "CA": "https://sellercentral.amazon.ca",
    "BR": "https://sellercentral.amazon.com.br",
    "MX": "https://sellercentral.amazon.com.mx",
    "ES": "https://sellercentral-europe.amazon.com",
    "UK": "https://sellercentral-europe.amazon.com",
    "FR": "https://sellercentral-europe.amazon.com",
    "NL": "https://sellercentral.amazon.nl",
    "DE": "https://sellercentral-europe.amazon.com",
    "IT": "https://sellercentral-europe.amazon.com",
    "SE": "https://sellercentral.amazon.se",
    "PL": "https://sellercentral.amazon.pl",
    "EG": "https://sellercentral.amazon.eg",
    "TR": "https://sellercentral.amazon.com.tr",
    "AE": "https://sellercentral.amazon.ae",
    "IN": "https://sellercentral.amazon.in",
    "SG": "https://sellercentral.amazon.sg",
    "AU": "https://sellercentral.amazon.com.au",
    "JP": "https://sellercentral.amazon.co.jp",
    "EG": "https://sellercentral.amazon.eg",
    "BE": "https://sellercentral.amazon.com.be",
    "SA": "https://sellercentral.amazon.sa"
}

const HELP_TEXT = {
    'automation_frequency_-1': <div className="tooltip-wrapper">Run the automation as soon as SOV data is available</div>,
    "accounts_manager_desc": <div className="tooltip-wrapper">A single Adbrew account can have a combination of multiple accounts (advertising and brands) associated with it</div>,
    "user_account_management_from": (<div className="tooltip-wrapper">The accounts on the left are ones that this user does not have access to. In order to grant this user access to 1 or more of these accounts, select the desired accounts and move them to the right by clicking on them </div>),
    "user_account_management_to": <div className="tooltip-wrapper">The accounts on the right are ones that this user can access. In order to revoke this user's access to 1 or more of these accounts, select the desired accounts and move them to the left by clicking on them </div>,
    "user_role_admin": <div className="tooltip-wrapper">An Admin is a super user and can manage, edit and remove other users and their access</div>,
    "user_role_user": <div className="tooltip-wrapper">These are regular users who have all privileges except that they cannot manage, edit or remove other users and their access</div>,
    "rulesets_description": <div className="tooltip-wrapper">Rulesets are a set of conditions and actions that can help you manage and automate bids, keywords or ASIN movements and budgets.</div>,
    "rulesets_type_description_budget": <div className="tooltip-wrapper">Budget rules can be leveraged to dynamically modify budget allocation, or plan ahead for special events or days.</div>,
    "rulesets_type_description_bid": <div className="tooltip-wrapper">An ordered collection of rules, each consisting of a criteria and corresponding action (increase bid, decrease bid or pause target). Used to control bids for keywords and product targets. </div>,
    "rulesets_type_description_target": <div className="tooltip-wrapper">These can be used to identify search terms or ASINs that satisfy a certain criteria and then target them as keywords or product targets in other campaigns.</div>,
    "rulesets_type_description_campaign": <div className="tooltip-wrapper">These can be used to identify campaigns that satisfy certain criteria and then apply corresponding action (update bidding strategy, update placement bids, update state, or update budget).</div>,
    "rulesets_type_description_sov": <div className="tooltip-wrapper">These can be used to callibrate bidding on keywords based on their ranking and share of voice.</div>,
    "condition_value_desc": 
        <div className="tooltip-wrapper" style={{"width": "600px"}}>
            You can either use a fixed absolute value like 100, 1000, etc or you can use the following <br />metrics which Adbrew calculates for you at the time of applying the <br />ruleset to specific keywords, or search terms.
            <ul>     
                <li><b>Target ACoS: </b> This represents the target ACoS you've set at the account level. </li>
                <li><b>Avg Spend: </b> This is the average spend.  </li>
                <li><b>Statistically Significant Clicks: </b> This is the minimum number of clicks required to <br />generate at least 1 order, calculated based on the overall conversion rate of the ad group. </li>
                <li><b>Current Bid: </b> Current Bid of the keyword. Only applicable for Bid Management rulesets. </li>
                <li><b>Max Affordable Bid: </b> This is the maximum bid for a keyword required to maintain the <br />target ACoS. It is calculated as Avg Product Price x Target ACOS x Conversion Rate </li>
                <li><b>P95 Clicks: </b> This represents the 95th percentile of clicks. If you want to identify <br />the top 5% of search terms or keywords with the highest clicks, you would specify a rule where clicks is greater than the P95 clicks.  </li>
                <li><b>P95 Orders: </b> Similar to P95 clicks, this represents the 95th percentile of orders. If <br />you would like to identify the top 5% of search terms or keywords with the highest orders, you would specify a rule where orders is greater than the P95 order. </li>
                <li><b>P75 Spend: </b> 75th percentile of spend. If you would like to identify the top 25% of search <br />terms or keywords with the highest spend, you would specify a rule where spend is greater than the P95 spend. </li>
                <li><b>P90 Conversion Rate: </b> 90th percentile of conversion rate. You can identify the top 10% <br />of search terms or keywords with the maximum conversion rate, by specifying a rule where conversion rate is greater than the P90 Conversion Rate  </li>
            </ul>
        </div>,
    'value_acos': <span>ACoS</span>,
    'value_target_acos': <span>Target ACoS you've set at the goal level, campaign level, <br />or account level (in that order).</span>,
    'value_current_bid': <span>Current Bid of the keyword.</span>,
    'value_pp_bid': <span>Current Bid of the product pages placement.</span>,
    'value_tos_bid': <span>Current Bid of the top of search placement.</span>,
    'value_ros_bid': <span>Current Bid of the Rest of Search placement.</span>,
    'value_max_bid': <span>Maximum bid for a keyword required to maintain the <br />target ACoS. It is calculated as <br /> Average Product Price x Target ACOS x Conversion Rate</span>,
    'value_cpc': <span>CPC of the target for the lookback period of the rule. If there are no clicks, CPC will be 0.</span>,
    'value_min_bid_threshold': <span>This is the "Minimum Bid" threshold that  <br />can be set at the campaign level</span>,
    'value_max_bid_threshold': <span>This is the "Maximum Bid" threshold that  <br />can be set at the campaign level</span>,
    'value_min_budget_threshold': <span>This is the "Min Budget" threshold that  <br />can be set at the campaign level</span>,
    'value_max_budget_threshold': <span>This is the "Max Budget" threshold that  <br />can be set at the campaign level</span>,
    'value_min_target_acos': <span>This is the Minimum Target ACOS threshold that <br />can be set at the campaign level</span>,
    'value_max_target_acos': <span>This is the Maximum Target ACOS threshold that <br />can be set at the campaign level</span>,
    'value_pp_max_bid': <span>Maximum bid for product pages placement.</span>,
    'value_tos_max_bid': <span>Maximum bid for top of search placement.</span>,
    'value_current_budget': <span>Current daily budget of the campaign.</span>,
    'value_p50_spend': <span>Average spend of all keywords, or search terms in the given period</span>,
    'value_statistically_significant_clicks': <span>This is the minimum number of clicks required to <br />generate at least 1 order, calculated <br />based on the overall conversion rate of the ad group.</span>,
    'value_statistically_significant_impressions': <span>This is the minimum number of impressions required to <br />generate at least 1 click, calculated <br />based on the overall CTR of the ad group.</span>,
    'value_p95_clicks': <span>This represents the 95th percentile of clicks. If you want to identify <br />the top 5% of search terms or keywords with the highest <br />clicks, you would specify a rule where clicks is greater than this.</span>,
    'value_p95_orders': <span>This represents the 95th percentile of orders. If <br />you would like to identify the top 5% of search terms <br />or keywords with the highest orders, you would specify <br />a rule where orders is greater than this.</span>,
    'value_p75_spend': <span>75th percentile of spend. If you would like to <br />identify the top 25% of search terms or keywords with <br /> the highest spend, you would specify a rule where <br />spend is greater than the P95 spend.</span>,
    'value_p90_conversion_rate': <span>90th percentile of conversion rate. You can <br />identify the top 10% of search terms or <br />keywords with the maximum conversion rate, by specifying <br />a rule where conversion rate is greater than the P90 Conversion Rate</span>,
    'value_adequate_impressions': <span>This is the "Adequate Impressions" threshold that  <br />can be set at the campaign level</span>,
    'value_adequate_spend': <span>This is the "Adequate Spend" threshold that <br />can be set at the campaign level</span>,
    'value_wasted_spend': <span>This is the "Wasted Spend" threshold that  <br />can be set at the campaign level</span>,
    'value_adequate_clicks': <span>This is the "Adequate Clicks" threshold that  <br />can be set at the campaign level</span>,
    'value_current_bid_acos_based_bid': <span>Current Bid * Target ACOS / ACOS</span>,
    'value_cpc_acos_based_bid': <span>CPC * Target ACOS / ACOS</span>,
    'value_budget': <span>Current daily budget of the campaign.</span>,
    'value_absolute': <span>Use this to specify an absolute number like 10, 100, etc</span>,
    'rule_action_desc': <span>Rule Actions are the actions that you want to perform if all <br />the conditions above are fulfilled. For instance, <br />in case of a target management rule, you can use <br />an action to promote a keyword or an ASIN ("Promote Target") <br />from an auto campaign to a manual campaign. Similarly, for <br />a bid management rule, you can configure an action to <br />increase or decrease the bid for a particular keyword or ASIN.</span>,
    'multiplier_desc': <span>Specify the multiplier for the chosen metric. <br />If you want to use the chosen metric as is, use 1</span>,
    'target_movement_source_desc': <div className="tooltip-wrapper">These are ad groups whose search terms will be evaluated against the rule</div>,
    'target_movement_destination_desc': <div className="tooltip-wrapper">Matching search terms (or ASINs in case of Product Targeting) will be added to this ad group</div>,
    'target_movement_targeting_desc': <div className="tooltip-wrapper">This will be the targeting type for the matching search terms. If the targeting type is Product Targeting or Negative ASIN targeting, system will automatically match only ASINs from the search term report</div>,
    'target_movement_ruleset_desc': <div className="tooltip-wrapper">This is the target management ruleset which will be used to identify the search terms or ASINs in the source ad groups.</div>,
    'target_movement_neg_phrase_desc': <div className='tooltip-wrapper'>When moving the search terms from source ad groups to destination ad group, you can specify which ad groups the search term should be added as negative phrase in.</div>,
    'target_movement_neg_exact_desc': <div className='tooltip-wrapper'>When moving the search terms from source ad groups to destination ad group, you can specify which ad groups the search term should be added as negative exact in.</div>,
    'campaign_manager_actions_AddLabel': <span>Add labels to your campaigns</span>,
    'campaign_manager_actions_RemoveLabels': <span>Remove labels from your campaigns</span>,
    'campaign_manager_actions_ForceRefreshCampaigns': <span>Use this to hard refresh your account data. It may <br />take a few minutes for data to update post this.</span>,
    'campaign_manager_actions_ShowCampaignThresholds': <span>Campaign Thresholds can be used to set different thresholds (spend, <br />impressions, etc) for each campaign, and these thresholds <br />can be used in rulesets. This allows you to have a single ruleset for <br /> multiple campaigns, while still having campaign specific thresholds.</span>,
    'campaign_manager_actions_HideCampaignThresholds': <span>Campaign Thresholds can be used to set different thresholds (spend, <br />impressions, etc) for each campaign, and these thresholds <br />can be used in rulesets. This allows you to have a single ruleset for <br /> multiple campaigns,  while still having campaign specific thresholds.</span>,
    'campaign_manager_actions_UpdateThresholds': <span>Campaign Thresholds can be used to set different thresholds (spend, <br />impressions, etc) for each campaign, and these thresholds <br />can be used in rulesets. This allows you to have a single ruleset for <br /> multiple campaigns,  while still having campaign specific thresholds.</span>,
    'campaign_manager_actions_LaunchGoal': <span>Use this to launch Adbrew's automated optimization strategy on <br />selected campaigns. Adbrew will automatically manage bids and <br />negative targeting based on your goal.</span>,
    'campaign_manager_actions_ApplyDaypartingStrategy': <span>Choose and enable Dayparting for selected campaigns</span>,
    'campaign_manager_actions_RemoveDaypartingStrategy': <span>Remove the associated Dayparting strategies for selected campaigns</span>,
    'campaign_manager_actions_UpdateBudgetAutomation': <span>Choose and enable budget ruleset for selected campaigns</span>,
    'campaign_manager_actions_UpdateBudget': <span>Enter and update budget for all selected campaigns</span>,
    'campaign_manager_actions_UpdateBaseBudget': <span>Enter and update base budget for all selected campaigns</span>,
    'campaign_manager_actions_EnableAutomation': <span>If automated rulesets are associated with selected <br />campaigns, this will enable automation for these campaigns.</span>,
    'campaign_manager_actions_DisableAutomation': <span>If automated rulesets are associated with selected <br />campaigns, this will disable automation for these campaigns.</span>,
    'campaign_manager_actions_PlotOnGraph': <span>Plot selected campaigns on the graph above.</span>,
    'campaign_manager_actions_UpdateCampaignState': <span>Select and update state for all selected campaigns</span>,
    'campaign_manager_actions_ApplyBidManagementRuleset': <span>Select and update bid management ruleset for all selected campaigns</span>,
    'campaign_manager_actions_ConfigureNegativeTargeting': <span>Configure negative targeting ruleset for all selected campaigns</span>,
    'campaign_manager_actions_ApplyCampaignManagementRuleset': <span>Select and update campaign management ruleset for all selected campaigns</span>,
    'campaign_manager_actions_ConfigureAutomation': <span>Configure automation for all selected campaigns</span>,
    'campaign_manager_actions_UpdateBiddingStrategy': <span>Update Bidding Strategy for all selected campaigns</span>,
    'product_explorer_actions_AdvertiseProducts': <span>Start advertising selected products in other campaigns</span>,
    'product_explorer_actions_UpdateState': <span>Select and update state for all selected product ads</span>,
    'product_explorer_actions_LaunchCampaigns': <span>Launch new campaigns for selected products</span>,
    'product_explorer_actions_AddLabel': <span>Add a label for selected products</span>,
    'product_explorer_actions_RemoveLabels': <span>Remove labels from selected products</span>,
    'product_explorer_actions_PlotOnGraph': <span>Plot selected products on the graph above</span>,
    'product_explorer_actions_TargetProducts': <span>Target selected products in campaigns</span>,
    'product_explorer_actions_CopyASINs': <span>Copy selected ASINs to clipboard</span>,
    'product_explorer_actions_TargetasNegativeProducts': <span>Add selected products as negative in campaigns</span>,
    'product_explorer_actions_LaunchGoal': <span>Launch and track goals for selected products</span>,
    'product_explorer_actions_AddasBrandTerms': <span>Add selected ASINs as brand ASINs</span>,
    'product_explorer_actions_AddTermsForProducts': <span>Add new hero, brand or competitor terms specific to selected ASINs</span>,
    'product_goals_desc': <div className="tooltip-wrapper">Create goals for a group of products to track their performance and progress against specific objectives (target ACoS, budget spend, etc).</div>,
    'one_time_goal_desc': <div className="tooltip-wrapper">One-time goals run for a fixed duration based on the start and end date</div>,
    'recurring_goal_desc': <div className="tooltip-wrapper">Recurring goals can repeat and reset based on a frequency during the time between start and end date. For e.g., you can set recurring quarterly goals for a year.</div>,
    'targets_analyzer_actions_PlotOnGraph': <span>Plot selected targets on the graph above</span>,
    'targets_analyzer_actions_AddKeywords': <span>Target selected keywords in other campaigns</span>,
    'targets_analyzer_actions_AddNegativeKeywords': <span>Add selected keywords as negative in campaigns</span>,
    'targets_analyzer_actions_AddProducts': <span>Target selected products in other campaigns</span>,
    'targets_analyzer_actions_AddNegativeProducts': <span>Add selected products as negative in campaigns</span>,
    'targets_analyzer_actions_UpdateState': <span>Choose and update state for selected targets</span>,
    'targets_analyzer_actions_UpdateBid': <span>Choose and update bids for selected targets</span>,
    'targets_analyzer_actions_UpdateBaseBid': <span>Choose and update base bids for selected targets</span>,
    'targets_analyzer_actions_AddasTerms': <span>Add selected targets as brand or competitor terms</span>,
    'search_terms_actions_PlotOnGraph': <span>Plot selected search terms on the graph above</span>,
    'search_terms_actions_AddKeywords': <span>Target selected search terms in other campaigns</span>,
    'search_terms_actions_AddNegativeKeywords': <span>Add selected search terms as negative keywords in campaigns</span>,
    'search_terms_actions_AddProducts': <span>Target selected products in other campaigns</span>,
    'search_terms_actions_AddNegativeProducts': <span>Add selected products as negative products in campaigns</span>,
    'search_terms_actions_AddTerms': <span>Add selected search terms as brand or competitor terms</span>,
    'brand_terms_desc': <div className="tooltip-wrapper">With the help of brand and competitor terms, you can indicate what ASINs/keywords/search terms belong to your brand, and which ones belong to your competitors. This is useful in multiple contexts like reporting, targeting, and also enables you to use this context in ruleset so you can create different rules for brand terms/products, for competitor terms/products as compared to generic terms/products.</div>,
    'add_terms_select_products': <span>Move desired products from the list on the left to the right.</span>,
    'search_terms_tracking_desc': <div className="tooltip-wrapper">With search terms tracking you can track the performance and ranking of your products for those terms. You can track ranking trends (sponsored, organic, etc) as well as brand share over time.</div>,
    'acc_setup_brand_terms': "With the help of brand terms, you can indicate what keywords or search terms belong to your brand. This makes our recommendations smarter and is also useful in reporting. You can also use this context in rulesets so you can create different rules for brand terms. Each line is a separate brand term, and anything that contains the text(s) below will be considered a brand term.",
    'acc_setup_comp_terms': "With the help of competitor terms, you can indicate what keywords or search terms belong to your competitors. This too makes our recommendations smarter and is also useful in reporting. You can also use this context in rulesets so you can create different rules for competitor terms. Each line is a separate competitor term, and anything that contains the text(s) below will be considered a competitor term. Do not worry about making this list too exhaustive, only major competitors will be a good start.",
    'acc_setup_brand_asins': "Similar to branded terms, these are ASINs that belong to you and marking them as branded ASINs will help in reporting, automation and recommendations.",
    'acc_setup_comp_asins': "Similar to competitor terms, these are ASINs that belong to your competitors and marking them as competitor ASINs will help in reporting, automation and recommendations. Do not worry about making this list too exhaustive, only major competitors will be a good start.",
    'acc_setup_sov': 'Share of Voice tracking allows you to track your products organic and sponsored ranks for specific keywords along with your share of search results. This is critical to ensure your advertising efforts are yielding results. Adbrew will automatically search Amazon for these specific keywords, and surface valuable insights based on your share of voice. With each account, you get 3 complimentary search terms, and the rest incur additional charges',
    'all_products_actions_CopyASINs': <span>Copy selected ASINs to clipboard</span>,
    'all_products_actions_UpdateBrand': <span>Update Brand for selected ASINs</span>,
    'placement_actions_PlotOnGraph': <span>Plot selected campaigns on the graph above.</span>,
    'placement_actions_UpdateBiddingAdjustments': <span>Update bidding adjustments for selected placements</span>,
    'placement_actions_UpdateBiddingBaseAdjustments': <span>Update bidding base adjustments for selected placements</span>,
    'first_execution_hour': <span>The first chronological hour in the hourly rulesets configuration</span>,
}

const TARGETING_OPTIONS = [
    {label: "KB", hover: "Broad Keyword Targeting", value: "keyword_broad"},
    {label: "KP", hover: "Phrase Keyword Targeting", value: "keyword_phrase"},
    {label: "KE", hover: "Exact Keyword Targeting", value: "keyword_exact"},
    {label: "PT", hover: "Product Targeting", value: "product"},
    {label: "CT", hover: "Category Targeting", value: "product_category"},
    {label: "NP", hover: "Negative Phrase Targeting", value: "negative_keyword_phrase", activeColor: "#f44236"},
    {label: "NE", hover: "Negative Exact Targeting", value: "negative_keyword_exact", activeColor: "#f44236"},
    {label: "NA", hover: "Negative ASIN Targeting", value: "negative_product", activeColor: "#f44236"}
]

const CURRENCY_SYMBOL_MAP = {
    AED: 'د.إ',
    AFN: '؋',
    ALL: 'L',
    AMD: '֏',
    ANG: 'ƒ',
    AOA: 'Kz',
    ARS: '$',
    AUD: '$',
    AWG: 'ƒ',
    AZN: '₼',
    BAM: 'KM',
    BBD: '$',
    BDT: '৳',
    BGN: 'лв',
    BHD: '.د.ب',
    BIF: 'FBu',
    BMD: '$',
    BND: '$',
    BOB: '$b',
    BOV: 'BOV',
    BRL: 'R$',
    BSD: '$',
    BTC: '₿',
    BTN: 'Nu.',
    BWP: 'P',
    BYN: 'Br',
    BYR: 'Br',
    BZD: 'BZ$',
    CAD: '$',
    CDF: 'FC',
    CHE: 'CHE',
    CHF: 'CHF',
    CHW: 'CHW',
    CLF: 'CLF',
    CLP: '$',
    CNY: '¥',
    COP: '$',
    COU: 'COU',
    CRC: '₡',
    CUC: '$',
    CUP: '₱',
    CVE: '$',
    CZK: 'Kč',
    DJF: 'Fdj',
    DKK: 'kr',
    DOP: 'RD$',
    DZD: 'دج',
    EEK: 'kr',
    EGP: '£',
    ERN: 'Nfk',
    ETB: 'Br',
    ETH: 'Ξ',
    EUR: '€',
    FJD: '$',
    FKP: '£',
    GBP: '£',
    GEL: '₾',
    GGP: '£',
    GHC: '₵',
    GHS: 'GH₵',
    GIP: '£',
    GMD: 'D',
    GNF: 'FG',
    GTQ: 'Q',
    GYD: '$',
    HKD: '$',
    HNL: 'L',
    HRK: 'kn',
    HTG: 'G',
    HUF: 'Ft',
    IDR: 'Rp',
    ILS: '₪',
    IMP: '£',
    INR: '₹',
    IQD: 'ع.د',
    IRR: '﷼',
    ISK: 'kr',
    JEP: '£',
    JMD: 'J$',
    JOD: 'JD',
    JPY: '¥',
    KES: 'KSh',
    KGS: 'лв',
    KHR: '៛',
    KMF: 'CF',
    KPW: '₩',
    KRW: '₩',
    KWD: 'KD',
    KYD: '$',
    KZT: '₸',
    LAK: '₭',
    LBP: '£',
    LKR: '₨',
    LRD: '$',
    LSL: 'M',
    LTC: 'Ł',
    LTL: 'Lt',
    LVL: 'Ls',
    LYD: 'LD',
    MAD: 'MAD',
    MDL: 'lei',
    MGA: 'Ar',
    MKD: 'ден',
    MMK: 'K',
    MNT: '₮',
    MOP: 'MOP$',
    MRO: 'UM',
    MRU: 'UM',
    MUR: '₨',
    MVR: 'Rf',
    MWK: 'MK',
    MXN: '$',
    MXV: 'MXV',
    MYR: 'RM',
    MZN: 'MT',
    NAD: '$',
    NGN: '₦',
    NIO: 'C$',
    NOK: 'kr',
    NPR: '₨',
    NZD: '$',
    OMR: '﷼',
    PAB: 'B/.',
    PEN: 'S/.',
    PGK: 'K',
    PHP: '₱',
    PKR: '₨',
    PLN: 'zł',
    PYG: 'Gs',
    QAR: '﷼',
    Rs: '₹',
    RMB: '￥',
    RON: 'lei',
    RSD: 'Дин.',
    RUB: '₽',
    RWF: 'R₣',
    SAR: '﷼',
    SBD: '$',
    SCR: '₨',
    SDG: 'ج.س.',
    SEK: 'kr',
    SGD: 'S$',
    SHP: '£',
    SLL: 'Le',
    SOS: 'S',
    SRD: '$',
    SSP: '£',
    STD: 'Db',
    STN: 'Db',
    SVC: '$',
    SYP: '£',
    SZL: 'E',
    THB: '฿',
    TJS: 'SM',
    TMT: 'T',
    TND: 'د.ت',
    TOP: 'T$',
    TRL: '₤',
    TRY: '₺',
    TTD: 'TT$',
    TVD: '$',
    TWD: 'NT$',
    TZS: 'TSh',
    UAH: '₴',
    UGX: 'USh',
    USD: '$',
    UYI: 'UYI',
    UYU: '$U',
    UYW: 'UYW',
    UZS: 'лв',
    VEF: 'Bs',
    VES: 'Bs.S',
    VND: '₫',
    VUV: 'VT',
    WST: 'WS$',
    XAF: 'FCFA',
    XBT: 'Ƀ',
    XCD: '$',
    XOF: 'CFA',
    XPF: '₣',
    XSU: 'Sucre',
    XUA: 'XUA',
    YER: '﷼',
    ZAR: 'R',
    ZMW: 'ZK',
    ZWD: 'Z$',
    ZWL: '$'
}

const AUTOMATION_USING_DATA_FROM_OPTIONS = [
    { 'value': '1', 'label': 'Last day' },
    { 'value': '3', 'label': 'Last 3 days' },
    { 'value': '7', 'label': 'Last 7 days' },
    { 'value': '14', 'label': 'Last 14 days' },
    { 'value': '30', 'label': 'Last 30 days' },
    { 'value': '60', 'label': 'Last 60 days' },
    { 'value': '90', 'label': 'Last 90 days' },
    { 'value': '120', 'label': 'Last 120 days' },
]

const EXCLUDE_DATA_FROM_OPTIONS = [
    { 'value': '0', 'label': 'Do not exclude any day' },
    { 'value': '1', 'label': 'Latest day' },
    { 'value': '2', 'label': 'Latest 2 days' },
    { 'value': '3', 'label': 'Latest 3 days' },
]

const AUTOMATION_FREQUENCY_OPTIONS = [
    { 'value': 0, 'label': 'Once' },
    { 'value': 24, 'label': 'Hourly' },
    { 'value': 1, 'label': 'Daily' },
    { 'value': 7, 'label': 'Weekly' },
    { 'value': 30, 'label': 'Monthly' },
]

const AUTOMATION_DAYS_OPTIONS = [
    { 'value': 'sunday', 'label': 'Sunday', idx: 6 },
    { 'value': 'monday', 'label': 'Monday', idx: 0 },
    { 'value': 'tuesday', 'label': 'Tuesday', idx: 1 },
    { 'value': 'wednesday', 'label': 'Wednesday', idx: 2 },
    { 'value': 'thursday', 'label': 'Thursday', idx: 3 },
    { 'value': 'friday', 'label': 'Friday', idx: 4 },
    { 'value': 'saturday', 'label': 'Saturday', idx: 5 },
]

const WEEKDAY_INDEX = AUTOMATION_DAYS_OPTIONS.reduce((index, day) => {
    index[day.idx] = day.label;
    return index;
  }, {});

const AUTOMATION_TIME_OPTIONS = [
    { 'value': '00', 'label': '00 AM' },
    { 'value': '01', 'label': '01 AM' },
    { 'value': '02', 'label': '02 AM' },
    { 'value': '03', 'label': '03 AM' },
    { 'value': '04', 'label': '04 AM' },
    { 'value': '05', 'label': '05 AM' },
    { 'value': '06', 'label': '06 AM' },
    { 'value': '07', 'label': '07 AM' },
    { 'value': '08', 'label': '08 AM' },
    { 'value': '09', 'label': '09 AM' },
    { 'value': '10', 'label': '10 AM' },
    { 'value': '11', 'label': '11 AM' },
    { 'value': '12', 'label': '12 PM' },
    { 'value': '13', 'label': '01 PM' },
    { 'value': '14', 'label': '02 PM' },
    { 'value': '15', 'label': '03 PM' },
    { 'value': '16', 'label': '04 PM' },
    { 'value': '17', 'label': '05 PM' },
    { 'value': '18', 'label': '06 PM' },
    { 'value': '19', 'label': '07 PM' },
    { 'value': '20', 'label': '08 PM' },
    { 'value': '21', 'label': '09 PM' },
    { 'value': '22', 'label': '10 PM' },
    { 'value': '23', 'label': '11 PM' },
]

const AUTOMATION_DATES_OPTIONS = [
    { value: 1, label: '1st' },
    { value: 2, label: '2nd' },
    { value: 3, label: '3rd' },
    { value: 4, label: '4th' },
    { value: 5, label: '5th' },
    { value: 6, label: '6th' },
    { value: 7, label: '7th' },
    { value: 8, label: '8th' },
    { value: 9, label: '9th' },
    { value: 10, label: '10th' },
    { value: 11, label: '11th' },
    { value: 12, label: '12th' },
    { value: 13, label: '13th' },
    { value: 14, label: '14th' },
    { value: 15, label: '15th' },
    { value: 16, label: '16th' },
    { value: 17, label: '17th' },
    { value: 18, label: '18th' },
    { value: 19, label: '19th' },
    { value: 20, label: '20th' },
    { value: 21, label: '21st' },
    { value: 22, label: '22nd' },
    { value: 23, label: '23rd' },
    { value: 24, label: '24th' },
    { value: 25, label: '25th' },
    { value: 26, label: '26th' },
    { value: 27, label: '27th' },
    { value: 28, label: '28th' },
    { value: 29, label: '29th' },
    { value: 30, label: '30th' },
    { value: 31, label: '31st' }
]

const GOAL_OPTIONS = [
    { theme_bg: 'theme-bg', value: 'a', name: 'Brand Awareness', description: 'Focused on increasing impressions' },
    { theme_bg: 'theme-bg2', value: 'b', name: 'Profit Maximization', description: 'Focused on ACoS reduction' }
]

const THRESHOLD_OPTIONS = [
    { value: 'target_acos', label: 'Target ACoS' },
    { value: 'adequate_impressions', label: 'Adequate Impressions' },
    { value: 'adequate_spend', label: 'Adequate Spend' },
    { value: 'wasted_spend', label: 'Wasted Spend' },
    { value: 'min_bid_threshold', label: 'Minimum Bid' },
    { value: 'max_bid_threshold', label: 'Maximum Bid' },
    { value: 'adequate_clicks', label: 'Adequate Clicks' },
    { value: 'min_target_acos', label: 'Min Target ACOS' },
    { value: 'max_target_acos', label: 'Max Target ACOS' },
    { value: 'min_budget_threshold', label: 'Min Budget'},
    { value: 'max_budget_threshold', label: 'Max Budget'}
]

const MIN_MAX_BIDS = {
    "sp": {
        "US": [0.02, 1000],
        "CA": [0.02, 1000],
        "UK": [0.02, 1000],
        "DE": [0.02, 1000],
        "FR": [0.02, 1000],
        "ES": [0.02, 1000],
        "IT": [0.02, 1000],
        "NL": [0.02, 1000],
        "IN": [0.5, 1000],
        "JP": [2, 100000],
        "AU": [0.1, 1410],
        "AE": [0.24, 184],
        "BR": [0.07, 3700],
        "MX": [0.1, 20000],
        "SA": [0.10, 3670],
        "SG": [0.02, 1100],
        "BE": [0.02, 1000],
    },
    "sb": {
        "US": [0.1, 49],
        "CA": [0.1, 49],
        "UK": [0.1, 31],
        "DE": [0.1, 39],
        "FR": [0.1, 39],
        "ES": [0.1, 39],
        "IT": [0.1, 39],
        "NL": [0.1, 39],
        "IN": [0.5, 39],
        "JP": [10, 7760],
        "AU": [0.1, 70],
        "AE": [0.24, 184],
        "BR": [0.07, 3700],
        "MX": [0.1, 49],
        "SA": [0.40, 184],
        "SG": [0.14, 100],
        "BE": [0.1, 39],
    },
    "sd": {
        "NL": [0.02, 1000],
        "AU": [1, 1000],
        "SA": [0.10, 3670],
        "SG": [0.14, 1410],
        "BE": [0.02, 1000],
    }
}

const PRODUCT_GOALS_RECURRING = [
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'quarterly', label: 'Quarterly' }
]

const SP_API_APP_ID = "amzn1.sp.solution.443fb369-824c-4ce4-82e2-27190c3b76ca"
const AUDIT_TYPES = {
    PRODUCT_TARGETING_CT_MIX: "ASIN Coverage",
    KEYWORD_TARGETING_MIX: "Mixed Targeting",
    CAMPAIGNS_WITH_NO_NEGATIVES: "Missing Negatives",
    CAMPAIGNS_BIDDING_STRATEGY: "Campaigns Bidding Strategy",
    CAMPAIGNS_PLACEMENTS: "Missing Placements",
    CAMPAIGN_BUDGET_USAGE: "Budget Utlisation"
}

const CAMPAIGN_TYPES = {
    SP: 'sp',
    SB: 'sb',
    SD: 'sd'
}

const MATCH_TYPES = {
    PHRASE: 'phrase',
    EXACT: 'exact',
    BROAD: 'broad'
}

const TASK_TYPES = {
    target_management: 'Target Management',
    campaign_management: 'Campaign Management',
    bid_management: 'Bid Management',
    bid_optimization: 'Bid Optimization',
    sov_optimization: 'SOV Optimization',
}

const BIDDING_STRATEGIES = {
    autoForSales: 'Up & Down',
    legacyForSales: 'Down Only',
    manual: 'Fixed'
}

const TERM_TYPES = {
    BRAND: 'brand',
    COMPETITOR: 'competitor'
}

const EMAIL_NAMES = {
    report_email: 'Weekly Performance Email'
}

const CAMPAIGN_TYPE_TO_CAMPAIGN_LABEL = {
    "sp": "Sponsored Products",
    "sb": "Sponsored Brands",
    "sd": "Sponsored Display"
}

const CR_SOURCES = {
    ASIN_PPC: "PPC CR of Advertised ASINs",
    CAT_PPC: "PPC CR of Advertised ASINs Category",
    ASIN_TOTAL: "Overall CR of Advertised ASINs",
    CAT_TOTAL: "Overall CR of Advertised ASINs Categor",
    OV_PPC: "PPC CR of Account",
    OV_TOTAL: "Overall CR of Account"
}

const PLACEMENTS = {
    TOP_OF_SEARCH: 'Top of Search',
    TOP_OF_SEARCH_AMZ: 'Top of Search on-Amazon',
    DETAIL_PAGE: 'Detail Page on-Amazon',
    OTHER_ON_AMAZON: 'Other on-Amazon',
    OTHER_PLACEMENTS: 'Other Placements',
}

const CHANGE_TYPES = [
    { value: 'bid_optimization', label: 'Bid Changes' },
    { value: 'target_management', label: 'Target Changes' },
    { value: 'campaign_management', label: 'Campaign Changes' },
    { value: 'sov_optimization', label: 'SOV Changes' },
]

const RULESET_TYPE_TO_CHANGE_TYPE = {
    bid: { value: 'bid_optimization', label: 'Bid Changes' },
    target: { value: 'target_management', label: 'Target Changes' },
    campaign: { value: 'campaign_management', label: 'Campaign Changes' },
    sov: { value: 'sov_optimization', label: 'SOV Changes' }
}

const DERIVED_PLACEMENTS = [
    { value: 'tos', label: 'Top of Search' },
    { value: 'detail', label: 'Product Pages' },
    { value: 'other', label: 'Rest of Search' },
]

const EXPORT_METRICS_WHITELST = [
    'day',
    'hour',
    'clicks',
    'impressions',
    'spend',
    'sales',
    'orders',
    'spend_perc',
    'clicks_perc',
    'impressions_perc',
    'sales_perc',
    'orders_perc',
    'conversions',
    'conversions_perc',
    'cpc',
    'acos',
    'roas',
    'ctr',
    'conversion_rate'
]

const METRIC_TYPES = Object.freeze({
    ACOS: "ACOS",
    NUMERIC: "NUMERIC",
    PERCENTAGE: "PERCENTAGE",
    CURRENCY: "CURRENCY",
})

const DEFAULT_ORG_DOMAIN_CONFIG = {
    'guest': {
        'protocol': 'https://',
        'domain': 'guest.adbrew.io',
        'subdomain': null
    },
    'app': {
        'protocol': 'https://',
        'domain': 'app.adbrew.io',
        'subdomain': null
    }
}

const NAVBAR_AND_HEADER_DIMENSIONS = {
    HEADER_HEIGHT: 52,
    COLLAPSED_NAV_WIDTH: 48,
    EXPANDED_NAV_WIDTH: 245
}

export {
    TARGET_TYPE_MAP,
    TARGET_INFO_KEYWORDS,
    CURRENCY_SYMBOL_MAP,
    COUNTRY_TO_DOMAIN,
    AUTOMATION_USING_DATA_FROM_OPTIONS,
    AUTOMATION_FREQUENCY_OPTIONS,
    AUTOMATION_DAYS_OPTIONS,
    AUTOMATION_TIME_OPTIONS,
    AUTOMATION_DATES_OPTIONS,
    GOAL_OPTIONS,
    THRESHOLD_OPTIONS,
    MATCH_TYPE_MAP,
    MIN_MAX_BIDS,
    PRODUCT_GOALS_RECURRING,
    HELP_TEXT,
    COUNTRY_TO_NUMBER_FORMAT,
    RULE_TYPE_TO_TASK_TYPE,
    TARGETING_OPTIONS,
    COUNTRY_TO_VENDOR_CENTRAL_URL,
    COUNTRY_TO_SELLER_CENTRAL_URL,
    SP_API_APP_ID,
    AUDIT_TYPES,
    CAMPAIGN_TYPES,
    MATCH_TYPES,
    TASK_TYPES,
    BIDDING_STRATEGIES,
    TERM_TYPES,
    EMAIL_NAMES,
    CAMPAIGN_TYPE_TO_CAMPAIGN_LABEL,
    CR_SOURCES,
    PLACEMENTS,
    CHANGE_TYPES,
    DERIVED_PLACEMENTS,
    EXCLUDE_DATA_FROM_OPTIONS,
    EXPORT_METRICS_WHITELST,
    WEEKDAY_INDEX,
    METRIC_TYPES,
    DEFAULT_ORG_DOMAIN_CONFIG,
    RULESET_TYPE_TO_CHANGE_TYPE,
    NAVBAR_AND_HEADER_DIMENSIONS
}



export default DEMO;
