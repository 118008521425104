import React from 'react'

const ErrorFallBackUI = ({ error, errorMessage }) => {

    const refreshPage = () => {
        window.location.reload()
    }

    const goToUserPreferences = () => {
        window.location.href = "/profile"
    }

    const onContactUs = () => {
        window.location.href = "/documentation"
    }

    const navigateToDashboard = () => {
        window.location.href = "/dashboard"
    }

    return (
        <div style={{ marginTop: '30vh' }}>
            <div style={{margin: "auto", width: 360}}>
                <div style={{ textAlign: 'center', color: 'grey' }}>
                    <h4 style={{ color: 'lightgrey' }}>(＞﹏＜)</h4>
                    <h5 style={{ color: 'grey' }}>
                        Oops! Something went wrong.
                    </h5>
                    <hr />
                </div>
                <div>
                    {/* <div className='mb-2'>A report has been sent and we are currently looking into it.</div> */}
                    <h5>You can try the following:</h5>
                    <li style={{ cursor: "pointer", fontWeight: "500", color: "rgb(31 115 206)" }} onClick={navigateToDashboard}>Go to Dashboard</li>
                    <li style={{ cursor: "pointer", fontWeight: "500", color: "rgb(31 115 206)" }} onClick={refreshPage}>Refresh page</li>
                    <li style={{ cursor: "pointer", fontWeight: "500", color: "rgb(31 115 206)" }} onClick={goToUserPreferences}>Clear browser preferences</li>
                    <li style={{ cursor: "pointer", fontWeight: "500", color: "rgb(31 115 206)" }} onClick={onContactUs}>Contact Us</li>
                </div>
            </div>
        </div>
    )
}

export default ErrorFallBackUI