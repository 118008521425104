import React from 'react'
import { Provider } from 'react-redux'

import { ProvideUsageTracking } from '../context/UsageTrackingContext'
import { ProvideDateRange } from '../context/DateRangeContext'
import { ProvideDataProvider } from '../context/DataProvider'
import { ProvideGraphParam } from '../context/GraphContext'
import { ProvideAccount } from '../context/AccountContext'
import { ProvideFilter } from '../context/FilterContext'
import { ProvideTablePreferences } from '../context/TablePreferencesContext'
import { ProvideAccountSetupState } from '../context/AccountSetupStateContext'
import { ProvideDCMSProvider } from '../context/DCMSProvider'
import store from '../app/store';

const AuthedContextWrapped = ({ children }) => {
    return (
        <ProvideDateRange>
            <ProvideDataProvider>
                <ProvideGraphParam>
                    <ProvideAccount>
                        <ProvideUsageTracking>
                            <ProvideFilter>
                                <ProvideTablePreferences>
                                    <ProvideAccountSetupState>
                                        <Provider store={store}>
                                            <ProvideDCMSProvider>
                                                {children}
                                            </ProvideDCMSProvider>
                                        </Provider>
                                    </ProvideAccountSetupState>
                                </ProvideTablePreferences>
                            </ProvideFilter>
                        </ProvideUsageTracking>
                    </ProvideAccount>
                </ProvideGraphParam>
            </ProvideDataProvider>
        </ProvideDateRange>
    )
}

export default AuthedContextWrapped