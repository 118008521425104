import { METRIC_TYPES } from "../../../store/constant"
import { COLORS } from "../constants"

export const GOAL_VALUE_TO_LABEL = {
    "ENGAGEMENT_WITH_MY_AD": "Engagement with my ad",
    "CONSIDERATIONS_ON_AMAZON": "Considerations on Amazon",
    "CONVERSIONS_OFF_AMAZON": "Conversions off Amazon",
    "PURCHASES_ON_AMAZON": "Purchases on Amazon",
    "PURCHASES_ON_OFF_AMAZON": "Purchases on and off Amazon",
    "MOBILE_APP_INSTALLS": "Mobile app installs"
}

export const DERIVED_GOAL_VALUE_TO_LABEL = {
    "AWARENESS": "Awareness",
    "CONSIDERATION": "Consideration",
    "CONVERSION": "Conversion"
}

export const KPI_CONFIGS = {
    REACH: {
        label: "Reach"
    },
    CLICK_THROUGH_RATE: {
        label: "CTR",
        metric_type: METRIC_TYPES.PERCENTAGE,
    },
    COST_PER_CLICK: {
        label: "CPC",
        metric_type: METRIC_TYPES.CURRENCY,
    },
    COST_PER_VIDEO_COMPLETION: {
        label: "Cost Per Video Completion",
    },
    VIDEO_COMPLETION_RATE: {
        label: "Video Completion Rate",
    },
    COST_PER_DETAIL_PAGE_VIEW: {
        label: "Cost Per Detail Page View",
        metric_type: METRIC_TYPES.CURRENCY,
    },
    DETAIL_PAGE_VIEW_RATE: {
        label: "Detail Page View Rate",
        metric_type: METRIC_TYPES.PERCENTAGE,
    },
    RETURN_ON_AD_SPEND: {
        label: "ROAS",
        metric_type: METRIC_TYPES.NUMERIC,
    },
    TOTAL_RETURN_ON_AD_SPEND: {
        label: "Total ROAS",
        metric_type: METRIC_TYPES.NUMERIC,
    },
    COST_PER_ACTION: {
        label: "Cost Per Action"
    },
    COMBINED_RETURN_ON_AD_SPEND: {
        label: "Combined ROAS"
    },
    COST_PER_SIGN_UP: {
        label: "Cost Per Sign Up"
    },
    COST_PER_FIRST_APP_OPEN: {
        label: "Cost Per First App Open"
    }
}

export const TARGET_KPI_STATUS = Object.freeze({
    ON_TARGET: "ON_TARGET",
    OFF_TARGET: "OFF_TARGET",
    UNKNOWN: "UNKNOWN",
    NA: "NA"
})

export const TARGET_KPI_STATUS_CONFIGS = {
    [TARGET_KPI_STATUS.ON_TARGET]: {
        label: "On Target",
        color: COLORS["green"]
    },
    [TARGET_KPI_STATUS.OFF_TARGET]: {
        label: "Off Target",
        color: COLORS["red"]
    },
    [TARGET_KPI_STATUS.UNKNOWN]: {
        label: "Unknown",
        color: COLORS["black"]
    },
    [TARGET_KPI_STATUS.NA]: {
        label: "N/A",
        color: COLORS["black"]
    }
}
