import React, { useState } from 'react'
import { Card } from 'react-bootstrap'

import HelpTip from './help_tooltip'
import CustomModal from './CustomModal'

const FailureReasonTextLines = ({ reasonList, key = 'tootlip', addLineStyles = false }) => {
    const lineStyles = { padding: "5px 0", fontSize: 13, borderBottom: '1px solid rgb(220 220 220)' }
    return <>
        {
            reasonList.map((_reason, _i) => (
                <div key={`failure-reason-item-${key}-${_i}`} style={addLineStyles ? lineStyles : {}}>{_reason}</div>
            ))
        }
    </>
}

const FailureReasonDetailsModal = ({ reasonList, ...modalProps }) => {

    return <CustomModal size='lg' bodyStyle={{ height: "75vh" }} {...{ ...modalProps }}>
        <Card className='Recent-Users mb-0'>
            <Card.Body className='p-4'>
                <FailureReasonTextLines reasonList={reasonList} key='details' addLineStyles />
            </Card.Body>
        </Card>
    </CustomModal>
}

const FailureReason = ({ reason, reasonList = null, idx }) => {
    const MAX_REASONS_TO_SHOW_IN_TOOLTIP = "20"
    const [showFailureDetailModal, setShowFailureDetailModal] = useState(false)

    let textReasons = reason
    if (reasonList) {
        textReasons = (
            <div className='enable-ellipsis' style={{ textOverflow: 'ellipsis', WebkitLineClamp: MAX_REASONS_TO_SHOW_IN_TOOLTIP }}>
                <FailureReasonTextLines reasonList={reasonList} />
            </div>
        )
    }
    return (
        <>
            <HelpTip text={textReasons} place="bottom" id={`failure-reason-${idx}`}>
                <div onClick={() => setShowFailureDetailModal(true)} className='enable-ellipsis text-danger' style={{ cursor: "pointer", maxWidth: "300px", WebkitLineClamp: "2", fontWeight: "500" }} >
                    {
                        reasonList ? <FailureReasonTextLines reasonList={reasonList} /> : reason
                    }
                </div>
            </HelpTip>
            <FailureReasonDetailsModal reasonList={reasonList} heading='Failure Reasons' show={showFailureDetailModal} onHide={() => setShowFailureDetailModal(false)} />
        </>

    )
}

export default FailureReason