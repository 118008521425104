import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import windowSize from 'react-window-size';

import NavSearch from './NavSearch';
import Aux from "../../../../hoc/_Aux";
import DEMO from "../../../../store/constant";
import { useAuth } from '../../../../context/AuthContext';
import { isSharedEnv } from '../../../../service';


export default function NavLeft({ windowWidth, rtlLayout }) {

    const { organization } = useAuth();

    let navItemClass = ['nav-item'];
    if (windowWidth <= 575) {
        navItemClass = [...navItemClass, 'd-none'];
    }

    let dropdownRightAlign = false;
    if (rtlLayout) {
        dropdownRightAlign = true;
    }

    let logo = (
        <div style={{ width: 28, height: 28, borderRadius: "50%", padding: 3, backgroundColor: "#efefef" }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 101" style={{ fill: "#4d4d4d", width: "100%", height: "100%" }}>
            <path d="m84 39.1-.1-.4s-.1-.1-.1-.2L76.5 18c-.3-1-1.2-1.6-2.3-1.6H26.8c-1 0-1.9.6-2.3 1.6l-7.4 20.4c0 .1-.1.2-.1.4l-.3.7c-.1.2-.1.4-.1.6-.2.9-.3 1.7-.3 2.4 0 4.4 2.8 8.2 6.8 9.7v30c0 1.3 1.1 2.4 2.4 2.4h50.8c1.3 0 2.4-1.1 2.4-2.4V51.8c3.4-1.7 5.8-5.2 5.8-9.3 0-.7-.1-1.5-.3-2.3.1-.4 0-.7-.2-1.1zM74 79.8H28v-27c.1 0 .3-.1.4-.1 2.5-.4 4.7-1.7 6.3-3.6 1.9 2.3 4.7 3.7 7.9 3.7s6-1.4 7.9-3.7c1.9 2.3 4.7 3.7 7.9 3.7s6-1.4 7.9-3.7c1.4 1.6 3.2 2.8 5.3 3.4h.1c.2.1.5.1.7.1.1 0 .3.1.4.1.2 0 .4 0 .6.1h.4v27zm1.8-32c-.3.1-.5.1-.7.2-.3 0-.5.1-.8.1-.4 0-.7 0-1.1-.1-.3-.1-.6-.1-.8-.3h-.1c-1.9-.8-3.3-2.6-3.4-4.7v-.4c0-1.3-1.1-2.4-2.4-2.4s-2.5 1-2.5 2.3v.5c-.2 2.1-1.6 3.9-3.4 4.7h-.1l-.9.3h-1.2c-.4 0-.7 0-1.1-.1-.3-.1-.6-.1-.8-.3h-.1c-1.9-.8-3.3-2.6-3.4-4.7v-.4c0-1.3-1.1-2.4-2.4-2.4-1.3 0-2.4 1.1-2.4 2.4v.5c-.2 2.1-1.6 3.9-3.4 4.7h-.1l-.9.3h-1.2c-.3 0-.7 0-1.1-.1-.3-.1-.6-.1-.9-.3h-.1c-1.9-.8-3.3-2.6-3.4-4.7v-.4c0-1.3-1.1-2.4-2.4-2.4-1.3 0-2.4 1.1-2.4 2.4v.5c-.2 2.1-1.6 3.9-3.4 4.7h-.1c-.3.1-.6.2-.8.3h-1.3c-.2 0-.4 0-.6-.1H25.8c-2.6-.5-4.6-2.7-4.6-5.4 0-.4.1-.9.3-1.5 0-.1 0-.2.1-.2l7-19.5h44l6.8 19s0 .1.1.2l.1.4c0 .1 0 .2.1.2.2.6.3 1.1.3 1.5-.2 2.4-1.9 4.5-4.2 5.2z" />
        </svg>
    </div>)

    // if (isSharedEnv && organization.show_guest_logo && organization.logo?.small)
    if (isSharedEnv && organization.logo?.small)
        logo = <img src={`${organization.logo_prefix}${organization.logo.small}`} width="28px" height="28px" />

    return (
        <Aux>
            <ul className="navbar-nav mr-auto">
                <li className='navbar-org'>
                    <div style={{ padding: "6px 20px", display: "flex", gap: 6, alignItems: "center" }}>
                        {logo}
                        <div style={{ color: "darkslategray", fontWeight: "600"}}>{organization.name}</div>
                    </div>
                </li>
                {/* <li className={navItemClass.join(' ')}>
                    <Dropdown alignRight={dropdownRightAlign}>
                        <Dropdown.Toggle variant={'link'} id="dropdown-basic">
                            Dropdown
                        </Dropdown.Toggle>
                        <ul>
                            <Dropdown.Menu>
                                <li><a className="dropdown-item" href={DEMO.BLANK_LINK}>Action</a></li>
                                <li><a className="dropdown-item" href={DEMO.BLANK_LINK}>Another action</a></li>
                                <li><a className="dropdown-item" href={DEMO.BLANK_LINK}>Something else here</a></li>
                            </Dropdown.Menu>
                        </ul>
                    </Dropdown>
                </li> */}
                {/* <li className="nav-item"><NavSearch/></li> */}
            </ul>
        </Aux>
    )
}


