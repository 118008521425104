import API from './api';

export class PaginatedGetter extends API {
    constructor(path) {
        super(path);
    }

    getPaginationParams = ({ page, perPage, sortColumn, sortDirection, filterSpec, filterText}) => {
        let params = {
            page, perPage, sortColumn, sortDirection, 
        }
        if (filterSpec)
            params.filterSpec = encodeURIComponent(JSON.stringify(filterSpec))
        if (filterText)
            params.filterText = encodeURIComponent(filterText)
        return params
    }

    async get({ page, perPage, sortColumn, sortDirection, filterSpec, filterText, fetchMetrics = true, ...extraParams }) {
        let params = this.getPaginationParams({ page, perPage, sortColumn, sortDirection, filterSpec, filterText })
        if (!fetchMetrics)
            params.no_metrics = 1 
        params = { ...params, ...extraParams }
        return await this._get({ params })
    }
}