import React, { createContext, useState } from 'react';

const graphParamContext = createContext();

function ProvideGraphParam({ children }) {
    const graphParamContextValue = useProvideGraphParamContext();
    return (
      <graphParamContext.Provider value={graphParamContextValue}>
        {children}
      </graphParamContext.Provider>
    );
}

function useProvideGraphParamContext() {
    
    const [plottedPortfolios, setPlottedPortfolios] = useState([]);
    const [plottedCampaigns, setPlottedCampaigns] = useState([])
    const [plottedAdGroups, setPlottedAdGroups] = useState([])
    const [plottedTargets, setPlottedTargets] = useState([])
    const [plottedKeywords, setPlottedKeywords] = useState([])
    const [plottedProducts, setPlottedProducts] = useState([])
    const [plottedSearchTerms, setPlottedSearchTerms] = useState([])
    const [plottedTrackedSearchTerms, setPlottedTrackedSearchTerms] = useState([])
    const [plottedLabels, setPlottedLabels] = useState([])
    const [plottedTypes, setPlottedTypes] = useState([])
    const [plottedPlacements, setPlottedPlacements] = useState([])
    const [graphType, setGraphType] = useState("account")

    const reset = () => {
        if (graphType === "account") {
            if (plottedPortfolios.length > 0) setPlottedPortfolios([])
            if (plottedCampaigns.length > 0) setPlottedCampaigns([])
        }
        if (plottedPortfolios.length > 0) setPlottedProducts([])
        if (plottedTargets.length > 0) setPlottedTargets([])
        if (plottedAdGroups.length > 0) setPlottedAdGroups([])
        if (plottedProducts.length > 0) setPlottedProducts([])
        if (plottedKeywords.length > 0) setPlottedKeywords([])
        if (plottedSearchTerms.length > 0) setPlottedSearchTerms([])
        if (plottedTrackedSearchTerms.length > 0) setPlottedTrackedSearchTerms([])
        if (plottedLabels.length > 0) setPlottedLabels([])
        if (plottedTypes.length > 0) setPlottedTypes([])
        if (plottedPlacements.length > 0) setPlottedPlacements([])
    }
    
    const toggleElement = (el, type) => {
        let arr, setArr;
        if (type === "campaigns") {
            arr = plottedCampaigns
            setArr = setPlottedCampaigns
        } else if (type === "products") {
            arr = plottedProducts
            setArr = setPlottedProducts
        } else if (type === "adgroups") {
            arr = plottedAdGroups
            setArr = setPlottedAdGroups
        } else if (type === "targets" || type === "target") {
            arr = plottedTargets
            setArr = setPlottedTargets
        } else if (type === "search_terms") {
            arr = plottedSearchTerms
            setArr = setPlottedSearchTerms 
        } else {
            arr = plottedKeywords
            setArr = setPlottedKeywords 
        }
        const idx = arr.findIndex(({label, value}) => value === el.value)
        if (idx > -1) {
            arr.splice(idx, 1)
        } else {
            arr.push(el)
        }
        setArr([...arr])
    }
    
    return {
        setPlottedPortfolios,
        setPlottedCampaigns,
        setPlottedAdGroups,
        setPlottedTargets,
        setPlottedKeywords,
        setPlottedProducts,
        setPlottedSearchTerms,
        setPlottedTrackedSearchTerms,
        setPlottedTypes,
        setPlottedLabels,
        setPlottedPlacements,
        toggleElement,
        plottedProducts,
        plottedPortfolios,
        plottedCampaigns,
        plottedAdGroups,
        plottedTargets,
        plottedKeywords,
        plottedSearchTerms,
        plottedTrackedSearchTerms,
        plottedLabels,
        plottedTypes,
        plottedPlacements,
        setGraphType,
        graphType,
        reset
    }
}

export {
    graphParamContext,
    ProvideGraphParam
}