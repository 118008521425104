// Export your Manipulation functions below

/**
* Converts "TEST_STRING" or "testString" type strings to "Test String"
* @param {String} target String to convert
* @returns String | NULL
*/
export const capitalizeString = (target) => {
    if (!target)
        return null
    if (target.length == 0)
        return '--'

    const matchLetterAfterSpace = /(?:^|\s)+\S/g
    const matchOnlyCaps = /([A-Z])+/g
    // Adds an extra space just before a capital letter
    let lowerCased = target.replace(matchOnlyCaps, match => ` ${match}`)
    // Lower case with no special characters. Here '_' is the only special character.
    lowerCased = lowerCased.toLowerCase().replaceAll("_", " ")
    // Replace the first letter of each word with its capital form
    return lowerCased.replace(matchLetterAfterSpace, match => match.toUpperCase())
}

/**
 * @param {Object} object String to convert
 * @param {Array} keys String to convert
 */
export const removeKeysFromObject = (object, keys) => {
    keys.forEach(key => {
        delete object[key]
    }
    )
    return object
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const createHyphenSeparatedString = (string) => {
    return string.toLowerCase().replaceAll(' ', '-')

}

export const generateRandomUniqueString = () => {
    return (Math.random() + 1).toString(36);
}
