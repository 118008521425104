export const PAGE_PATHS = {
    SHARED_LINKS: '/shared_links',
    PROFILE_PAGE: "/profile",
    PROFILE_PERSONAL_DETAILS: "/profile/personal-details",
    PROFILE_SECURITY: "/profile/security",
    PROFILE_PREFERENCES: "/profile/preferences",
    ORG_SETTINGS: '/org_settings',
    ORG_BRAND_DEATAILS: '/org_settings/brand_details',
    ORG_DOMAIN_PREFERENCES: '/org_settings/domain_preferences',
    AMC_ONBOARDING: '/amc/onboarding',
    AMC_INSTANCE_CREATION: '/amc/onboarding/instance_creation',
}