import React, { useState, useEffect, useContext } from "react";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import moment from "moment";

import Support from "../NavBar/NavRight/Support/index";
import AccountSelectionDropdown from "./AccountSelectionDropdown";
import ProfileOptions from "./ProfileOptions";

import { useAuth } from "../../../context/AuthContext";
import { accountContext } from "../../../context/AccountContext";
import {
  EVENT_ACTIONS,
  useUsageTracking,
} from "../../../context/UsageTrackingContext";
import { getCampaigns, isSharedEnv } from "../../../service";
import HelpTip from "../../Components/help_tooltip";
import { iconClasses, iconClassesWithHover } from "../../../utils/commonTailwindClasses";
import {
  AdbClockRefresh,
  AdbHelpSqaure,
  AdbSettings,
} from "../../../adb-icons/AdbIcons";

/**
 * Renders Right part of the Main header which includes
 * Currently selected dropdow, Organization options and User options.
 */
function HeaderRight() {
  const {
    loading,
    accounts,
    switchAccount,
    selectedAccountDetails,
  } = useContext(accountContext);
  const { organization, user, signout, isUserAdmin } = useAuth();
  const params = new URLSearchParams(window.location.search);
  const showHelp = params.get("showHelp");
  const [listOpen, setListOpen] = useState(false);
  const { sendEvent } = useUsageTracking();
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (showHelp) {
      setListOpen(true);
    }
  }, [showHelp]);

  const tabsConfig = {
    ACCOUNTS: {
      name: "Accounts",
      listBox: true,
      selectedOption: {
        value: selectedAccountDetails._id,
        label: selectedAccountDetails.account_name,
        type: selectedAccountDetails.profile_account_type,
        country_code: selectedAccountDetails.country_code,
      },
      data: accounts.map((account) => {
        return {
          value: account._id,
          label: account.account_name,
          type: account.profile_account_type || account.type,
          country_code: account.country_code,
        };
      }),
    },
    ...( !isSharedEnv && {
      SETTINGS: {
        name: "Settings",
        dropdown: true,
        selectedOption: null,
        options: [
          {
            name: "Accounts Setup",
            icon: <AdbSettings className={iconClasses} />,
            navLink: "/account_setup",
          },
        ],
      },
    }),
  };

  let timezoneAbbreviation, formattedDate;

  function handleHelpIconClick() {
    setListOpen(true);
    sendEvent(EVENT_ACTIONS.MODAL_OPEN, "HELP_SIDEBAR");
  };

  function handleSuccess(message) {
    MySwal.fire({
      text: message,
      icon: "success",
      type: "success",
    });
  };

  async function handleForceRefresh() {
    MySwal.fire({
      title: "Are you sure?",
      text: "This will fetch your latest campaign data (not performance data) from Amazon. If you've already recently force refreshed, please wait at least 15 minutes before forcing another refresh.",
      icon: "info",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((apply) => {
      if (apply.value) {
        getCampaigns(
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true,
        );
        sendEvent(EVENT_ACTIONS.BUTTON_CLICK, "FORCE_REFRESH");
        handleSuccess(
          "Your force refresh of data has been successfully scheduled. It may take sometime for the updated data to reflect.",
        );
      }
    });
  };

  function handleAccountSwitch(account) {
    switchAccount(account.value, account.label);
    window.location.reload();
  };

  if (!loading && Object.keys(selectedAccountDetails).length) {
    timezoneAbbreviation = moment
      .tz(selectedAccountDetails.timezone)
      .format('z');
  
    formattedDate = moment
      .tz(moment.now(), "Europe/London")
      .tz(selectedAccountDetails.timezone)
      .format("MMM Do, ddd h A") + ' ' + timezoneAbbreviation;
  }

  return (
    <div className="tw-flex tw-items-center tw-gap-4">
      {!loading && (
        <>
          {
            !isSharedEnv &&
            <>         
              <HelpTip
                id="force-refresh-tooltip"
                text={"Force refresh"}
                place="bottom"
              >
                <AdbClockRefresh
                  className={iconClassesWithHover}
                  onClick={handleForceRefresh}
                />
              </HelpTip>
              <HelpTip
                id="help-support-tooltip"
                text={"Help & Support"}
                place="bottom"
              >
                <AdbHelpSqaure
                  className={iconClassesWithHover}
                  onClick={handleHelpIconClick}
                />
              </HelpTip>
            </>
          }
          <span className="tw-inline-flex tw-items-center tw-rounded-xl tw-bg-brand-sky-blue-light tw-px-2 tw-py-1 tw-text-small-tags tw-font-medium tw-text-brand-blue">
            {formattedDate}
          </span>
          <AccountSelectionDropdown
            accounts={accounts}
            selectedAccountDetails={selectedAccountDetails}
            onOptionSelect={handleAccountSwitch}
          />
          <ProfileOptions
            user={user}
            organization={organization}
            signout={signout}
            isUserAdmin={isUserAdmin}
          />
        </>
      )}
      <Support
        listOpen={listOpen}
        closed={() => {
          setListOpen(false);
        }}
      />
    </div>
  );
}

export default HeaderRight;
