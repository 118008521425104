import React, { useContext, createContext, useState, useEffect, useMemo, useRef } from "react";
import { accountContext } from "./AccountContext";
import { notificationContext } from "./NotificationContext"
import { useDataProvider } from "./DataProvider";
import { AUDIT_TYPES, TARGETING_OPTIONS, DERIVED_PLACEMENTS } from "../store/constant";
import { LABELS, RAW_FILTER_CONFIGS, FILTER_KEYS, getPrefixedFilterConfig, getPrefixedMetricsFilterConfig, AMC_MULTI_TOUCH_ATTRIBUTION_METRICS, FILTER_TYPES, DSPMetricConfigs } from '../features/common/utils/rawFilters'
import { generateUniqueId } from '../Util'
import { editSavedFilters } from "../service";
import { useAuth } from "./AuthContext";
import { EVENT_ACTIONS, useUsageTracking } from './UsageTrackingContext';

const filterContext = createContext();

function useFilter() {
    return useContext(filterContext);
}

function ProvideFilter({ children }) {
    const filter = useProvideFilter();
    return (
        <filterContext.Provider value={filter}>
            {children}
        </filterContext.Provider>
    )
}

function useProvideFilter() {
    
    const {selectedAccountDetails = {}} = useContext(accountContext);    
    const [filterType, setFilterType] = useState()
    const [filterKey, setFilterKey] = useState()
    const { portfolios, rulesets, campaigns, labels, campaignLabels, daypartingStrategies} = useDataProvider();    
    const {accounts, marketplaces} = useContext(accountContext);
    const { setNotification } = useContext(notificationContext);
    const { user, justLoggedIn, setJustLoggedIn } = useAuth()
    const { sendEvent } = useUsageTracking()

    const getKey = () => {
        return `${selectedAccountDetails.id}:filters`
    }

    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem(getKey()) || "{}"))

    const checkFiltersApplied = (matchFilters=[], matchAll=false) => {
        const _matchLength = matchAll ? matchFilters.length : 1
        const _filterList = filters[filterKey]
        if (_filterList && _filterList.filter(f => matchFilters.includes(f.attribute)).length >= _matchLength)
            return true
        return false
    }

    const hierarchicalAdGroupEvaluator = (row, value) => {
        const adGroups = (value || []).map(({adGroupId}) => adGroupId)
        for (const {adgroup_id} of (row.ad_groups || [])) {
            if (adGroups.indexOf(adgroup_id) > -1) return true
        }
        return false;
    }

    const hierarchicalStateEvaluator = (row, value) => {
        const {ad_groups=[]} = row;
        for (const {state} of ad_groups) {
            if (state === value) {
                return true
            }
        }
        return false;
    }

    const hierarchicalPortfolioEvaluator = (row, value) => {
        const {ad_groups=[]} = row;
        const strValues = value.map(a => String(a))
        for (const {portfolio_id} of ad_groups) {
            if (strValues.indexOf(String(portfolio_id)) > -1) {
                return true
            }
        }
        return false;
    }

    const config = {
        [LABELS.CAMPAIGN_NAME] : {
            'attribute': RAW_FILTER_CONFIGS[LABELS.CAMPAIGN_NAME].config.attribute,
            'whitelistedKeys': [FILTER_KEYS.CAMPAIGNS, FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_PRODUCTS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY, FILTER_KEYS.CAMPAIGN_LABELS],
            'textual': true,
            'input_config': {
                'in': {
                    'placeholder': 'Comma, newline, space separated campaign names',
                    'comma_separated': true
                }
            }
        },  
        [LABELS.ADGROUP_NAME]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.ADGROUP_NAME].config.attribute,
            'whitelistedKeys': [FILTER_KEYS.DSP_PRODUCTS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY],
            'textual': true,
            'input_config': {
                'in': {
                    'placeholder': 'Comma, newline, space separated adgroup names',
                    'comma_separated': true
                }
            }
        },
        [LABELS.TARGETING] : {
            'attribute': RAW_FILTER_CONFIGS[LABELS.TARGETING].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.TARGETING].values,
            'whitelistedKeys': [AUDIT_TYPES.PRODUCT_TARGETING_CT_MIX],
            'binary': true,
        },
        [LABELS.MATCH_TYPE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.MATCH_TYPE].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.MATCH_TYPE].values,
            'whitelistedKeys': [AUDIT_TYPES.PRODUCT_TARGETING_CT_MIX],
            'binary': true,
        },
        [LABELS.NEGATIVE_MATCH_TYPES]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.NEGATIVE_MATCH_TYPES].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.NEGATIVE_MATCH_TYPES].values,
            'whitelistedKeys': [FILTER_KEYS.NEGATIVE_TARGETS],
            'binary': true,
        },
        [LABELS.CAMPAIGN_TYPES]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.CAMPAIGN_TYPES].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.CAMPAIGN_TYPES].values,
            'whitelistedKeys': [AUDIT_TYPES.PRODUCT_TARGETING_CT_MIX],
            'binary': true,
        },
        [LABELS.CAMPAIGN]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.CAMPAIGN].config.attribute,
            'options': campaigns,
            'whitelistedKeys': [FILTER_KEYS.SEARCH_TERMS, FILTER_KEYS.N_GRAMS, FILTER_KEYS.PRODUCTS, FILTER_KEYS.TARGETS, FILTER_KEYS.CHANGE_HISTORY, FILTER_KEYS.CHANGE_HISTORY_V2, FILTER_KEYS.CHANGE_HISTORY_PERF, FILTER_KEYS.SMART_RECOMMENDATIONS, FILTER_KEYS.NEGATIVE_TARGETS],
            'searchable': true,
            'multi': true,
            'eval_fn': (row, value) => {
                const adGroups = value.map(({adGroupId}) => adGroupId)
                return adGroups.indexOf(row['adGroupId']) > -1
            },
            'evaluators': {
                'products': hierarchicalAdGroupEvaluator,
                'targets': hierarchicalAdGroupEvaluator,
                'change_history': (row, value) => {
                    const r = row.task || {}
                    const adGroups = value.map(({adGroupId}) => adGroupId)
                    return adGroups.indexOf(r.adgroup_id) > -1
                }
            }
        },
        [LABELS.CAMPAIGN_FILTER]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.CAMPAIGN_FILTER].config.attribute,
            'options': campaigns.map(c=>({label: c.name, value: c.campaignId})),
            'whitelistedKeys': [FILTER_KEYS.PLACEMENT, FILTER_KEYS.DAYPARTING_RUNS, FILTER_KEYS.CAMPAIGN_LABELS],
            'searchable': true,
            'multi': true,
        },
        [LABELS.SEARCH_TERM]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.SEARCH_TERM].config.attribute,
            'textual': true,
            'whitelistedKeys': [FILTER_KEYS.SEARCH_TERMS, FILTER_KEYS.N_GRAMS]
        },
        [LABELS.SEARCH_TERM_TYPE] : {
            'attribute': RAW_FILTER_CONFIGS[LABELS.SEARCH_TERM_TYPE].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.SEARCH_TERM_TYPE].values,
            'whitelistedKeys': [FILTER_KEYS.SEARCH_TERMS, FILTER_KEYS.N_GRAMS],
            'binary': true,
        },
        [LABELS.CATEGORY]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.CATEGORY].config.attribute,
            'textual': true,
            'whitelistedKeys': [FILTER_KEYS.PRODUCTS, FILTER_KEYS.DSP_PRODUCTS]
        },
        [LABELS.BRAND]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.BRAND].config.attribute,
            'textual': true,
            'whitelistedKeys': [FILTER_KEYS.PRODUCTS, FILTER_KEYS.SERP, FILTER_KEYS.DSP_PRODUCTS]
        },
        [LABELS.PRICE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.PRICE].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.PRODUCTS, FILTER_KEYS.DSP_PRODUCTS]
        },
        [LABELS.PORTFOLIO]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.PORTFOLIO].config.attribute,
            'multi': true,
            'options': (portfolios || []).map(({portfolioId, name}) => ({label: name, value: portfolioId})),
            'whitelistedKeys': [FILTER_KEYS.CAMPAIGNS, FILTER_KEYS.SEARCH_TERMS, FILTER_KEYS.N_GRAMS, FILTER_KEYS.PRODUCTS, FILTER_KEYS.TARGETS, FILTER_KEYS.SMART_RECOMMENDATIONS],
            'searchable': true,
            'evaluators': {
                'products': hierarchicalPortfolioEvaluator,
                'targets': hierarchicalPortfolioEvaluator,
                'change_history': (row, value) => { return value.map((a) => String(a)).indexOf(String((row.task || {}).portfolio_id)) > -1}
            }
        },
        [LABELS.AUTOMATION]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.AUTOMATION].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.AUTOMATION].values,
            'eval_fn': (row, value) => {
                let result;
                if (parseInt(value) === 0) {
                    result = row['automated'] && row['automated_adgroups'].length > 0
                } else if (parseInt(value) === 1) {
                    result = (!row['automated']) && row['automated_adgroups'].length > 0
                } else {
                    result = row['automated_adgroups'].length === 0
                }
                return result;
            },
            'whitelistedKeys': [FILTER_KEYS.CAMPAIGNS]
        },
        [LABELS.TARGETING_TYPE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.TARGETING_TYPE].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.TARGETING_TYPE].values,
            'whitelistedKeys': [FILTER_KEYS.CAMPAIGNS]
        },
        [LABELS.AVAILABILITY]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.AVAILABILITY].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.AVAILABILITY].values,
            'whitelistedKeys': [FILTER_KEYS.PRODUCTS, FILTER_KEYS.DSP_PRODUCTS]
        },
        [LABELS.STATE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.STATE].config.attribute,
            'multi': true,
            'options': RAW_FILTER_CONFIGS[LABELS.STATE].values,
            'whitelistedKeys': [FILTER_KEYS.CAMPAIGNS, FILTER_KEYS.PRODUCTS, FILTER_KEYS.TARGETS, FILTER_KEYS.PLACEMENT, FILTER_KEYS.NEGATIVE_TARGETS],
            'evaluators': {
                'products': hierarchicalStateEvaluator,
                'targets': hierarchicalStateEvaluator
            }
        },
        [LABELS.CAMPAIGN_STATE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.CAMPAIGN_STATE].config.attribute,
            'multi': true,
            'options': RAW_FILTER_CONFIGS[LABELS.CAMPAIGN_STATE].values,
            'whitelistedKeys': [FILTER_KEYS.PRODUCTS, FILTER_KEYS.TARGETS, FILTER_KEYS.SEARCH_TERMS, FILTER_KEYS.N_GRAMS]
        },
        [LABELS.CAMPAIGN_TYPE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.CAMPAIGN_TYPE].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.CAMPAIGN_TYPE].values,
            'whitelistedKeys': [FILTER_KEYS.CAMPAIGNS, FILTER_KEYS.SEARCH_TERMS, FILTER_KEYS.N_GRAMS, FILTER_KEYS.TARGETS, FILTER_KEYS.PLACEMENT, FILTER_KEYS.NEGATIVE_TARGETS]
        },
        [LABELS.RUN_STATUS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.RUN_STATUS].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.RUN_STATUS].values,
            'whitelistedKeys': [FILTER_KEYS.DAYPARTING_RUNS]
        },
        [LABELS.ASIN]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.ASIN].config.attribute,
            'textual': true,
            'whitelistedKeys': [FILTER_KEYS.PRODUCTS, FILTER_KEYS.DSP_PRODUCTS, FILTER_KEYS.PRODUCT_LABELS],
            'input_config': {
                'in': {
                    'placeholder': 'Comma, newline, space separated ASINs',
                    'comma_separated': true
                },
                'does not contain': {
                    'placeholder': 'Comma, newline, space separated ASINs',
                    'comma_separated': true
                }
            }
        },
        [LABELS.SKU]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.SKU].config.attribute,
            'textual': true,
            'whitelistedKeys': [FILTER_KEYS.PRODUCTS],
            'input_config': {
                'in': {
                    'placeholder': 'Comma, newline, space separated SKUs',
                    'comma_separated': true
                },
                'does not contain': {
                    'placeholder': 'Comma, newline, space separated SKUs',
                    'comma_separated': true
                }
            }
        },
        [LABELS.TARGET]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.TARGET].config.attribute,
            'textual': true,
            'whitelistedKeys': [FILTER_KEYS.TARGETS, FILTER_KEYS.NEGATIVE_TARGETS]
        },
        [LABELS.TARGET_TERM_TYPE] : {
            'attribute': RAW_FILTER_CONFIGS[LABELS.TARGET_TERM_TYPE].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.TARGET_TERM_TYPE].values,
            'whitelistedKeys': [FILTER_KEYS.TARGETS],
            'binary': true,
        },
        [LABELS.CHANGE_TYPE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.CHANGE_TYPE].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.CHANGE_TYPE].values,
            'whitelistedKeys': [FILTER_KEYS.CHANGE_HISTORY, FILTER_KEYS.CHANGE_HISTORY_PERF],
            'eval_fn': (row, value) => {
                const {task = {}} = row;
                return task.task_type === value
            }
        },
        [LABELS.REASON]:{
            'attribute': RAW_FILTER_CONFIGS[LABELS.REASON].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.REASON].values,
            'whitelistedKeys': [FILTER_KEYS.SMART_RECOMMENDATIONS],
        },
        [LABELS.TARGET_TYPE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.TARGET_TYPE].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.TARGET_TYPE].values,
            'whitelistedKeys': [FILTER_KEYS.TARGETS, FILTER_KEYS.SMART_RECOMMENDATIONS],
            'eval_fn': (row, value) => {
                if (['broad', 'phrase', 'exact'].indexOf(value) > -1) {
                    return row['match_type'] === value
                } else {
                    return (row['resolved_target'] || {}).type === value
                }
            },
            'evaluators': {
                'change_history': (row, value) => {
                    const r = row.target || {}
                    if (['broad', 'phrase', 'exact'].indexOf(value) > -1) {
                        return r['match_type'] === value
                    } else {
                        return (r['resolved_target'] || {}).type === value
                    }
                },
            }
        },
        [LABELS.CURRENT_BID]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.CURRENT_BID].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.TARGETS, FILTER_KEYS.SMART_RECOMMENDATIONS]
        },
        [LABELS.MAX_BASE_BID_CHANGE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.MAX_BASE_BID_CHANGE].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.DAYPARTING_RUNS]
        },
        [LABELS.MIN_BASE_BID_CHANGE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.MIN_BASE_BID_CHANGE].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.DAYPARTING_RUNS]
        },
        [LABELS.BASE_BID_CHANGE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.BASE_BID_CHANGE].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.TARGETS]
        },
        [LABELS.BASE_BUDGET_CHANGE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.BASE_BUDGET_CHANGE].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.CAMPAIGNS]
        },
        [LABELS.BASE_BID_CURRENT_BID_DIFF]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.BASE_BID_CURRENT_BID_DIFF].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.TARGETS]
        },
        [LABELS.BASE_BID]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.BASE_BID].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.TARGETS]
        },
        [LABELS.ACOS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.ACOS].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.SEARCH_TERMS, FILTER_KEYS.N_GRAMS, FILTER_KEYS.CAMPAIGNS, FILTER_KEYS.TARGETS, FILTER_KEYS.PRODUCTS, FILTER_KEYS.SMART_RECOMMENDATIONS, FILTER_KEYS.SEARCH_RANKS, FILTER_KEYS.PRODUCT_GOALS, FILTER_KEYS.PLACEMENT, AUDIT_TYPES.PRODUCT_TARGETING_CT_MIX, FILTER_KEYS.AMC_PATH_TO_CONVERSION_REPORT_PATHS, FILTER_KEYS.CAMPAIGN_LABELS, FILTER_KEYS.PRODUCT_LABELS]
        },
        [LABELS.ROAS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.ROAS].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.PLACEMENT, FILTER_KEYS.AMC_PATH_TO_CONVERSION_REPORT_PATHS, FILTER_KEYS.CAMPAIGN_LABELS]
        },
        [LABELS.SALES]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.SALES].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.SEARCH_TERMS, FILTER_KEYS.N_GRAMS, FILTER_KEYS.CAMPAIGNS, FILTER_KEYS.TARGETS, FILTER_KEYS.PRODUCTS, FILTER_KEYS.SMART_RECOMMENDATIONS, FILTER_KEYS.SEARCH_RANKS, FILTER_KEYS.PRODUCT_GOALS, FILTER_KEYS.PLACEMENT, AUDIT_TYPES.PRODUCT_TARGETING_CT_MIX, FILTER_KEYS.AMC_PATH_TO_CONVERSION_REPORT_PATHS, FILTER_KEYS.AMC_TIME_TO_CONVERSION_REPORT_CAMPAIGNS, FILTER_KEYS.AMC_NEW_TO_BRAND_REPORT_CAMPAIGNS, FILTER_KEYS.AMC_TENTPOLE_FIRST_TOUCH_CAMPAIGNS, FILTER_KEYS.CAMPAIGN_LABELS, FILTER_KEYS.PRODUCT_LABELS]
        },
        [LABELS.SPEND]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.SPEND].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.SEARCH_TERMS, FILTER_KEYS.N_GRAMS, FILTER_KEYS.CAMPAIGNS, FILTER_KEYS.TARGETS, FILTER_KEYS.PRODUCTS, FILTER_KEYS.SMART_RECOMMENDATIONS, FILTER_KEYS.SEARCH_RANKS, FILTER_KEYS.PRODUCT_GOALS, FILTER_KEYS.PLACEMENT, AUDIT_TYPES.PRODUCT_TARGETING_CT_MIX, FILTER_KEYS.AMC_PATH_TO_CONVERSION_REPORT_PATHS]
        },
        [LABELS.REACH]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.REACH].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.AMC_MULTI_TOUCH_ATTRIBUTION_REPORT_CAMPAIGNS, FILTER_KEYS.AMC_TENTPOLE_FIRST_TOUCH_CAMPAIGNS]
        },
        [LABELS.IMPRESSIONS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.IMPRESSIONS].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.SEARCH_TERMS, FILTER_KEYS.N_GRAMS, FILTER_KEYS.CAMPAIGNS, FILTER_KEYS.TARGETS, FILTER_KEYS.PRODUCTS, FILTER_KEYS.SMART_RECOMMENDATIONS, FILTER_KEYS.SEARCH_RANKS, FILTER_KEYS.PRODUCT_GOALS, FILTER_KEYS.PLACEMENT, AUDIT_TYPES.PRODUCT_TARGETING_CT_MIX, FILTER_KEYS.AMC_PATH_TO_CONVERSION_REPORT_PATHS, FILTER_KEYS.AMC_MULTI_TOUCH_ATTRIBUTION_REPORT_CAMPAIGNS, FILTER_KEYS.AMC_TENTPOLE_FIRST_TOUCH_CAMPAIGNS, FILTER_KEYS.CAMPAIGN_LABELS, FILTER_KEYS.PRODUCT_LABELS]
        },
        [LABELS.CONVERSION_RATE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.CONVERSION_RATE].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.SEARCH_TERMS, FILTER_KEYS.N_GRAMS, FILTER_KEYS.PRODUCTS, FILTER_KEYS.TARGETS, FILTER_KEYS.CAMPAIGNS, FILTER_KEYS.PLACEMENT, FILTER_KEYS.CAMPAIGN_LABELS, FILTER_KEYS.PRODUCT_LABELS]
        },
        [LABELS.CTR]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.CTR].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.SEARCH_TERMS, FILTER_KEYS.N_GRAMS, FILTER_KEYS.PRODUCTS, FILTER_KEYS.TARGETS, FILTER_KEYS.CAMPAIGNS, FILTER_KEYS.PLACEMENT, FILTER_KEYS.AMC_MULTI_TOUCH_ATTRIBUTION_REPORT_CAMPAIGNS, FILTER_KEYS.CAMPAIGN_LABELS, FILTER_KEYS.PRODUCT_LABELS]
        },
        [LABELS.CPC]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.CPC].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.SEARCH_TERMS, FILTER_KEYS.N_GRAMS, FILTER_KEYS.TARGETS, FILTER_KEYS.PRODUCTS, FILTER_KEYS.CAMPAIGNS, FILTER_KEYS.CAMPAIGN_LABELS, FILTER_KEYS.PRODUCT_LABELS]
        },
        [LABELS.CPM]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.CPM].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.AMC_PATH_TO_CONVERSION_REPORT_PATHS, FILTER_KEYS.CAMPAIGN_LABELS, FILTER_KEYS.PRODUCT_LABELS]
        },
        [LABELS.CLICKS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.CLICKS].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.SEARCH_TERMS, FILTER_KEYS.N_GRAMS, FILTER_KEYS.CAMPAIGNS, FILTER_KEYS.TARGETS, FILTER_KEYS.PRODUCTS, FILTER_KEYS.SMART_RECOMMENDATIONS, FILTER_KEYS.SEARCH_RANKS, FILTER_KEYS.PRODUCT_GOALS, FILTER_KEYS.PLACEMENT, AUDIT_TYPES.PRODUCT_TARGETING_CT_MIX, FILTER_KEYS.AMC_MULTI_TOUCH_ATTRIBUTION_REPORT_CAMPAIGNS, FILTER_KEYS.AMC_TENTPOLE_FIRST_TOUCH_CAMPAIGNS, FILTER_KEYS.CAMPAIGN_LABELS, FILTER_KEYS.PRODUCT_LABELS]
        },
        [LABELS.UNITS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.UNITS].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.SEARCH_TERMS, FILTER_KEYS.N_GRAMS, FILTER_KEYS.CAMPAIGNS, FILTER_KEYS.TARGETS, FILTER_KEYS.PRODUCTS, FILTER_KEYS.SMART_RECOMMENDATIONS, FILTER_KEYS.SEARCH_RANKS, FILTER_KEYS.PRODUCT_GOALS, FILTER_KEYS.PLACEMENT, AUDIT_TYPES.PRODUCT_TARGETING_CT_MIX, FILTER_KEYS.AMC_TENTPOLE_FIRST_TOUCH_CAMPAIGNS]
        },
        [LABELS.ORDERS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.ORDERS].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.SEARCH_TERMS, FILTER_KEYS.N_GRAMS, FILTER_KEYS.CAMPAIGNS, FILTER_KEYS.TARGETS, FILTER_KEYS.PRODUCTS, FILTER_KEYS.PLACEMENT, AUDIT_TYPES.PRODUCT_TARGETING_CT_MIX, FILTER_KEYS.AMC_PATH_TO_CONVERSION_REPORT_PATHS, FILTER_KEYS.AMC_TIME_TO_CONVERSION_REPORT_CAMPAIGNS, FILTER_KEYS.AMC_NEW_TO_BRAND_REPORT_CAMPAIGNS, FILTER_KEYS.AMC_TENTPOLE_FIRST_TOUCH_CAMPAIGNS, FILTER_KEYS.CAMPAIGN_LABELS, FILTER_KEYS.PRODUCT_LABELS]
        },
        [LABELS.TOS_IS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.TOS_IS].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.CAMPAIGNS, FILTER_KEYS.TARGETS]
        },
        [LABELS.BUDGET]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.BUDGET].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.CAMPAIGNS]
        },
        [LABELS.RULESET]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.RULESET].config.attribute,
            'binary': true,
            'options': (rulesets || []).map(({_id, name}) => ({label: name, value: _id})),
            'whitelistedKeys': [FILTER_KEYS.CAMPAIGNS, FILTER_KEYS.CHANGE_HISTORY, FILTER_KEYS.CHANGE_HISTORY_V2, FILTER_KEYS.CHANGE_HISTORY_PERF],
            'evaluators': {
                'change_history': (row, value) => {
                    return row.ruleset_id === value
                }
            },
            'searchable': true
        },
        [LABELS.RULE_NAME]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.RULE_NAME].config.attribute,
            'textual': true,
            'whitelistedKeys': [FILTER_KEYS.CHANGE_HISTORY, FILTER_KEYS.CHANGE_HISTORY_V2, FILTER_KEYS.CHANGE_HISTORY_PERF],
        },
        [LABELS.BASE_BUDGET]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.BASE_BUDGET].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.CAMPAIGNS]
        },
        [LABELS.MOVE_AS_TARGET_TYPE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.MOVE_AS_TARGET_TYPE].config.attribute,
            'options': (TARGETING_OPTIONS || []).map(({label, value}) => ({label, value})),
            'whitelistedKeys': [FILTER_KEYS.TARGET_MANAGEMENT_TASKS],
        },
        [LABELS.DAYPARTING_STRATEGY]: {
            'searchable': true,
            'binary': true,
            'attribute': RAW_FILTER_CONFIGS[LABELS.DAYPARTING_STRATEGY].config.attribute,
            'options': (daypartingStrategies || []).map(({_id, name}) => ({label: name, value: _id})),
            'whitelistedKeys': [FILTER_KEYS.CAMPAIGNS, FILTER_KEYS.DAYPARTING_RUNS],
        },
        [LABELS.PRODUCT_LABELS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.PRODUCT_LABELS].config.attribute,
            'binary': true,
            'whitelistedKeys': [FILTER_KEYS.PRODUCTS, AUDIT_TYPES.PRODUCT_TARGETING_CT_MIX, FILTER_KEYS.PRODUCT_LABELS],
            'searchable': true,
            'options': (labels || []).map((v) => ({label: v, value: v}))
        },
        [LABELS.LABEL_TYPE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.LABEL_TYPE].config.attribute,
            'binary': true,
            'whitelistedKeys': [FILTER_KEYS.CAMPAIGN_LABELS, FILTER_KEYS.PRODUCT_LABELS],
            'options': RAW_FILTER_CONFIGS[LABELS.LABEL_TYPE].values
        },
        [LABELS.LABEL_EXISTS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.LABEL_EXISTS].config.attribute,
            'whitelistedKeys': [FILTER_KEYS.CAMPAIGNS, FILTER_KEYS.PRODUCTS],
            'options': RAW_FILTER_CONFIGS[LABELS.LABEL_EXISTS].values
        },
        [LABELS.CAMPAIGN_LABELS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.CAMPAIGN_LABELS].config.attribute,
            'binary': true,
            'whitelistedKeys': [FILTER_KEYS.CAMPAIGNS, FILTER_KEYS.TARGETS, FILTER_KEYS.SEARCH_TERMS, FILTER_KEYS.N_GRAMS, FILTER_KEYS.PLACEMENT, FILTER_KEYS.PRODUCTS, FILTER_KEYS.CHANGE_HISTORY, FILTER_KEYS.CHANGE_HISTORY_PERF, FILTER_KEYS.CHANGE_HISTORY_V2, FILTER_KEYS.NEGATIVE_TARGETS, FILTER_KEYS.AMC_MULTI_TOUCH_ATTRIBUTION_REPORT_CAMPAIGNS, FILTER_KEYS.AMC_NEW_TO_BRAND_REPORT_CAMPAIGNS, FILTER_KEYS.AMC_TIME_TO_CONVERSION_REPORT_CAMPAIGNS, FILTER_KEYS.AMC_TENTPOLE_FIRST_TOUCH_CAMPAIGNS, FILTER_KEYS.SMART_RECOMMENDATIONS, FILTER_KEYS.DAYPARTING_RUNS, FILTER_KEYS.CAMPAIGN_LABELS],
            'searchable': true,
            'options': (campaignLabels || []).map((v) => ({label: v, value: v}))
        },
        [LABELS.ACCOUNTS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.ACCOUNTS].config.attribute,
            'multi': true,
            'options': (accounts || []).filter(acc => acc.type == 'advertising').map(({ account_name, _id }) => ({ label: account_name, value: _id })),
            'whitelistedKeys': [FILTER_KEYS.EXECUTIVE_DASHBOARD],
            'searchable': true,
        },
        [LABELS.MARKETPLACE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.MARKETPLACE].config.attribute,
            'whitelistedKeys': [FILTER_KEYS.EXECUTIVE_DASHBOARD],
            'options': (marketplaces || []).map(v => ({ label: v, value: v }))
        },
        [LABELS.ACCOUNT_TYPE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.ACCOUNT_TYPE].config.attribute,
            'whitelistedKeys': [FILTER_KEYS.SP_ACCOUNTS],
            'options': RAW_FILTER_CONFIGS[LABELS.ACCOUNT_TYPE].values
        },
        [LABELS.TERM_TYPES]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.TERM_TYPES].config.attribute,
            'whitelistedKeys': [FILTER_KEYS.TERMS],
            'options': RAW_FILTER_CONFIGS[LABELS.TERM_TYPES].values
        },
        [LABELS.MATCH_CRITERIA]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.MATCH_CRITERIA].config.attribute,
            'whitelistedKeys': [FILTER_KEYS.TERMS],
            'options': RAW_FILTER_CONFIGS[LABELS.MATCH_CRITERIA].values
        },
        [LABELS.SCOPE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.SCOPE].config.attribute,
            'whitelistedKeys': [FILTER_KEYS.TERMS],
            'options': RAW_FILTER_CONFIGS[LABELS.SCOPE].values
        },  
        [LABELS.RATING]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.RATING].config.attribute,
            'numeric' : true,
            'whitelistedKeys': [FILTER_KEYS.SERP],
        },
        [LABELS.REVIEWS] : {
            'attribute': RAW_FILTER_CONFIGS[LABELS.REVIEWS].config.attribute,
            'numeric' : true,
            'whitelistedKeys': [FILTER_KEYS.SERP],
        },
        [LABELS.FREQUENCY] : {
            'attribute': RAW_FILTER_CONFIGS[LABELS.FREQUENCY].config.attribute,
            'whitelistedKeys': [FILTER_KEYS.SEARCH_RANKS],
            'options': RAW_FILTER_CONFIGS[LABELS.FREQUENCY].values
        },
        [LABELS.MISSING_MATCH_TYPES] : {
            'attribute': RAW_FILTER_CONFIGS[LABELS.MISSING_MATCH_TYPES].config.attribute,
            'whitelistedKeys': [FILTER_KEYS.SMART_RECOMMENDATIONS],
            "multi": true,
            'options': RAW_FILTER_CONFIGS[LABELS.MISSING_MATCH_TYPES].values
        },
        [LABELS.AFN_QUANTITY]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.AFN_QUANTITY].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.PRODUCTS]
        },
        [LABELS.MFN_QUANTITY]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.MFN_QUANTITY].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.PRODUCTS]
        },
        [LABELS.DAYS_OF_SUPPLY]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.DAYS_OF_SUPPLY].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.PRODUCTS]
        },
        [LABELS.TOS_BID_ADJUSTMENT]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.TOS_BID_ADJUSTMENT].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.PLACEMENT]
        },
        [LABELS.PP_BID_ADJUSTMENT]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.PP_BID_ADJUSTMENT].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.PLACEMENT]
        },
        [LABELS.AVG_BUDGET_UTIL]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.AVG_BUDGET_UTIL].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.CAMPAIGNS]
        },
        [LABELS.AVG_BUDGET_UTIL_HOUR]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.AVG_BUDGET_UTIL_HOUR].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.CAMPAIGNS]
        },
        [LABELS.LAUNCH_STATUS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.LAUNCH_STATUS].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.LAUNCH_STATUS].values,
            'whitelistedKeys': [FILTER_KEYS.LAUNCH_HISTORY]
        },
        [LABELS.BIDDING_STRATEGY]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.BIDDING_STRATEGY].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.BIDDING_STRATEGY].values,
            'whitelistedKeys': [FILTER_KEYS.CAMPAIGNS]
        },
        [LABELS.ROS_BID_ADJUSTMENT]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.ROS_BID_ADJUSTMENT].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.PLACEMENT]
        },
        [LABELS.TOS_BID_BASE_ADJUSTMENT]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.TOS_BID_BASE_ADJUSTMENT].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.PLACEMENT]
        },
        [LABELS.ROS_BID_BASE_ADJUSTMENT]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.ROS_BID_BASE_ADJUSTMENT].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.PLACEMENT]
        },
        [LABELS.PP_BID_BASE_ADJUSTMENT]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.PP_BID_BASE_ADJUSTMENT].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.PLACEMENT]
        },
        [LABELS.AD_TYPES]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.AD_TYPES].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.AD_TYPES].values,
            'whitelistedKeys': [FILTER_KEYS.AMC_PATH_TO_CONVERSION_REPORT_PATHS],
            'multi': true,
        },
        [FILTER_TYPES.CAMPAIGN_TYPE_WITH_DSP]: {
            'label': RAW_FILTER_CONFIGS[FILTER_TYPES.CAMPAIGN_TYPE_WITH_DSP].config.label,
            'attribute': RAW_FILTER_CONFIGS[FILTER_TYPES.CAMPAIGN_TYPE_WITH_DSP].config.attribute,
            'options': RAW_FILTER_CONFIGS[FILTER_TYPES.CAMPAIGN_TYPE_WITH_DSP].values,
            'whitelistedKeys': [FILTER_KEYS.AMC_TIME_TO_CONVERSION_REPORT_CAMPAIGNS, FILTER_KEYS.AMC_MULTI_TOUCH_ATTRIBUTION_REPORT_CAMPAIGNS, FILTER_KEYS.AMC_NEW_TO_BRAND_REPORT_CAMPAIGNS, FILTER_KEYS.AMC_TENTPOLE_FIRST_TOUCH_CAMPAIGNS]
        },
        [LABELS.TIME_TO_CONVERSION_CATEGORY]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.TIME_TO_CONVERSION_CATEGORY].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.TIME_TO_CONVERSION_CATEGORY].values,
            'whitelistedKeys': [FILTER_KEYS.AMC_TIME_TO_CONVERSION_REPORT_CAMPAIGNS],
            'searchable': true,
            'multi': true,
        },
        [LABELS.PATH_FREQUENCY]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.PATH_FREQUENCY].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.AMC_PATH_TO_CONVERSION_REPORT_PATHS],
        },
        [LABELS.ATC]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.ATC].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.AMC_TENTPOLE_FIRST_TOUCH_CAMPAIGNS],
        },
        [LABELS.CONVERSION_RATE_PER_THOUSAND_IMPRESSIONS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.CONVERSION_RATE_PER_THOUSAND_IMPRESSIONS].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.AMC_PATH_TO_CONVERSION_REPORT_PATHS],
        },
        [LABELS.LEAD_ASIN]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.LEAD_ASIN].config.attribute,
            'textual': true,
            'whitelistedKeys': [FILTER_KEYS.AMC_ASIN_OVERLAP_REPORT_OVERLAPS],
            'input_config': {
                'in': {
                    'placeholder': 'Comma, newline, space separated ASINs',
                    'comma_separated': true
                },
                'does not contain': {
                    'placeholder': 'Comma, newline, space separated ASINs',
                    'comma_separated': true
                }
            }
        },
        [LABELS.LEAD_ASIN_ORDERS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.LEAD_ASIN_ORDERS].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.AMC_ASIN_OVERLAP_REPORT_OVERLAPS],
        },
        [LABELS.LEAD_ASIN_CUSTOMERS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.LEAD_ASIN_CUSTOMERS].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.AMC_ASIN_OVERLAP_REPORT_OVERLAPS],
        },
        [LABELS.CUSTOMERS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.CUSTOMERS].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.AMC_TENTPOLE_FIRST_TOUCH_CAMPAIGNS],
        },
        [LABELS.OVERLAP_ASIN]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.OVERLAP_ASIN].config.attribute,
            'textual': true,
            'whitelistedKeys': [FILTER_KEYS.AMC_ASIN_OVERLAP_REPORT_OVERLAPS],
            'input_config': {
                'in': {
                    'placeholder': 'Comma, newline, space separated ASINs',
                    'comma_separated': true
                },
                'does not contain': {
                    'placeholder': 'Comma, newline, space separated ASINs',
                    'comma_separated': true
                }
            }
        },
        [LABELS.COMMON_CUSTOMERS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.COMMON_CUSTOMERS].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.AMC_ASIN_OVERLAP_REPORT_OVERLAPS],
        },
        [LABELS.USER_OVERLAP]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.USER_OVERLAP].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.AMC_ASIN_OVERLAP_REPORT_OVERLAPS],
        },
        [LABELS.NEW_TO_BRAND_ORDERS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.NEW_TO_BRAND_ORDERS].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.AMC_NEW_TO_BRAND_REPORT_CAMPAIGNS, FILTER_KEYS.AMC_TENTPOLE_FIRST_TOUCH_CAMPAIGNS],
        },
        [LABELS.NEW_TO_BRAND_SALES]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.NEW_TO_BRAND_SALES].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.AMC_NEW_TO_BRAND_REPORT_CAMPAIGNS, FILTER_KEYS.AMC_TENTPOLE_FIRST_TOUCH_CAMPAIGNS],
        },
        [LABELS.NEW_TO_BRAND_UNITS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.NEW_TO_BRAND_UNITS].config.attribute,
            'numeric': true,
            'whitelistedKeys': [],
        },
        [LABELS.NEW_TO_BRAND_CUSTOMERS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.NEW_TO_BRAND_CUSTOMERS].config.attribute,
            'numeric': true,
            'whitelistedKeys': [],
        },
        [LABELS.NEW_TO_BRAND_ORDERS_PERCENTAGE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.NEW_TO_BRAND_ORDERS_PERCENTAGE].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.AMC_NEW_TO_BRAND_REPORT_CAMPAIGNS, FILTER_KEYS.AMC_TENTPOLE_FIRST_TOUCH_CAMPAIGNS],
        },
        [LABELS.NEW_TO_BRAND_SALES_PERCENTAGE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.NEW_TO_BRAND_SALES_PERCENTAGE].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.AMC_NEW_TO_BRAND_REPORT_CAMPAIGNS, FILTER_KEYS.AMC_TENTPOLE_FIRST_TOUCH_CAMPAIGNS],
        },
        [FILTER_TYPES.DSP_CAMPAIGN_DELIVERY_STATUS]: {
            'label': RAW_FILTER_CONFIGS[FILTER_TYPES.DSP_CAMPAIGN_DELIVERY_STATUS].config.label,
            'attribute': RAW_FILTER_CONFIGS[FILTER_TYPES.DSP_CAMPAIGN_DELIVERY_STATUS].config.attribute,
            'options': RAW_FILTER_CONFIGS[FILTER_TYPES.DSP_CAMPAIGN_DELIVERY_STATUS].values,
            'whitelistedKeys': [FILTER_KEYS.DSP_CAMPAIGNS],
            'multi': true,
            'input_config': {
                'show_operator_choices': true
            }
        },
        [FILTER_TYPES.DSP_ADGROUP_DELIVERY_STATUS]: {
            'label': RAW_FILTER_CONFIGS[FILTER_TYPES.DSP_ADGROUP_DELIVERY_STATUS].config.label,
            'attribute': RAW_FILTER_CONFIGS[FILTER_TYPES.DSP_ADGROUP_DELIVERY_STATUS].config.attribute,
            'options': RAW_FILTER_CONFIGS[FILTER_TYPES.DSP_ADGROUP_DELIVERY_STATUS].values,
            'whitelistedKeys': [FILTER_KEYS.DSP_ADGROUPS],
            'multi': true,
            'input_config': {
                'show_operator_choices': true
            }
        },
        [LABELS.DERIVED_GOAL]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.DERIVED_GOAL].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.DERIVED_GOAL].values,
            'whitelistedKeys': [FILTER_KEYS.DSP_CAMPAIGNS]
        },
        [FILTER_TYPES.DSP_CAMPAIGN_BID_STRATEGY]: {
            'label': RAW_FILTER_CONFIGS[FILTER_TYPES.DSP_CAMPAIGN_BID_STRATEGY].config.label,
            'attribute': RAW_FILTER_CONFIGS[FILTER_TYPES.DSP_CAMPAIGN_BID_STRATEGY].config.attribute,
            'options': RAW_FILTER_CONFIGS[FILTER_TYPES.DSP_CAMPAIGN_BID_STRATEGY].values,
            'whitelistedKeys': [FILTER_KEYS.DSP_CAMPAIGNS],
        },
        [FILTER_TYPES.DSP_ADGROUP_BID_STRATEGY]: {
            'label': RAW_FILTER_CONFIGS[FILTER_TYPES.DSP_ADGROUP_BID_STRATEGY].config.label,
            'attribute': RAW_FILTER_CONFIGS[FILTER_TYPES.DSP_ADGROUP_BID_STRATEGY].config.attribute,
            'options': RAW_FILTER_CONFIGS[FILTER_TYPES.DSP_ADGROUP_BID_STRATEGY].values,
            'whitelistedKeys': [FILTER_KEYS.DSP_ADGROUPS],
        },
        [LABELS.KPI_STATUS]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.KPI_STATUS].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.KPI_STATUS].values,
            'whitelistedKeys': [FILTER_KEYS.DSP_CAMPAIGNS],
        },
        [LABELS.INVENTORY_TYPE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.INVENTORY_TYPE].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.INVENTORY_TYPE].values,
            'whitelistedKeys': [FILTER_KEYS.DSP_ADGROUPS],
        },
        [LABELS.DELIVERY_PROFILE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.DELIVERY_PROFILE].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.DELIVERY_PROFILE].values,
            'whitelistedKeys': [FILTER_KEYS.DSP_ADGROUPS],
        },
        [LABELS.BASE_BID]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.BASE_BID].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.DSP_ADGROUPS],
        },
        [LABELS.MAX_AVERAGE_CPM]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.MAX_AVERAGE_CPM].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.DSP_ADGROUPS],
        },
        [FILTER_TYPES.DSP_BUDGET]: {
            'label': RAW_FILTER_CONFIGS[FILTER_TYPES.DSP_BUDGET].config.label,
            'attribute': RAW_FILTER_CONFIGS[FILTER_TYPES.DSP_BUDGET].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.DSP_ADGROUPS],
        },
        [LABELS.CATCH_UP_BOOST_PERCENTAGE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.CATCH_UP_BOOST_PERCENTAGE].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.DSP_ADGROUPS],
        },
        [LABELS.AUDIENCE_SEGMENT] : {
            'attribute': RAW_FILTER_CONFIGS[LABELS.AUDIENCE_SEGMENT].config.attribute,
            'whitelistedKeys': [FILTER_KEYS.DSP_AUDIENCES],
            'textual': true,
            'input_config': {
                'in': {
                    'placeholder': 'Comma, newline, space separated campaign names',
                    'comma_separated': true
                }
            }
        },
        [LABELS.SUPPLY_SOURCE] : {
        'attribute': RAW_FILTER_CONFIGS[LABELS.SUPPLY_SOURCE].config.attribute,
            'whitelistedKeys': [FILTER_KEYS.DSP_INVENTORY],
            'textual': true,
            'input_config': {
                'in': {
                    'placeholder': 'Comma, newline, space separated campaign names',
                    'comma_separated': true
                }
            }
        },
        [LABELS.SITE] : {
            'attribute': RAW_FILTER_CONFIGS[LABELS.SITE].config.attribute,
            'whitelistedKeys': [FILTER_KEYS.DSP_INVENTORY],
            'textual': true,
            'input_config': {
                'in': {
                    'placeholder': 'Comma, newline, space separated campaign names',
                    'comma_separated': true
                }
            }
        },
        [LABELS.TARGETING_METHOD]: {
            'label': RAW_FILTER_CONFIGS[LABELS.TARGETING_METHOD].config.label,
            'attribute': RAW_FILTER_CONFIGS[LABELS.TARGETING_METHOD].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.TARGETING_METHOD].values,
            'whitelistedKeys': [FILTER_KEYS.DSP_AUDIENCES],
        },
        [LABELS.AUDIENCE_NAME]: {
            'label': RAW_FILTER_CONFIGS[LABELS.AUDIENCE_NAME].config.label,
            'attribute': RAW_FILTER_CONFIGS[LABELS.AUDIENCE_NAME].config.attribute,
            'whitelistedKeys': [FILTER_KEYS.AMC_AUDIENCES],
            'textual': true,
            'input_config': {
                'in': {
                    'placeholder': 'Comma, newline, space separated audience names',
                    'comma_separated': true
                }
            }
        },
        [LABELS.AUDIENCE_TYPE]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.AUDIENCE_TYPE].config.attribute,
            'options': RAW_FILTER_CONFIGS[LABELS.AUDIENCE_TYPE].values,
            'whitelistedKeys': [FILTER_KEYS.AMC_AUDIENCES],
            'binary': true,
        },
        [LABELS.AUDIENCE_COUNT]: {
            'attribute': RAW_FILTER_CONFIGS[LABELS.AUDIENCE_COUNT].config.attribute,
            'numeric': true,
            'whitelistedKeys': [FILTER_KEYS.AMC_AUDIENCES]
        },
        ...getPrefixedFilterConfig([FILTER_KEYS.CHANGE_HISTORY_PERF]),
        ...getPrefixedMetricsFilterConfig('Top of Search', 'tos', [FILTER_KEYS.PLACEMENT]),
        ...getPrefixedMetricsFilterConfig('Product Pages', 'detail', [FILTER_KEYS.PLACEMENT]),
        ...getPrefixedMetricsFilterConfig('Rest of Search', 'other', [FILTER_KEYS.PLACEMENT]),
        ...getPrefixedMetricsFilterConfig(
            "First Touch Attribution", 
            "first_touch_attribution", 
            [FILTER_KEYS.AMC_MULTI_TOUCH_ATTRIBUTION_REPORT_CAMPAIGNS],
            false,
            AMC_MULTI_TOUCH_ATTRIBUTION_METRICS
        ),
        ...getPrefixedMetricsFilterConfig(
            "Last Touch Attribution", 
            "last_touch_attribution", 
            [FILTER_KEYS.AMC_MULTI_TOUCH_ATTRIBUTION_REPORT_CAMPAIGNS], 
            false,
            AMC_MULTI_TOUCH_ATTRIBUTION_METRICS
        ),
        ...getPrefixedMetricsFilterConfig(
            "Linear Touch Attribution", 
            "linear_touch_attribution", 
            [FILTER_KEYS.AMC_MULTI_TOUCH_ATTRIBUTION_REPORT_CAMPAIGNS], 
            false,
            AMC_MULTI_TOUCH_ATTRIBUTION_METRICS
        ),
        ...getPrefixedMetricsFilterConfig(
            "Position Based Attribution", 
            "position_based_attribution", 
            [FILTER_KEYS.AMC_MULTI_TOUCH_ATTRIBUTION_REPORT_CAMPAIGNS],
            false,
            AMC_MULTI_TOUCH_ATTRIBUTION_METRICS
        ),
        ...Object.entries(DSPMetricConfigs).reduce((acc, [key, { attribute, label, whitelistedKeys }]) => { 
            acc[key] = { 
                numeric: true,
                attribute,
                label,
                whitelistedKeys
            };

            return acc;
        }, {})
    }

    useEffect(() => {
        try {
            let query = window.location.search.substring(1)
            query = atob(query)
            let params = new URLSearchParams(query);
            if (params.has("filterKey") && params.has("filter")) {
                let filter = JSON.parse(params.get("filter") || "{}");
                let filterKey = params.get("filterKey");

                filters[filterKey] = [filter];
                setFilters({ ...filters });
            } else {
                setFilters(JSON.parse(localStorage.getItem(getKey()) || "{}"))
            }
        } catch (e) {
            console.log(e)
        }
    }, [selectedAccountDetails.id])

    useEffect(() => {
        localStorage.setItem(getKey(), JSON.stringify(filters))
    }, [filters])

    const [allSavedFilters, setAllSavedFilters] = useState([])
    const [selectedSavedFilter, setSelectedSavedFilter] = useState({})

    const savedFiltersForView = useMemo(() => {
        return allSavedFilters.filter((savedFilter) => savedFilter['filterKey'] === filterKey);
    }, [allSavedFilters, filterKey])

    // This useEffect sets allSavedFilters from backend upon load.
    useEffect(() => {
        if (Object.keys(user).length !== 0) {
            setAllSavedFilters(user['saved_filters'] || [])
        }
    }, [user])

    const getSavedFiltersKey = () => {
        return `${selectedAccountDetails.id}:appliedSavedFilters`
    }

    const getFilterLocalStorageKey = ({ accountId='' }) => {
       return {
            accountFiltersKey: accountId ? `${accountId}:filters` : getKey(),
            accountSavedFiltersKey: accountId ? `${accountId}:appliedSavedFilters` : getSavedFiltersKey(),
        }
    }

    const getFiltersAndSavedFiltersForAccount = ({ accountId='' }) => {
        return {
            appliedAccountFilters: JSON.parse(localStorage.getItem(getFilterLocalStorageKey({ accountId }).accountFiltersKey) || "{}"),
            appliedAccountSavedFilters: JSON.parse(localStorage.getItem(getFilterLocalStorageKey({ accountId }).accountSavedFiltersKey) || "{}"),
        }
    }

    const setFiltersAndSavedFiltersForAccount = ({ accountId='', appliedAccountFilters={}, appliedAccountSavedFilters={} }) => {
        if(Object.keys(appliedAccountFilters).length !== 0){
            localStorage.setItem(getFilterLocalStorageKey({ accountId }).accountFiltersKey, JSON.stringify(appliedAccountFilters))
        }
        if(Object.keys(appliedAccountSavedFilters).length !== 0){
            localStorage.setItem(getFilterLocalStorageKey({ accountId }).accountSavedFiltersKey, JSON.stringify(appliedAccountSavedFilters))
        }
    }

    const applyDefaultSavedFiltersUponLogin = (savedFilters=[], accountId) => {
        let defaultFilters = savedFilters.filter(filter => filter.isDefault)
        let { appliedAccountFilters, appliedAccountSavedFilters } = getFiltersAndSavedFiltersForAccount({ accountId })
        let hasAccountFiltersChanged = false;

        defaultFilters.forEach(defaultFilter => {
            if (!(defaultFilter.filterKey in appliedAccountFilters) || appliedAccountFilters[defaultFilter.filterKey] === null || appliedAccountFilters[defaultFilter.filterKey].length === 0) {
                appliedAccountFilters[defaultFilter.filterKey] = [...defaultFilter.filters]
                appliedAccountSavedFilters[defaultFilter.filterKey] = defaultFilter.id
                hasAccountFiltersChanged = true
            }
        });

        if(hasAccountFiltersChanged){
            setFiltersAndSavedFiltersForAccount({ accountId, appliedAccountFilters, appliedAccountSavedFilters })
        }

        if(accountId === selectedAccountDetails.id){
            setFilters(appliedAccountFilters)
        }
    }
    
    // This useEffect only runs when user logs in and sets the default filters across accounts and all pages.
    useEffect(() => {
        if(justLoggedIn && Object.keys(user).length !== 0 && accounts.length !== 0 ){
            accounts.forEach((account) => {applyDefaultSavedFiltersUponLogin(user['saved_filters'] || [], account.id)})
        }
    }, [justLoggedIn, user, accounts])

    // This useEffect runs when page is reloaded or savedFilters are updated in any way. This ensures the applied Saved Filters 
    // that are stored in local storage are refreshed in case of a user deleting a saved filter in a different browser or device.
    useEffect(() => {
        if(accounts.length !== 0 && allSavedFilters.length !== 0){
            accounts.forEach((account) => {
                let accountId = account.id
                let { appliedAccountFilters, appliedAccountSavedFilters } = getFiltersAndSavedFiltersForAccount({ accountId })

                Object.keys(appliedAccountSavedFilters).forEach(viewfilterKey => {
                    if (appliedAccountSavedFilters[viewfilterKey] === '') {
                        return;
                    }

                    const savedFilterIndex = allSavedFilters.findIndex(savedFilter => savedFilter.id === appliedAccountSavedFilters[viewfilterKey]);

                    if (savedFilterIndex !== -1) {
                        appliedAccountFilters[viewfilterKey] = [...allSavedFilters[savedFilterIndex].filters] // This is for incase a user updates a saved filter from an account/device/browser which is applied in a different account/browser/device
                    } else {
                        appliedAccountSavedFilters[viewfilterKey] = ''; // This is for incase a user deletes a saved filter from an account/device/browser which is applied in a different account/browser/device
                    }
                })
                
                setFiltersAndSavedFiltersForAccount({ accountId, appliedAccountFilters, appliedAccountSavedFilters })
            })
        }
    }, [allSavedFilters, accounts])

    const shouldTriggerSaveSelectedToLocal = useRef(true);

    // This useEffect is responsible for setting the previously applied Saved Filter to selected saved filter, when user switches to a new page.
    useEffect(() => {
        let appliedSavedFilterForView = {}
        if(savedFiltersForView.length > 0){
            let { appliedAccountSavedFilters } = getFiltersAndSavedFiltersForAccount({})
            appliedSavedFilterForView = savedFiltersForView.find(savedFilter => savedFilter.id === appliedAccountSavedFilters[filterKey])
        }
        shouldTriggerSaveSelectedToLocal.current = false
        setSelectedSavedFilter(appliedSavedFilterForView || {});
    }, [filterKey, savedFiltersForView]);

    // This useEffect is responsible for saving a applied saved filter to local storage.
    useEffect(() => {
        if(filterKey && shouldTriggerSaveSelectedToLocal.current){
            let { appliedAccountSavedFilters } = getFiltersAndSavedFiltersForAccount({})
            appliedAccountSavedFilters[filterKey] = selectedSavedFilter.id || ''
            setFiltersAndSavedFiltersForAccount({ appliedAccountSavedFilters })
        }
        shouldTriggerSaveSelectedToLocal.current = true
    }, [selectedSavedFilter]);

    const handleSavedFilterCreate = async(name, isDefault) => {
        const uniqueId = generateUniqueId();
        let savedFilter = { name, isDefault, 'filters': getFilters(), filterKey, "id": uniqueId };
        try {
            const { saved_filters } = await editSavedFilters({ 'saved_filter': savedFilter, update_type: "saved_filter_create" })
            
            setSelectedSavedFilter(savedFilter)
            setAllSavedFilters(saved_filters)
            
            setNotification("Success", "Filters saved successfully.", "success");
            sendEvent(EVENT_ACTIONS.SUBMIT_SUCCESS, "CREATED_SAVED_FILTER")
        } catch (error) {
            console.log(error)
            setNotification("Error", "Failed to save filters.", "error");
            sendEvent(EVENT_ACTIONS.SUBMIT_FAILURE, "CREATE_SAVED_FILTER_FAILED")
        }
    }

    const handleSavedFilterUpdate = async(name, isDefault, toUpdateSavedFilter) => {
        const updatedSavedFilter = { ...toUpdateSavedFilter, filters: [...getFilters()], name, isDefault }
        try {
            const { saved_filters } = await editSavedFilters({ 'saved_filter': updatedSavedFilter, update_type: "saved_filter_update" })
            
            setSelectedSavedFilter(updatedSavedFilter)
            setAllSavedFilters(saved_filters)

            setNotification("Success", "Saved Filter updated successfully.", "success");
            sendEvent(EVENT_ACTIONS.SUBMIT_SUCCESS, "UPDATED_SAVED_FILTER")
        } catch (error) {
            console.log(error)
            setNotification("Error", "Failed to update saved filter.", "error");
            sendEvent(EVENT_ACTIONS.SUBMIT_FAILURE, "UPDATE_SAVED_FILTER_FAILED")
        }
    }

    const handleSavedFilterDelete = async(savedFiltersToBeDeletedList) => {
        try {
            const { saved_filters } = await editSavedFilters({ 'saved_filter': {savedFiltersToBeDeletedList}, update_type: "saved_filter_delete" })

            if(Object.keys(selectedSavedFilter).length > 0 && savedFiltersToBeDeletedList.some(savedFilter => savedFilter.id === selectedSavedFilter.id)){
                setSelectedSavedFilter({})
            }
            setAllSavedFilters(saved_filters)

            setNotification("Success", "Saved Filters deleted successfully.", "success");
            sendEvent(EVENT_ACTIONS.SUBMIT_SUCCESS, "DELETED_SAVED_FILTERS")
        } catch (error) {
            console.log(error)
            setNotification("Error", "Failed to delete saved filters.", "error");
            sendEvent(EVENT_ACTIONS.SUBMIT_FAILURE, "DELETE_SAVED_FILTERS_FAILED")
        }
    }
    
    const applySavedFilter = (savedFilter) => {
        const newFilters = { ...filters };
        newFilters[savedFilter.filterKey] = [...savedFilter.filters];
        setFilters(newFilters);
        setSelectedSavedFilter(savedFilter)
        sendEvent(EVENT_ACTIONS.ACTION, `APPLIED_SAVED_FILTER`)
    }
    
    const getFilters = () => {
        return (filters[filterKey] || [])
    }

    const addFilter = (filter, fk) => {
        const arr = (filters[fk || filterKey] || [])
        arr.push(filter)
        filters[fk || filterKey] = [...arr]
        setFilters({...filters})
        setSelectedSavedFilter({})
    }

    const addFilterAtIndex = (filter, idx) => {
        const arr = (filters[filterKey] || [])
        arr[idx] = filter
        filters[filterKey] = [...arr]
        setFilters({...filters})
        setSelectedSavedFilter({})
    }

    const removeFilter = (idx) => {
        const arr = (filters[filterKey] || [])
        arr.splice(idx, 1)
        filters[filterKey] = [...arr]
        setFilters({...filters})
        setSelectedSavedFilter({})
    }

    const resetFilters = (fk) => {
        filters[fk || filterKey] = []
        setFilters({...filters})
        setSelectedSavedFilter({})
    }

    const evaluate = (row) => {
        const filtersArr = getFilters(filterKey)
        if (filtersArr.length === 0) return true;
        let result = true;
        for (let f of filtersArr) {
            const {attribute, operator, value, label} = f;
            const {eval_fn, textual, evaluators={}, multi=false} = config[label] || {}
            let r;
            if (evaluators[filterKey]) {
                r = evaluators[filterKey](row, value)
            } else if (eval_fn) {
                r = eval_fn(row, value)
            } else if (operator == "=") {
                if (typeof(row[attribute]) === "object") {
                    r = (row[attribute] || []).map((v) => String(v)).indexOf(String(value)) > -1
                } else if (multi) {
                    r = value.map(a => String(a)).indexOf(String(row[attribute])) > -1
                } else if (textual) {
                    r = (row[attribute] || "").toLowerCase() == (value || "").toLowerCase()
                } else {
                    r = row[attribute] == value
                }
            } else if (operator == ">") {
                r = row[attribute] > value
            } else if (operator == ">=") {
                r = row[attribute] >= value
            } else if (operator == "<") {
                r = row[attribute] < value
            } else if (operator == "<=") {
                r = row[attribute] <= value
            } else if (operator == 'in') {
                const arg = textual ? row[attribute]?.toLowerCase() : row[attribute]
                r = arg?.indexOf(value) > -1
            }
            result = result && r
        }
        return result
    }

    const [showSaveFilterModal, setShowSaveFilterModal] = useState(false)

    const openSaveFilterModal = () => {
        setShowSaveFilterModal(true);
    }

    const closeSaveFilterModal = () => {
        setShowSaveFilterModal(false);
    }

    const [showDisplaySavedFiltersModal, setShowDisplaySavedFiltersModal] = useState(false);

    const openDisplaySavedFiltersModal = () => {
        setShowDisplaySavedFiltersModal(true);
    }

    const closeDisplaySavedFiltersModal = () => {
        setShowDisplaySavedFiltersModal(false);
    }

    return {
        portfolios,
        addFilter,
        filterType,
        setFilterType,
        filters,
        getFilters,
        removeFilter,
        resetFilters,
        evaluate,
        rulesets,
        config,
        setFilterKey,
        addFilterAtIndex,
        filterKey,
        checkFiltersApplied,
        showSaveFilterModal,
        openSaveFilterModal,
        closeSaveFilterModal,
        handleSavedFilterCreate,
        handleSavedFilterUpdate,
        handleSavedFilterDelete,
        applySavedFilter,
        selectedSavedFilter,
        setSelectedSavedFilter,
        showDisplaySavedFiltersModal,
        openDisplaySavedFiltersModal,
        closeDisplaySavedFiltersModal,
        savedFiltersForView,
    }
}

export {
    useFilter,
    ProvideFilter
}