import React from "react";

import AdbAvatar from "../../../ui/AdbAvatar";

import { iconClasses } from "../../../utils/commonTailwindClasses";
import {
  AdbBriefCase,
  AdbBuilding,
  AdbSignOut,
  AdbUser,
  AdbUsers,
} from "../../../adb-icons/AdbIcons";
import AdbDropdown from "../../../ui/AdbDropdown";
import { isSharedEnv } from "../../../service";

const IMAGE_SIZE = {
  imgSize: "tw-w-8 tw-h-8",
};

/**
 * Renders Organization and User account options icons in Main header.
 */
function ProfileOptions({
  user,
  organization,
  signout,
  isUserAdmin
}) {
  const organizationImage = (
    <AdbAvatar
      image={
        organization.logo?.small ? (
          `${organization.logo_prefix}${organization.logo.small}`
        ) : (
          <AdbBuilding />
        )
      }
      imageSize={IMAGE_SIZE}
    />
  );
  const userImage = (
    <AdbAvatar
      image={
        user.display_img ? (
          `${user.display_img_prefix}${user.display_img}`
        ) : (
          <AdbUser />
        )
      }
      imageSize={IMAGE_SIZE}
    />
  );

  const profileMenuItems = [
    (!isSharedEnv && {
      name: "View profile",
      icon: <AdbUser className={iconClasses} />,
      navLink: "/profile",
    }),
    {
      name: "Sign out",
      icon: <AdbSignOut className={`${iconClasses} !tw-stroke-red-500`} />,
      handleClick: signout,
      linkStyles: "!tw-text-red-500",
    },
  ];
  const profileHeaderData = {
    image: userImage,
    heading: user.name,
    subHeading: user.email,
  };

  const accountMenuItems = [
    (!isSharedEnv && {
      name: "Accounts Manager",
      icon: <AdbBriefCase className={iconClasses} />,
      navLink: "/accounts_manager/",
    }),
    ...(isUserAdmin && !isSharedEnv ? [
      {
        name: "User Manager",
        icon: <AdbUsers className={iconClasses} />,
        navLink: "/user_manager",
      },
      {
        name: "Organization settings",
        icon: <AdbBuilding className={iconClasses} />,
        navLink: "/org_settings",
      }
    ] : []),
    
  ];
  const accountHeaderData = {
    image: organizationImage,
    heading: organization.name,
    subHeading: user.email,
  };

  return (
    <div className="tw-flex">
      <div className="tw-flex tw-gap-3">
        <AdbDropdown
          dropdownButton={organizationImage}
          dropdownItemsProps={{
            menuItems: accountMenuItems,
            menuItemHeader: accountHeaderData,
            hideBorder: isSharedEnv
          }}
        />

        <span className="tw-border-l tw-border-brand-very-light-gray"></span>

        <AdbDropdown
          dropdownButton={userImage}
          dropdownItemsProps={{
            menuItems: profileMenuItems,
            menuItemHeader: isSharedEnv ? {} : profileHeaderData,
            hideBorder: isSharedEnv
          }}
        />
      </div>
    </div>
  );
}

export default ProfileOptions;
