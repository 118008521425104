import { Form, InputGroup, Row } from "react-bootstrap";
import {useEffect, useState} from 'react';
import { EVENT_ACTIONS, useUsageTracking } from "../../context/UsageTrackingContext";

export default function SearchBox({ placeholder, value, onChange, onEnter, changeOnBlur=true, autoFocus=false }) {

    const { sendEvent } = useUsageTracking()
    const [isFocused, setIsFocused] = useState(false)
    
    const handleEnter = (arg) => {
        if (onEnter) {
            onEnter(arg)
        }
        sendEvent(EVENT_ACTIONS.FORM_SUBMIT, `SEARCH_${placeholder}`)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && onEnter) {
            handleEnter(event.target.value)
        }
    }

    const [v, setV] = useState(value)

    useEffect(() => {
        setV(value)
    }, [value])


    const handleChange = (event) => {
        if (onChange) {
            onChange(event.target.value)
        } else {
            setV(event.target.value)
        }
    }

    const handleOnBlur = (event) => {
        if (changeOnBlur) {
            handleEnter(event.target.value)
        }
        setIsFocused(false)
    }

    const handleOnFocus = () => {
        setIsFocused(true)
    }

    return <div className="search-box">
        <InputGroup style={{ backgroundColor: 'white', borderRadius: '20px', padding: "2px" }} className="">
            <input autoFocus={autoFocus} style={{ border: 'none', marginLeft: "5px", padding: '5px 5px 5px 5px', fontSize: '12px' }} className="form-control" placeholder={placeholder} value={onEnter ? v : value} onFocus={handleOnFocus} onBlur={handleOnBlur} onChange={handleChange} onKeyDown={handleKeyDown} />
            <InputGroup.Append>
                <InputGroup.Text style={{ padding: 0, border: 'none', margin: 0 }} id="inputGroupAppend">
                    <span className="search-btn btn-primary" onClick={() => { onEnter(v)}}><i className="feather icon-search" /></span>
                </InputGroup.Text>
            </InputGroup.Append>
        </InputGroup>
    </div>

    // return (
    //     <Form.Group>
    //         <InputGroup style={{ borderColor: isFocused ? '#007bff' : '#ddd', backgroundColor: 'white', borderRadius: '50px', border: '1px solid #ddd' }} className="">
    //             <InputGroup.Prepend>
    //                 <InputGroup.Text style={{ paddingRight: '5px', border: 'none'}} id="inputGroupPrepend"><i className="feather icon-search"></i></InputGroup.Text>
    //             </InputGroup.Prepend>
    //             <input style={{ border: 'none', padding: '5px 5px', borderRadius: '50px', fontSize: '14px'}} className="form-control" placeholder={placeholder} value={onEnter ? v : value} onFocus={handleOnFocus} onBlur={handleOnBlur} onChange={handleChange} onKeyDown={handleKeyDown} />
    //         </InputGroup>
    //     </Form.Group>
    // );
}