import React, { useContext, createContext, useState, useEffect } from "react";
import moment from "moment";

const navContext = createContext();

function useNavContext() {
    return useContext(navContext);
}

function ProvideNavContext({ children }) {
    const useNavContext = useProvideNavContext();
    return (
        <navContext.Provider value={useNavContext}>
            {children}
        </navContext.Provider>
    )
}

function useProvideNavContext() {
    const [helpToolTipRef, setHelpToolTipRef] = useState()
    const [isNavLocked, setIsNavLocked] = useState(() => {
        const storedValue = localStorage.getItem("navigation-locked");
        return storedValue === "true";
    });

    return {
        helpToolTipRef,
        setHelpToolTipRef,
        isNavLocked,
        setIsNavLocked
    }
}

export {
    useNavContext,
    ProvideNavContext,
}