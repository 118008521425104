import ReactTooltip from "react-tooltip";
import { HELP_TEXT } from "../../store/constant";

export default function HelpTip({children, text, textId, id, inPlace=true, tooltipOnly=false, effect="solid", place="right"}) {

    if (tooltipOnly) {
        return <ReactTooltip id={id}
            getContent={(dataTip) => dataTip}
            effect={effect}
            place={place}
        />
    }

    text = text || HELP_TEXT[textId || id];
    
    return (
        children ?
            (<>
                {inPlace && <ReactTooltip id={id} effect={effect} place={place}>
                    {text}
                </ReactTooltip>}
                <span data-tip={text} data-for={id}>
                    {children}
                </span>
            </>) :
            (<>
                {inPlace && <ReactTooltip id={id} effect={effect} place={place}>
                    {text}
                </ReactTooltip>}
                <i className="fa fa-info-circle icon-help" data-tip={text} data-for={id} />
            </>)
    )
}