import API from "../services/api";
import { DEFAULT_ORG_DOMAIN_CONFIG } from "../store/constant";
import { ENVIRONMENTS } from "./envUtils";

export const getAccessTokenFromQuery = () => {
    const search = window.location.search || "?"
    let params = new URLSearchParams(search.substring(1));
    return params.get("accessToken")
}

export const getEncodedRedirectParam = () => {
    const fullPath = `${window.location.pathname}${window.location.search}`
    return `redirect=${btoa(fullPath)}`
}

/**
 * Get full base URL string
 * @param {String} forRoute - Split by this string
 * @returns {String}
 */
export const getFullBasePath = (forRoute) => {
    const paths = window.location.pathname.split(forRoute)
    if (paths[0])
        // Removes '/' from the end
        return paths[0].substring(0, paths[0].length - 1)
    return ''
}

export const getBaseAppUrlForShared = (org = null) => {
    const config = (org.domain_config || DEFAULT_ORG_DOMAIN_CONFIG).guest
    return `${config.protocol}${config.subdomain ? config.subdomain + "." : ""}${config.domain}/s`
}