import React from 'react';
import Aux from "../../../../hoc/_Aux";
import { useAuth } from '../../../../context/AuthContext';
import { isSharedEnv } from '../../../../service';

const DEFAULT_LOGO = "https://adbrew-site.s3.eu-west-1.amazonaws.com/assets/images/adbrew_logo_1.png"

const navLogo = (props) => {

    const { organization } = useAuth()

    let toggleClass = ['mobile-menu'];
    if (props.collapseMenu) {
        toggleClass = [...toggleClass, 'on'];
    }

    let imageSrc = DEFAULT_LOGO
    // if(isSharedEnv && organization.show_guest_logo && organization.logo?.large)
    if (isSharedEnv && organization.logo?.large)
        imageSrc = `${organization.logo_prefix}${organization.logo.large}`

    return (
        <Aux>
            <div className="navbar-brand header-logo">
                <a href="/dashboard" className="b-brand">
                    <img src={imageSrc} width="125px" height="26px" />
                </a>
            </div>
        </Aux>
    );
};

export default navLogo;
