import { useEffect } from "react";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

import DropdownMenuItem from "./DropdownMenuItem";
import OpenCloseTransition from "./OpenCloseTransition";
import PropTypes from "prop-types";

/**
 * Main component for the Adbrew dropdown which wraps Headless UI's <Menu/>.
 */
function AdbDropdownMenu({ children }) {
  useEffect(() => {
    // Define a function to remove the styles on html added by headlessui
    const removeHeadlessUiStyles = () => {
      document.documentElement.style.overflow = ""; // Remove overflow hidden
      document.documentElement.style.paddingRight = ""; // Remove padding-right
    };

    // Set up a mutation observer to watch for style changes on the html element
    const observer = new MutationObserver(() => {
      // Some styles are added by headless ui when dropdown is opened, which we
      // do not want, so remove them.
      removeHeadlessUiStyles();
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["style"], // Only observe style changes
    });

    // Clean up the observer when the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Menu as="div" className="text-left tw-relative tw-inline-block">
      {children}
    </Menu>
  );
}

/**
 * Button component to open Adbrew dropdown which wraps Headless UI's <MenuButton/>.
 */
function AdbDropdownMenuButton({ children }) {
  return (
    <MenuButton className="hover:tw-cursor-pointer focus:!tw-outline-0">
      {children}
    </MenuButton>
  );
}

/**
 * Component to display Adbrew dropdown options which wraps Headless UI's <MenuItems/>.
 */
function AdbDropdownMenuItems({
  twWidth = "tw-w-60 !tw-max-w-60",
  position = "bottom end",
  transitionOrigin,
  extraStyles,
  customStyles,
  menuItems,
  menuItemHeader,
  hideBorder,
  children,
}) {
  const menuItemsDefaultStyles = `${twWidth} tw-absolute tw-z-[1030] tw-mt-2 tw-p-1 tw-origin-top-right tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none ${extraStyles}`;

  return (
    <OpenCloseTransition origin={transitionOrigin}>
      <MenuItems
        as="div"
        anchor={position}
        className={customStyles ? menuItemsDefaultStyles + customStyles : menuItemsDefaultStyles}
      >
        {menuItemHeader && (
          <>
            <div className={`${!hideBorder ? "tw-mb-3 tw-mt-2" : ""} tw-flex tw-items-center tw-justify-start tw-gap-2.5 tw-px-2`}>
              {menuItemHeader.image && menuItemHeader.image}
              {menuItemHeader.heading && (
                <div className="tw-flex tw-flex-col">
                  <span className="tw-text-xs tw-font-semibold">
                    {menuItemHeader.heading}
                  </span>
                  {menuItemHeader.subHeading && (
                    <span className="tw-text-small-tags">
                      {menuItemHeader.subHeading.length > 25
                        ? `${menuItemHeader.subHeading.slice(0, 25)}...`
                        : menuItemHeader.subHeading}
                    </span>
                  )}
                </div>
              )}
            </div>
            { !hideBorder && <div className="tw-border-b tw-border-brand-very-light-gray tw-mb-1"></div> }
          </>
        )}
        {!children &&
          menuItems &&
          menuItems.map((menuItem) => {
            return (
              <AdbDropdownMenuItem>
                <DropdownMenuItem menuItem={menuItem} />
              </AdbDropdownMenuItem>
            );
          })}
        {children}
      </MenuItems>
    </OpenCloseTransition>
  );
}

function AdbDropdownMenuItem({ children }) {
  return <MenuItem as="div">{children}</MenuItem>;
}

export default function AdbDropdown({dropdownButton, dropdownContent, dropdownItemsProps, children}) {
  return (
    <AdbDropdownMenu>
      <AdbDropdownMenuButton>
        {dropdownButton}
      </AdbDropdownMenuButton>
      <AdbDropdownMenuItems {...dropdownItemsProps}>
        {dropdownContent || children}
      </AdbDropdownMenuItems>
    </AdbDropdownMenu>
  );
}

AdbDropdownMenuItems.propTypes = {
  twWidth: PropTypes.string,
  position: PropTypes.string,
  transitionOrigin: PropTypes.string,
  extraStyles: PropTypes.string,
  customStyles: PropTypes.string,
  menuItems: PropTypes.array,
  menuItemHeader: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export {
  AdbDropdownMenu,
  AdbDropdownMenuButton,
  AdbDropdownMenuItems,
};
