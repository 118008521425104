
import API from './api';

export class AccountSetupService extends API {
    constructor() {
        super('account_setup');
    }

    async getSetupState() {
        return await this._get()
    }

    async updateSetupState({ body }) {
        return await this._post({ body })
    }

}

export default AccountSetupService;