import {Alert as BootstrapAlert, Button} from 'react-bootstrap';
import { EVENT_ACTIONS, useUsageTracking } from '../../context/UsageTrackingContext';

function TrackedButton({children, eventLabel, ...props}) {
    const { sendEvent } = useUsageTracking()

    const handleClick = (arg) => {
        sendEvent(EVENT_ACTIONS.BUTTON_CLICK, eventLabel)
        if (props.onClick) {
            props.onClick(arg)
        }
    }

    return (
        <Button {...props} onClick={handleClick}>
            {children}
        </Button>
    );
}

export {
    TrackedButton
}