import Aux from '../../hoc/_Aux';
export default function TypeLabel({t}) {
    const m = {
        "vendor": {label: "Vendor", display: "V"},
        "seller": {label: "Seller", display: "S"},
        "brand": {label: "Brand", display: "B"}
    }
    return (
        <Aux>
            <span title={m[t]?.label} style={{ "bottom": "1px", "padding": "2px 5px", "fontSize": "10px", "borderRadius": "15px" }} className={`label theme-bg2 text-white f-12`}>{m[t]?.display}</span>
        </Aux>
    );
}