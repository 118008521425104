import React, { useContext, createContext, useState, useEffect } from "react";
import moment from "moment";
import { defaultStaticRanges, createStaticRanges } from "react-date-range";

import {subDays, differenceInCalendarDays, startOfMonth, endOfDay, startOfWeek} from 'date-fns';


const dateRangeContext = createContext();

function useDateRange() {
    return useContext(dateRangeContext);
}

function ProvideDateRange({ children }) {
    const dateRange = useProvideDateRange();
    return (
        <dateRangeContext.Provider value={dateRange}>
            {children}
        </dateRangeContext.Provider>
    )
}

function useProvideDateRange() {
    const { savedFromDate, savedToDate, savedDateRangeLabel, savedPrevFromDate, savedPrevToDate, savedPrevDateRangeLabel } = JSON.parse(localStorage.getItem("dateRange") || "{}");

    const getDefaultDateRange = (savedFromDate, savedToDate, savedDateRangeLabel) => {
        let defaultDateRange;
        if (savedDateRangeLabel) {
            const range = defaultStaticRanges.find(({label}) => label == savedDateRangeLabel)
            if (range) {
                defaultDateRange = [range.range().startDate, range.range().endDate]
            }
        }
        if (savedFromDate && savedToDate && !defaultDateRange) {
            defaultDateRange = [savedFromDate, savedToDate]
        }
        return defaultDateRange;
    }

    const defaultCurrentDateRange = getDefaultDateRange(savedFromDate, savedToDate, savedDateRangeLabel) || [moment().subtract(13, 'days').toDate(), moment().toDate()]
    const [rawFromDate, setRawFromDate] = useState(defaultCurrentDateRange[0]);
    const [rawToDate, setRawToDate] = useState(defaultCurrentDateRange[1]);
    const [dateRange, setDateRange] = useState({})
    const [prevDateRange, setPrevDateRange] = useState({})
    const [dateRangeLabel, setDateRangeLabel] = useState(savedDateRangeLabel)

    const defaultPrevDateRange = getDefaultDateRange(savedPrevFromDate, savedPrevToDate, savedPrevDateRangeLabel) || [moment().subtract(28, 'days').toDate(), moment().subtract(15, 'days').toDate()]
    const [prevRawFromDate, setPrevRawFromDate] = useState(defaultPrevDateRange[0]);
    const [prevRawToDate, setPrevRawToDate] = useState(defaultPrevDateRange[1]);
    const [prevDateRangeLabel, setPrevDateRangeLabel] = useState(savedPrevDateRangeLabel || "Previous Period")

    const getPrevDateRange = (f, t) => {
        return {
            startDate: subDays(f, 1 + differenceInCalendarDays(t, f)),
            endDate: subDays(f, 1)
        }
    }

    const prevPeriodStaticRange = {
        label: 'Previous Period',
        range: () => {
            return getPrevDateRange(rawFromDate, rawToDate)
        }
    }

    const getThisMonthRange = () => ({
        startDate: startOfMonth(new Date()),
        endDate: endOfDay(new Date()),
    })

    const getThisWeekRange = () => ({
        startDate: startOfWeek(new Date()),
        endDate: endOfDay(new Date()),
    })

    for (const range of defaultStaticRanges) {
        if (range.label == "This Month") {
            range.range = () => {
                return getThisMonthRange()
            }
        }
        if (range.label == "This Week") {
            range.range = () => {
                return getThisWeekRange()
            }
        
        }
    }

    const prevDateRangeDefaultStaticRanges = createStaticRanges([prevPeriodStaticRange, ...defaultStaticRanges])

    const currentDateRangeStaticRanges = createStaticRanges([...defaultStaticRanges])

    useEffect(() => {
        try {
            console.log("saving", savedFromDate, savedToDate, dateRangeLabel, savedPrevFromDate, savedPrevToDate, savedPrevDateRangeLabel)
            localStorage.setItem('dateRange', JSON.stringify(
                {
                    savedFromDate: rawFromDate, savedToDate: rawToDate, savedDateRangeLabel: dateRangeLabel,
                    savedPrevFromDate: prevRawFromDate, savedPrevToDate: prevRawToDate, savedPrevDateRangeLabel: prevDateRangeLabel
                }
            ))
        } catch (e) {
            console.log({ e });
        }  
    }, [rawFromDate, rawToDate, dateRangeLabel, prevRawFromDate, prevRawToDate, prevDateRangeLabel])

    useEffect(() => {
        setDateRange({ from: moment(rawFromDate).format("YYYYMMDD"), to: moment(rawToDate).format("YYYYMMDD")})
    }, [rawFromDate, rawToDate])

    useEffect(() => {
        setPrevDateRange({ from: moment(prevRawFromDate).format("YYYYMMDD"), to: moment(prevRawToDate).format("YYYYMMDD")})
    }, [prevRawFromDate, prevRawToDate])

    const getDateRangeLabel = (f, t, staticRanges) => {
        staticRanges = staticRanges || defaultStaticRanges
        let dateRangeLabel;
        for (let r of staticRanges) {
            if (r.isSelected({startDate: f, endDate: t})) {
                dateRangeLabel = r.label
                break;
            }
        }
        return dateRangeLabel;
    }

    const updateDateRange = (selectedFromDate, selectedToDate) => {
        setRawFromDate(selectedFromDate);
        setRawToDate(selectedToDate);
        setDateRangeLabel(getDateRangeLabel(selectedFromDate, selectedToDate));
        if (prevDateRangeLabel == prevPeriodStaticRange.label) {
            const pdr = getPrevDateRange(selectedFromDate, selectedToDate)
            updatePrevDateRange(pdr.startDate, pdr.endDate, prevPeriodStaticRange.label)
        }
    }

    const updatePrevDateRange = (selectedFromDate, selectedToDate, label) => {
        setPrevRawFromDate(selectedFromDate);
        setPrevRawToDate(selectedToDate);
        setPrevDateRangeLabel(label || getDateRangeLabel(selectedFromDate, selectedToDate, prevDateRangeDefaultStaticRanges));
    }

    const getDiffInDays = () => {
        return differenceInCalendarDays(rawToDate, rawFromDate) + 1
    }

    return {
        rawFromDate, rawToDate, updateDateRange, getDiffInDays, dateRange, dateRangeLabel, currentDateRangeStaticRanges,
        prevRawFromDate, prevRawToDate, updatePrevDateRange, prevDateRange, prevDateRangeLabel, prevDateRangeDefaultStaticRanges
    }
}

export {
    useDateRange,
    ProvideDateRange
}