import React, { useContext, createContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PaginatedGetter } from "../services/paginated_getter";
import { encodedAndCompressString } from "../Util";

const DCMSProviderContext = createContext();

function useDCMSProvider() {
    return useContext(DCMSProviderContext);
}

function ProvideDCMSProvider({ children }) {
    const dataProvider = useProvideDCMSProvider();
    return (
        <DCMSProviderContext.Provider value={dataProvider}>
            {children}
        </DCMSProviderContext.Provider>
    )
}

function useProvideDCMSProvider() {
    const [campaigns, setCampaigns] = useState([])
    const [sovKeywords, setSovKeywords] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [currentKey, setCurrentKey] = useState()
    const [cached, setCached] = useState([])
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [isCleanFetch, setIsCleanFetch] = useState(true)

    const history = useHistory()

    // Upon removing filters, the page is set to 1. Hence, we need fresh data without filters.
    useEffect(() => {
        if (page < 2 && isCleanFetch) {
            fetchData(currentKey)
            setIsCleanFetch(false)
        }
    }, [page])

    // Keys where IDs are stored for each sections. These IDs are used to compare objects.
    const idKeys = {
        'campaigns': 'campaignId',
        'products': '',
        'sov_keywords': '_id',
    }

    /**
     * Updates the current section key
     * @param {String} key - Section key
     */
    const updateKey = (key) => {
        if (currentKey && currentKey == key)
            return
        setCurrentKey(key)
    }

    /**
     * Removes duplicate value from the list. Set of `prev` and `newList` combined.
     * @param {Array} prev - List of values already present in the state
     * @param {Array} newList - List of new values fetched
     * @param {String} key - Section key
     * @returns Array of unique objects
     */
    const getUnique = (prev = [], newList = [], key) => {
        let prevEntries = prev.map(d => d[idKeys[key]])
        let newEntries = newList.filter(c => prevEntries.indexOf(c[idKeys[key]]) == -1)
        return [...prev, ...newEntries]
    }

    // ----------------- Data Fetcher Functions -----------------
    /**
     * Generic function to fetch data from the server.
     * @param {String} dataKey - Key in the response object where the data is stored
     * @param {String} path - Path to the API
     * @param {Object} filters - Filters to be applied
     * @param {Boolean} forceFirstPage - If true, fetches data from the first page
     * @param {Number} limit - Number of records to be fetched per page
     * @param {Boolean} refresh - If true, refreshes the data
     * @param {Array} include - List of IDs to be included
     * @param {Array} exclude - List of IDs to be excluded
     * @param {Function} callback - Callback function to be called after fetching data
     * @returns
     * @example
     * genericFetchData({dataKey: 'sov_keywords', path: 'sov_keywords_view', filters: [], forceFirstPage: true, limit: 500, refresh: false, include: [], exclude: [], callback: (data) => {}})
    */
    const genericFetchData = async ({ dataKey, path, filters, forceFirstPage = false, limit = 500, refresh = false, include, exclude, callback, ...extraParams }) => {
        if (isCleanFetch || forceFirstPage)
            setIsLoading(true)
        updateKey(dataKey)
        if (!hasMore && !forceFirstPage) {
            setIsLoading(false)
            return
        }
        try {
            const data = await new PaginatedGetter(path).get({page: forceFirstPage?1: page, perPage: limit, filterSpec: filters, include, ...extraParams })
            if (data[dataKey].length == 0)
                setHasMore(false)
            if (include) {
                setCached(getUnique(cached, data[dataKey], dataKey))
                setIsLoading(false)
                return
            }
            if (!refresh)
                resultSetterMap[dataKey](t => getUnique(t, data[dataKey], dataKey))
            else {
                resultSetterMap[dataKey](data[dataKey])
                setHasMore(true)
            }
            !forceFirstPage && setPage(page + 1)
            setIsLoading(false)
            callback && callback(data[dataKey])
        }
        catch (e) {
            console.log({ e });
        }
        setIsLoading(false)
    }

    // Specific for only campaign/adgroup selector. Fetches campaigns with adgroups data.
    const fetchPaginatedCampaigns = async (forceFirstPage = false, limit = 500, include, exclude, refresh = false, filters, excludeAdgs, callback, mode) => {
        genericFetchData({
            filters, forceFirstPage, limit, refresh, include, exclude, callback, 
            dataKey: 'campaigns', 
            path: 'campaigns',
            get_adg: mode && mode === 'campaigns_only' ? false : true, 
            exclude_adgs: await encodedAndCompressString(excludeAdgs?.join(",")), 
            filter_serving_status: ['rejected', 'REJECTED', 'CAMPAIGN_REJECTED'],
            from_selector: true
        })
    }

    // ----------------- Data Fetcher Functions - END -----------------

    /**
     * Save the selected objects to a state so its not lost when some filters are applied. 
     * Also, prevents multiple calls
     * @param {Object} data - object to save
     * @param {String} key - section key
     */
    const saveSelected = (data, key) => {
        if (data.length == 0)
            return
        updateKey(key)
        setCached(t => getUnique(t, data, key))
    }

    // Data fetchers for each section.
    const fetcherMap = {
        'campaigns': fetchPaginatedCampaigns,
        'product': () => { },
        'sov_keywords': () => genericFetchData({ dataKey: "sov_keywords", path: "sov_keywords_view" }),
    }

    const resultSetterMap = {
        'sov_keywords': setSovKeywords,
        'campaigns': setCampaigns
    }

    // Mapping of URLs to sections
    const sectionMap = {
        'target_management': 'campaigns',
        'rulesets': 'campaigns',
        'dayparting': 'campaigns'
    }

    /**
     * Detects the section using URL string.
     * @returns String
     */
    const getKeyByURL = () => {
        const section = history.location.pathname.split('/')[1]
        return sectionMap[section]
    }

    /**
     * Fetches data for a specific section. 
     * If section key not provided, gets it from URL string
     * @param {*} k - Section key
     */
    const fetchData = (k) => {
        if (!k) {
            const fetcherFunc = fetcherMap[getKeyByURL()]
            fetcherFunc && fetcherFunc()
        }
        else
            fetcherMap[k]()
    }

    return {
        isLoading,
        campaigns,
        sovKeywords,
        genericFetchData,
        fetchPaginatedCampaigns,
        hasMore,
        setHasMore,
        page,
        cached,
        setPage,
        saveSelected,
        fetchData,
        setIsCleanFetch,
    }
}

export {
    useDCMSProvider,
    ProvideDCMSProvider
}