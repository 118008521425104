export const INVENTORY_FIELDS = {
    TITLE: 'title',
    ASIN: 'asin',
    QUANTITY: 'quantity',
    PRICE: 'price',
    DAYS_OF_SUPPLY: 'daysOfSupply',
    AFN: 'afn',
    MFN: 'mfn'
}

class BaseInventoryData {
    constructor(data) {
        this.title = data[INVENTORY_FIELDS.TITLE]
        this.asin = data[INVENTORY_FIELDS.ASIN]
        this.quantity = data[INVENTORY_FIELDS.QUANTITY]
        this.price = data[INVENTORY_FIELDS.PRICE]
    }

    getProperties() {
        return Object.getOwnPropertyNames(this)
    }

    getDefinedProperties() {
        return this.getProperties().filter(p => this[p])
    }
}

class MFNData extends BaseInventoryData {
    constructor(data) {
        super(data)
    }
}

class AFNData extends BaseInventoryData {
    constructor(data) {
        super(data)
        this.daysOfSupply = data[INVENTORY_FIELDS.DAYS_OF_SUPPLY]
    }
}

export class InventoryData {
    constructor(data) {
        this.afn = new AFNData(data[INVENTORY_FIELDS.AFN] || {})
        this.mfn = new MFNData(data[INVENTORY_FIELDS.MFN] || {})
    }
}
