import React, { useEffect, useState } from 'react'

const DEFAULT_LENGTH = 64

const TableCellText = ({ children, style, maxLength, bold = false, text='' }) => {

    let textStyles = {}

    if (bold) {
        textStyles = {...textStyles, fontWeight: "bold"}
    }

    if (style)
        textStyles = { ...textStyles, ...style }


    return (
        children ?
            <>
                <span style={textStyles} className='wrapped-cell-text' data-tip={typeof children  === 'string' ? children : text}>
                    {children}
                </span>
            </> :
            <>
                
            </>
    )
}

export default TableCellText