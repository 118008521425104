import { useContext } from "react";
import { getDeepValueFromObject, isNumeric } from "../Util";
import { accountContext } from "../context/AccountContext";
import { Num } from "../layout/Components/table_cells";
import RedGreenText from "../layout/Components/redGreenText";
import { id } from "date-fns/locale";


export const CreateMetricCellForExpanded = ({row, metricKey, prefixCurr = false, suffix = '', multiplier, missing, horizontal = false}) => {
    const { selectedAccountDetails = {} } = useContext(accountContext);
    const currentValue = getDeepValueFromObject(row, metricKey + '.current');
    const changePrecValue = getDeepValueFromObject(row, metricKey + '.change_perc');

    const formattedCurrentValue = Num({
        val: currentValue,
        prefixCurr: prefixCurr,
        suffix: suffix,
        multiplier,
        missing,
        countryCode: selectedAccountDetails.country_code,
        currencyCode: selectedAccountDetails.currency_code,
    });

    const formattedChangePrecValue = Num({
        val: changePrecValue,
        suffix: '%',
    });
    const redGreenTextComponent = row.attribute === 'Overall ACOS' ? (
        <RedGreenText diff={-changePrecValue} displayVal={`${formattedChangePrecValue}`} />
    ) : (
        <RedGreenText diff={changePrecValue} displayVal={`${formattedChangePrecValue}`} />
    );    

    const backgroundColor = changePrecValue >= 0 ? '#e6f4ea' : '#f4e6ea';

    if (horizontal) {
        return (
            <div style={{ padding: "7px 0px", width: "100%", textAlign: 'right' }}>
                <div style={{ marginTop: "15px", marginBottom: "20px", textAlign: 'right' }}>
                    {formattedCurrentValue} ({redGreenTextComponent})
                </div>
            </div>
        );
    } else {
        return (
            <div style={{ padding: "7px 0px", width: "100%", textAlign: 'right' }}>
                <div style={{ marginTop: "15px", textAlign: 'right' }}>
                    {formattedCurrentValue}
                </div>
                <div className="mt-2 text-muted" style={{ marginBottom: "15px", textAlign: 'right'}}>
                    ({redGreenTextComponent})
                </div>
            </div>
        );
    }
};

/**
 * Calculates the percentage change between current and previous metric values.
 * @param {number|any} currentData - The current metric value (numeric) or any other data type.
 * @param {number|any} previousData - The previous metric value (numeric) or any other data type.
 * @returns {Object} - An object containing the current metric value, previous metric value, and percentage change.
 * @property {number|any} current - The current metric value.
 * @property {number|any} previous - The previous metric value.
 * @property {number} change_perc - The percentage change between current and previous metric values.
 */
const calculatePrevChange = (currentData, previousData) => {
    if (typeof currentData === 'number') {
        const currentMetricValue = currentData;
        const previousMetricValue = previousData;
        let changePerc = 0;
        if (typeof previousMetricValue === 'number' && previousMetricValue !== 0) {
            changePerc = ((currentMetricValue - previousMetricValue) / previousMetricValue) * 100;
        } else {
            changePerc = typeof currentMetricValue === 'number' && currentMetricValue > 0 ? 100 : 0;
        }
        return {
            current: currentMetricValue,
            previous: previousMetricValue,
            change_perc: changePerc,
        };
    } else {
        return {
            current: currentData,
            previous: previousData,
            change_perc: 0,
        };
    }
};

/**
 * Updates the data array by adding percentage change for each metric based on the previous data.
 * @param {Array} data - An array of objects representing metric data.
 * @returns {Array} - A new array of objects with added percentage change information for each metric.
 */
export const updateDataWithPrevChange = (data) => {
    const newData = [];
    for (let i = 0; i < data.length; i++) {
        const currentData = data[i];
        const previousData = i === 0 ? data[i] : data[i - 1];
        const metricsChanges = {};
        for (const metricKey in currentData) {
            if (metricKey === '_id') {
                metricsChanges[metricKey] = currentData[metricKey];
                continue;
            }
            metricsChanges[metricKey] = calculatePrevChange(currentData[metricKey], previousData[metricKey]);
        }
        const newDataItem = {
            dt: currentData.dt,
            metrics: metricsChanges,
        };
        newData.push(newDataItem);
    }
    return newData;
};

/**
 * Updates the result object by adding percentage change for each metric based on the previous values.
 * @param {Object} result - An object containing metrics data, with keys representing metric names.
 * @returns {Object} - A new object with added percentage change information for each metric (excluding "date").
 */
export const updateDataWithPrevChangeForBreakAttr = (result) => {
    const toReturn = []
    for (const row of result) {
        const resultWithPrevChange = {};
        let prevValue = 0
        Object.keys(row).forEach((col, idx) => {
            if (col !== "attribute") {
                const currentVal = row[col];
                resultWithPrevChange[col] = calculatePrevChange(currentVal, idx === 1 ? currentVal : prevValue);
                prevValue = currentVal;
            } else {
                resultWithPrevChange[col] = row[col];
            }
        });
        toReturn.push(resultWithPrevChange)
    }
    return toReturn;
};