import Skeleton from 'react-loading-skeleton'

import FailureReason from "../FailureReason"

// -------------------- Config/Constants --------------------

export const customTableStyles = {
    headCells: {
        style: {
            paddingLeft: '12px',
            paddingRight: '0',
            fontSize: '13px'
        }
    },
    headRow: { style: { minHeight: "42px" } },
    rows: {
        style: {
            border: 'none !important',
            fontSize: '13px',
            maxHeight: '58.8px'
        }
    },
    cells: {
        style: {
            paddingLeft: '12px',
            paddingRight: '0',
        },
    },
    pagination: {
        style: {
            minHeight: "42px"
        }
    }
}

// -------------------- Utilities --------------------

export const spliceAndSet = (idx, setState) => {
    setState(current => current.filter(d => d.idx !== idx))
}

export const appendFailureReasonColumn = (columns, loading, flag) => {
    if (flag)
        columns.push({ name: 'Reason', cell: skeletonFallbackCell(400, loading, getFailureReasonColumn), width: '400px' })
    else {
        const _i = columns.findIndex(c => c['name'] == 'Reason')
        if (_i !== -1)
            columns.splice(_i, 1)
    }
    return columns
}

export const parseFailureResponses = (responses, data, key) => {
    const idMappedData = {}
    data.forEach(d => { idMappedData[d[key]] = d })
    for (let i = 0; i < responses.length; i++)
        responses[i] = { ...idMappedData[responses[i][key]], ...responses[i] }
    return responses
}

// -------------------- Event Handlers --------------------

export const submitToAPI = async ({ service, payload, setLoader, onServiceSuccess, onServiceFailure }) => {
    const _setLoader = setLoader ? setLoader : () => { }
    let _failures = []
    let _success = []

    try {
        _setLoader(true)
        const response = await service(payload)
        _success = response.success || []
        _failures = response.failed || []

        if (_failures && _failures.length > 0)
            onServiceFailure(_failures, _success)
        else
            onServiceSuccess(_success)
    } catch (error) {
        onServiceFailure(_failures, _success, error)
    }
    _setLoader(false)
}

// -------------------- JSX handlers --------------------

export const heavyFont = (children) => <span style={{ fontWeight: "500" }}>{children}</span>

export const skeletonFallbackCell = (width, loading, callback) => {
    return loading ? () => <Skeleton height="33.2px" width={`${width - 10}px`} /> : callback
}

export const getActionsCell = ({ idx }, deleteTerm) => {
    return <div style={{ paddingBottom: "10px" }}>
        <a className="inline-anchor" style={{ float: "right", marginTop: "5px" }} onClick={() => deleteTerm(idx)}><i className="feather text-danger icon-trash" /></a>
    </div>
}

export const createFailureReasonSummary = (reasons = [], failureForKey = null) => {
    return reasons.map(reason => {
        try {
            const _errorType = reason.errorType
            const message = reason.errorValue[_errorType].message
            // Returns- "{Who the failure is meant for?}: {Failure Message}"
            return `${failureForKey ? failureForKey + ": " : ""}${message} \n`
        }
        catch {
            return "Unknown Reason"
        }
    })
}
/**
 * reasons sample:
 * "reasons": [
 *                {
 *                  "errorType": "adEligibilityError",
 *                  "errorValue": {
 *                      "adEligibilityError": {
 *                          "message": "Product is ineligible for advertising"
 *                          ...<extraInfo>
 *                      }
 *                  }
 *              }
 *          ]
 * 
 * Note: 'reasons' can either directly be in the parent object or inside array of adgroups
 */
export const getFailureReasonColumn = ({ idx, ad_groups = [], reasons = null }) => {
    let textReasons = []

    // Extract failure reasons from adgroups array
    for (const adgroup of ad_groups)
        textReasons = [...textReasons, ...createFailureReasonSummary(adgroup['reasons'], adgroup.adGroupName)]

    // Extract reasons from parent object
    if (reasons !== null)
        textReasons = createFailureReasonSummary(reasons)

    // If no failure message, show default
    if (textReasons.length === 0)
        textReasons = ['Unknown Reason']
    else if (textReasons.length > 1)
        textReasons = [`${textReasons.length} Failures:`, ...textReasons]

    return <FailureReason reasonList={textReasons} idx={idx} />
}