import React, { useState, createContext, useEffect, useContext } from 'react';
import { getLinkedAccounts } from "../service";
import { get_env, getSelectedAccountFromStorage } from "../Util";
import { useAuth } from '../context/AuthContext';
import { notificationContext } from "./NotificationContext";
import { useDataProvider } from './DataProvider';
import { associateUserWithGroup } from './UsageTrackingContext';

const accountContext = createContext();

const useAccount = () => useContext(accountContext);

function ProvideAccount({ children }) {
    const accountContextVal = useProvideAccountContext();
    return (
      <accountContext.Provider value={accountContextVal}>
        {children}
      </accountContext.Provider>
    );
}

function useProvideAccountContext() {
    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState();
    const {authenticated, user} = useAuth();
    const [error, setError] = useState(false);
    const { setNotification }  = useContext(notificationContext);
    const { refreshAllData } = useDataProvider()
    const [marketplaces, setMarketPlaces] = useState([])
    
    useEffect(() => {
        setMarketPlaces([...new Set(accounts.map(acc => acc.country_code))])
    }, [accounts])

    useEffect(() => {
        setError(false)
        if (authenticated) {
            const fetchData = async () => {
                try {
                    const {accounts} = await getLinkedAccounts();
                    setAccounts(accounts);
                    if(accounts.length > 0) {
                        const selAccount = getSelectedAccountFromStorage();
                        const {account_name, id} = selAccount;
                        if (account_name) {
                            setSelectedAccount(account_name)
                        } else {
                            switchAccount(accounts[0].id, accounts[0].account_name);
                        }
                        try {
                            // get account info by id
                            const selAccountInfo = accounts.filter(acc => acc.id === id)
                            if (selAccountInfo.length > 0) {
                                associateUserWithGroup(user?._id, selAccountInfo[0].id, {
                                    name: selAccountInfo[0].account_name + " (Account)",
                                    timezone: selAccountInfo[0].timezone,
                                    currency_code: selAccountInfo[0].currency_code,
                                    country_code: selAccountInfo[0].country_code,
                                    marketplace_id: selAccountInfo[0].profile_marketplace_string_id,
                                    account_type: selAccountInfo[0].profile_account_type,
                                    createdAt: selAccountInfo[0].created,
                                    type: "account",
                                    env: get_env()
                                })
                            }
                        } catch (e) {
                            console.log("Error associating user with group", {e});
                        }
                    }
                    refreshAllData()
                } catch (e) {
                    console.log({e});
                    setError(true);
                    setNotification("Error", "Something went wrong, please refresh and try again.", "error");
                }
                setLoading(false);
            };
            fetchData();
            
            setLoading(true);
        }
    }, [authenticated]);

    const switchAccount = (id, account_name) => {
        localStorage.setItem('account', JSON.stringify({id: id, account_name: account_name}));
        setSelectedAccount(account_name);
    }

    const getSelectedAccountDetails = () => {
        const { id } = JSON.parse(localStorage.getItem("account") || "{}");
        const arr = accounts.filter(acc => acc.id === id)
        if (arr.length > 0) {
            arr[0].timezone = (arr[0].timezone || "Europe/London").replace('IST', 'Asia/Kolkata')
            return arr[0]
        } else if (accounts.length > 0) {
            switchAccount(accounts[0].id, accounts[0].account_name)
            window.location.reload()
        } else {
            return {}
        }
    }

    const accountExists = (id) => {
        return accounts.filter(acc => acc.id === id).length > 0
    }

    return {
        accounts,
        selectedAccount,
        switchAccount,
        loading,
        error,
        marketplaces,
        selectedAccountDetails: getSelectedAccountDetails(),
        accountExists
    }
}

export {
    accountContext,
    ProvideAccount,
    useAccount
}