import React, { useState, useRef } from "react";

import { Modal, Button } from "react-bootstrap";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import styled from "styled-components";
import {
  subDays,
  differenceInCalendarDays,
  endOfDay,
  startOfDay,
  addDays,
} from "date-fns";

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 910px;
  }
`;

function CustomDateRangePicker({
  rawFromDate,
  rawToDate,
  onChange,
  hide,
  staticRanges,
}) {
  const [rangeStart, setRangeStart] = useState(rawFromDate);
  const [rangeEnd, setRangeEnd] = useState(rawToDate);
  const prevDateRange = useRef({ start: rawFromDate, end: rawToDate });

  function handleDateRangeModalHide() {
    onChange(rangeStart, rangeEnd);
    hide();
  };

  /**
   * Reverts newly made changes to the previous one.
   */
  function cancelDateRangeModalChanges() {
    hide();
    setRangeStart(prevDateRange.current.start);
    setRangeEnd(prevDateRange.current.end);
  };

  function handleDateRangeChange(data) {
    if (data?.startDate != rangeStart) setRangeStart(data?.startDate);
    if (data?.endDate != rangeEnd) setRangeEnd(data?.endDate);
  };

  const inputRanges = [
    {
      label: "days up to today",
      range(value, p) {
        const skipDaysCurrentValue = p.inputRanges[1].getCurrentValue(p.range);

        return {
          startDate: addDays(
            startOfDay(new Date()),
            (Math.max(Number(value), 1) - 1) * -1,
          ),
          endDate: subDays(
            endOfDay(new Date()),
            Math.max(Number(skipDaysCurrentValue), 0),
          ),
        };
      },
      getCurrentValue(range) {
        if (!range.startDate) return "∞";

        return (
          differenceInCalendarDays(endOfDay(new Date()), range.startDate) + 1
        );
      },
    },
    {
      label: "latest days excluded",
      range(value, x) {
        const r = {
          startDate: x.range.startDate,
          endDate: subDays(endOfDay(new Date()), Math.max(Number(value), 0)),
        };

        return r;
      },
      getCurrentValue(range) {
        const ret = differenceInCalendarDays(
          startOfDay(new Date()),
          range.endDate,
        );

        return ret;
      },
    },
  ];

  return (
    <StyledModal show={true} size="lg" onHide={cancelDateRangeModalChanges}>
      <div
        style={{ padding: "6px", display: "flex", justifyContent: "center" }}
      >
        <DateRangePicker
          onChange={(item) => handleDateRangeChange(item.dateRange)}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={[
            { startDate: rangeStart, endDate: rangeEnd, key: "dateRange" },
          ]}
          direction="horizontal"
          staticRanges={staticRanges || defaultStaticRanges}
          inputRanges={inputRanges}
        />
      </div>
      <Modal.Footer>
        <Button variant="light" size="sm" onClick={cancelDateRangeModalChanges}>
          Cancel
        </Button>
        <Button variant="primary" size="sm" onClick={handleDateRangeModalHide}>
          Save
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
}

export default CustomDateRangePicker;
