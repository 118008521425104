import React, { useContext, createContext, useState, useEffect } from "react";
import { AccountSetupService } from '../services/account_setup';

const accountSetupStateContext = createContext();

function useAccountSetupState() {
    return useContext(accountSetupStateContext);
}

function ProvideAccountSetupState({ children }) {
    const dateRange = useProvideAccountSetupState();
    return (
        <accountSetupStateContext.Provider value={dateRange}>
            {children}
        </accountSetupStateContext.Provider>
    )
}

function useProvideAccountSetupState() {
    const service = new AccountSetupService();
    const [totalSteps, setTotalSteps] = useState();
    const [loading, setLoading] = useState(false)
    const [progressPercent, setProgressPercent] = useState(0)
    const [accountBrandTerms, setAccountBrandTerms] = useState([])
    const [identifiedCompetitorTerms, setIdentifiedCompetitorTerms] = useState([])
    const [accountAsins, setAccountAsins] = useState([])

    const [reportsFetched, setReportsFetched] = useState(false)
    const [brandTermsConfigured, setBrandTermsConfigured] = useState(false)
    const [brandAsinsConfigured, setBrandAsinsConfigured] = useState(false)
    const [competitorTermsConfigured, setCompetitorTermsConfigured] = useState(false)
    const [brandAnalyticsSeen, setBrandAnalyticsSeen] = useState(false)
    const [sovKeywordsConfigured, setSovKeywordsConfigured] = useState(false)
    const [bidRecommendationsSeen, setBidRecommendationsSeen] = useState(false)
    const [negativeRecommendationsSeen, setNegativeRecommendationsSeen] = useState(false)
    const [recommendationsGenerated, setRecommendationsGenerated] = useState(false)
    const [competitorDataFetched, setCompetitorDataFetched] = useState(false)
    const [rulesetsCreated, setRulesetsCreated] = useState(false)
    const [automationsConfigured, setAutomationsConfigured] = useState(false)

    const [setupComplete, setSetupComplete] = useState(false)

    const fetchData = async () => {
        const { account_brand_terms, account_brand_asins,
            identified_competitor_brand_terms, brand_terms_configured, brand_asins_configured,
            competitor_terms_configured, reports_fetched,
            brand_analytics_seen, sov_keywords_configured, bid_recommendations_seen,
            negative_recommendations_seen, automations_configured, rulesets_created, 
            recommendations_generated, competitor_data_fetched, setup_complete } = await service.getSetupState()
        setAccountBrandTerms(account_brand_terms)
        setAccountAsins(account_brand_asins)
        setIdentifiedCompetitorTerms(identified_competitor_brand_terms)

        setReportsFetched(reports_fetched)
        setBrandTermsConfigured(brand_terms_configured)
        setBrandAsinsConfigured(brand_asins_configured)
        setCompetitorTermsConfigured(competitor_terms_configured)
        setBrandAnalyticsSeen(brand_analytics_seen)
        setSovKeywordsConfigured(sov_keywords_configured)
        setBidRecommendationsSeen(bid_recommendations_seen)
        setNegativeRecommendationsSeen(negative_recommendations_seen)
        setRecommendationsGenerated(recommendations_generated)
        setCompetitorDataFetched(competitor_data_fetched)
        setRulesetsCreated(rulesets_created)
        setAutomationsConfigured(automations_configured)

        setSetupComplete(setup_complete)
    }

    const forceFetchData = async () => {
        setLoading(true);
        await fetchData()
        setLoading(false);
    }


    const updateProgress = async ({ brandTermsConfigured, brandAsinsConfigured, competitorTermsConfigured,
        brandAnalyticsSeen, sovKeywordsConfigured, bidRecommendationsSeen, negativeRecommendationsSeen,
        rulesetsCreated, automationsConfigured }) => {
        await service.updateSetupState({
            body: {
                brand_terms_configured: brandTermsConfigured,
                brand_asins_configured: brandAsinsConfigured,
                competitor_terms_configured: competitorTermsConfigured,
                brand_analytics_seen: brandAnalyticsSeen,
                sov_keywords_configured: sovKeywordsConfigured,
                bid_recommendations_seen: bidRecommendationsSeen,
                negative_recommendations_seen: negativeRecommendationsSeen,
                rulesets_created: rulesetsCreated,
                automations_configured: automationsConfigured
            }
        });
        await fetchData()
    }

    useEffect(() => {
        let progress = 0
        let stepVal = 100 / totalSteps
        if (brandTermsConfigured) progress += stepVal;
        if (brandAsinsConfigured) progress += stepVal;
        if (competitorTermsConfigured) progress += stepVal;
        if (brandAnalyticsSeen) progress += stepVal;
        if (sovKeywordsConfigured) progress += stepVal;
        if (bidRecommendationsSeen) progress += stepVal;
        if (negativeRecommendationsSeen) progress += stepVal;
        if (rulesetsCreated) progress += stepVal;
        if (automationsConfigured) progress += stepVal;
        setProgressPercent(parseInt(progress))
    }, [totalSteps, brandTermsConfigured, brandAsinsConfigured, competitorTermsConfigured, brandAnalyticsSeen, sovKeywordsConfigured, bidRecommendationsSeen, negativeRecommendationsSeen, rulesetsCreated, automationsConfigured])

    return {
        loading, setLoading, setTotalSteps,
        progressPercent,
        updateProgress,
        accountBrandTerms,
        accountAsins,
        identifiedCompetitorTerms,

        reportsFetched,
        brandTermsConfigured,
        brandAsinsConfigured,
        competitorTermsConfigured,
        brandAnalyticsSeen,
        sovKeywordsConfigured,
        bidRecommendationsSeen,
        negativeRecommendationsSeen,
        rulesetsCreated,
        automationsConfigured,

        setBrandTermsConfigured,
        setBrandAsinsConfigured,
        setCompetitorTermsConfigured,
        setBrandAnalyticsSeen,
        setSovKeywordsConfigured,
        setBidRecommendationsSeen,
        setNegativeRecommendationsSeen,
        setRulesetsCreated,
        setAutomationsConfigured,

        recommendationsGenerated,
        competitorDataFetched,
        setupComplete,
        forceFetchData
    }
}

export {
    useAccountSetupState,
    ProvideAccountSetupState
}