export const INVENTORY_TYPE_VALUE_TO_LABEL =  {
    STANDARD_DISPLAY: "Standard Display",
    AMAZON_MOBILE_DISPLAY: "Amazon Mobile Display",
    AAP_MOBILE_APP: "AAP Mobile App",
    DISPLAY: "Display",
    VIDEO: "Video",
}

export const DELIVERY_PROFILE_VALUE_TO_LABEL = {
    EVEN: "Even",
    PACE_AHEAD: "Pace Ahead",
    ASAP: "ASAP"
}
