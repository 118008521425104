import {Form} from 'react-bootstrap';
import Select from 'react-select';

const groupStyles = {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    justifyContent: "space-between",
};

const groupBadgeStyles= {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
};
  
const formatGroupLabel = (data) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

export default function FormSelect({ placeholder, options, multi, val, onChange, isSearchable, filterOption, disabled = false, sm = false, isClearable = false, defaultDimensions=false, isGrouped = false, fixMulti = false}) {
    let opts = [];
    let value = null;
    if (isGrouped) {
        opts = options.map(group => ({
          label: group.label,
          options: group.options.map(({ value, ...ops }) => ({
            value: String(value),
            ...ops
          }))
        }));
        
        for (const group of opts) {
            value = group.options.find(o => String(o.value) === String(val));
            if (value) {
                break;
            }
        }
    } else {
        opts = options.map(({ value, ...ops }) => ({
            value: String(value),
            ...ops
        }));
        if (multi && fixMulti) {
            value = val && val.map(v => opts.find(o => String(o.value) === String(v.value)));
        } else {
            value = opts.find(o => String(o.value) === String(val));
        }
    }

    const customStyles = {
        control: provided => ({ ...provided, border: "1px solid #ced4da8a" }),
        // Fixes the overlapping problem of the component
        menu: provided => ({ ...provided, zIndex: 999999999 }),
        menuPortal: provided => ({ ...provided, zIndex: 999999999 })
    }
    if (sm) {
        customStyles.control = (provided, state) => ({
            ...provided,
            border: "1px solid #ced4da8a",
            minHeight: '30px'
        })
        customStyles.indicatorContainer = (provided, state) => ({
            ...provided,
            padding: '4px'
        })
    }
    else if (defaultDimensions){
        customStyles.control = provided => ({ ...provided, border: "1px solid #ced4da8a", minHeight: '30px' })
        customStyles.indicatorsContainer = provided => ({  ...provided, padding: '4px' })
        customStyles.dropdownIndicator = provided => ({  ...provided, padding: '2px' })
    }
    
    return (
        <Select
            styles={customStyles}
            isClearable={isClearable}
            className="basic-single"
            classNamePrefix="select"
            value={value}
            name="s"
            isMulti={multi ? true: false}
            isSearchable={isSearchable === undefined ? false : isSearchable}
            onChange={(e) => { onChange(multi ? e : e?.value)}}
            options={opts}
            placeholder={placeholder || "Select.."}
            filterOption={filterOption}
            isDisabled={disabled}
            closeMenuOnSelect={!multi}
            menuPortalTarget={document.body}
            isOptionDisabled={(option) => option.disabled}
            {...(isGrouped ? { formatGroupLabel } : {}) }
        />
    );
}
