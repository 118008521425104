export const ENTITY = {
    DASHBOARD: 'dashboard'
}

export const ALLOWED_ENTITIES = new Set([
    ENTITY.DASHBOARD
])

export const ALLOWED_LEFT_NAV_GROUPS = new Set([
    "sp_ads"
])

export const ALLOWED_LEFT_NAV_ITEMS = new Set([
    ENTITY.DASHBOARD
])