import React, {useState, useEffect} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Aux from "../../../../../hoc/_Aux";
import DEMO from "../../../../../store/constant";
import { useLocation } from 'react-router';
import { getSupportData } from '../../../../../service';
import Spinner from '../../../../Components/spinner';
import FormSelect from '../../../../Components/select';
import SearchBox from '../../../../Components/search_box';
import { useParams } from 'react-router-dom';
import { EVENT_ACTIONS, useUsageTracking } from '../../../../../context/UsageTrackingContext';


const SupportBar = ({listOpen, closed}) => {
    let listClass = ['header-user-list'];
    if (listOpen) {
        listClass = [...listClass, 'open'];
    }
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const [checkList, setCheckList] = useState()
    const [activeHelpItem, setActiveHelpItem] = useState()
    const [searchText, setsearchText] = useState('')
    const {pathname} = useLocation();
    const params = new URLSearchParams(window.location.search); 
    const showHelp = params.get('showHelp')

    useEffect(() => {
        console.log({showHelp})
        setCheckList(showHelp);
    }, [showHelp])

    const fetchData = async () => {
        setLoading(true)
        try {
            const {response} = await getSupportData({location: pathname, searchText: searchText})
            if (!checkList && (response.checklists || []).length > 0 && !showHelp) {
                setCheckList(response.checklists[0].id)
            }
            setData(response)
        } catch (e) {
            console.error(e)
        }
        setLoading(false)
    }

    useEffect(() => {
        const fetchDataAsync = async () => {
            if (listOpen) {
                await fetchData();
            }
        };
    
        fetchDataAsync();
    }, [listOpen, pathname, searchText]);

    const getChecklistOptions = () => {
        return (data.checklists || []).map(({name, id}) => ({label: name, value: id}))
    }

    const getChecklistItems = () => {
        const items = (data.checklists || []).find(({id}) => id === checkList)?.items || []
        return items.map((item, idx) => <HelpItem key={idx} item={{...item, index: idx}} type="checklist" onSelect={setActiveHelpItem} activeHelpItem={activeHelpItem} />)
    }

    const openChatWidget = () =>{
        if (window?.HubSpotConversations) {
            window.HubSpotConversations.widget.load();
            window.HubSpotConversations.widget.open();
            closed()
        }
    }

    return (
        <Aux>
            <div className={listClass.join(' ')}>
                <div className="h-list-header">
                    <h4>Support</h4> 
                        <SearchBox placeholder="Support Items..." value={searchText} onEnter={(val) => setsearchText(val)} />
                </div>
                {loading && <Spinner />}
                <div className="h-list-body">
                    <a href={DEMO.BLANK_LINK} className="h-close-text" onClick={closed}><i className="feather icon-chevrons-right" /></a>
                    
                    <div className="main-friend-cont scroll-div">
                        <div className="main-friend-list" style={{height: 'calc(100vh - 85px)'}}>
                            <PerfectScrollbar>
                                {((data.search_results || []).length > 0 && searchText.trim().length > 0) &&  (
                                    <div className="mb-5 mt-2">
                                        <div style={{width: "90%", paddingLeft: "15px", paddingBottom:"5px"}}>
                                            <h6 className="text-muted">Search Results</h6>
                                        </div>
                                        {data.search_results.map((item) => <HelpItem item={item} type="search" onSelect={setActiveHelpItem} activeHelpItem={activeHelpItem} />)}
                                    </div>

                                )}
                                <div style={{width: "90%", paddingLeft: "15px", paddingBottom:"10px"}}>
                                    <h6 className="mt-2 text-muted">What are you looking to do?</h6>
                                    <FormSelect options={getChecklistOptions()} multi={false} val={checkList} onChange={setCheckList} />
                                </div>
                                {getChecklistItems()}
                                <>
                                    <div className="mt-5" style={{width: "90%", paddingLeft: "15px", paddingBottom:"10px"}}>
                                        <h6 className="text-muted">Help Items On This Page</h6>
                                    </div>
                                    {((data.page_items || []).length == 0) && <div className="ml-3">No help items found for this page</div>}
                                    {(data.page_items || []).map((item) => <HelpItem key={item.id} item={item} type="related" onSelect={setActiveHelpItem} activeHelpItem={activeHelpItem} />)}
                                </>
                                <div style={{paddingBottom: "85px", paddingTop: "15px", paddingLeft: "15px"}}><a className="inline-anchor" target="_blank" href="https://adbrew.atlassian.net/servicedesk/customer/portals"><i className="feather icon-external-link" /> Support Portal</a></div>
                            </PerfectScrollbar>
                        </div>
                    </div>
                </div>
            </div>
        </Aux>
    );
};

const HelpItem = ({activeHelpItem, item={}, onSelect, type, index}) => {
    const idx = `${type}-${item.id}`
    const isActive = activeHelpItem === idx
    const emojis = [
        '1️⃣', '2️⃣', '3️⃣', '4️⃣', '5️⃣', '6️⃣', '7️⃣', '8️⃣', '9️⃣'
    ]
    const {sendEvent} = useUsageTracking()
    const onClick = (idx) => {
        onSelect(idx)
        sendEvent(EVENT_ACTIONS.CLICK, `HELP_${idx}`)
    }
    return (
        <Aux>
            <div className={isActive ? 'media userlist-box ripple active' : 'media userlist-box ripple'} onClick={() => onClick(idx)}>
                {/* <a className="media-left" href={DEMO.BLANK_LINK}> <img className="media-object img-radius" src={photo} alt={props.data.name}/>{newFriend}</a> */}
                <div className="media-body">
                    <h6 className="chat-header">{type === 'checklist' ? emojis[item.index] : ''}&nbsp;&nbsp;&nbsp;&nbsp;{item.name}</h6>
                    {isActive && (
                        <div className="mt-2 animated fadeIn">
                            <div className="mt-4 mb-4 support-description" dangerouslySetInnerHTML={{__html: item.description}}>
                            </div>
                            {item.video_url && <div style={{textAlign: "center"}}>
                                <iframe style={{width: "100%"}} height="200" src={item.video_url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>}
                            {type !== "related" && <div className="mt-2">
                                <a href={item.location}><i className="feather icon-link" /> Go to page</a>
                            </div>}
                        </div>
                    )}
                </div>
            </div>
        </Aux>
    );
}
export default SupportBar;