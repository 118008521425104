export default function Spinner({button, small, inline=false}) {
    const style = {}
    if (!small) {
        style['padding'] = '20px'
    }
    if (inline) {
        style['display'] = 'inline-block'
    }
    return (
        button ? 
            (
                <div className="spinner-border spinner-border-sm mr-1 ml-2" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            )
        :
        (<div style={style}>
            <div className={['spinner-border', 'mr-1', 'text-secondary', small ? 'spinner-border-sm' : ''].join(' ')} role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>)
    )
}