import React from "react";

import { NavLink } from "react-router-dom";

const menuItemStyles =
  "tw-group tw-flex tw-items-center tw-gap-3 tw-w-full tw-px-4 tw-py-2.5 tw-h-8 tw-text-xs tw-text-brand-text-gray tw-rounded-md tw-text-current hover:tw-text-current hover:tw-bg-brand-blue-light data-[focus]:tw-bg-gray-100 data-[focus]:tw-text-gray-900 focus:tw-outline-none";

function DropdownMenuItem({ menuItem }) {
  const menuItemContent = menuItem.icon ? (
    <>
      {menuItem.icon} {menuItem.name}
    </>
  ) : (
    menuItem.name
  );

  return (
    <>
      {menuItem.handleClick && (
        <button
          onClick={() => menuItem.handleClick()}
          className={`${menuItemStyles} ${menuItem.linkStyles}`}
        >
          {menuItemContent}
        </button>
      )}
      {menuItem.navLink && (
        <NavLink
          to={menuItem.navLink}
          className={`${menuItemStyles} ${menuItem.linkStyles}`}
        >
          {menuItemContent}
        </NavLink>
      )}
      {menuItem.link && (
        <a
          href={menuItem.link}
          className={`${menuItemStyles} ${menuItem.linkStyles}`}
        >
          {menuItemContent}
        </a>
      )}
    </>
  );
}

export default DropdownMenuItem;
