import React, { Suspense, useContext, useEffect } from 'react'
import ReactTooltip from 'react-tooltip';

import Loader from "../Loader";
import { accountContext } from '../../context/AccountContext';
import NavBar from '../AdminLayout/NavBar';
import Aux from '../../hoc/_Aux';
import Navigation from '../AdminLayout/Navigation';
import { routes } from './routes';
import LeftNavigation from '../AdminLayout/Navigation/LeftNavigation';
import MainHeader from '../AdminLayout/MainHeader/MainHeader';

const WrapperComponent = ({ routePath, title = '' }) => {
    const { loading, selectedAccount, selectedAccountDetails } = useContext(accountContext);

    useEffect(() => {
        document.title = `${title}`
    }, [])

    if (!loading && selectedAccount) {
        return (<Aux>
            <ReactTooltip id="global" effect="solid" />
            <LeftNavigation selectedAccountDetails={selectedAccountDetails} />
            <MainHeader />
            <div className="pcoded-main-container custom-main-container">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <Suspense fallback={<Loader />}>
                                        {
                                            routes.map(route => (
                                                <route.Component key={route.key} {...routePath} />
                                            ))
                                        }
                                    </Suspense>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Aux>);
    }
    else
        return (<div></div>);
}

export default WrapperComponent