import React from 'react';
import { Modal } from "react-bootstrap";

import { TrackedButton } from "./Tracked";

export default function CustomModal({ centered = true, children, heading = "", size = "lg", show = false, footerActions = [], footerLeftActions = [], onHide, style, bodyStyle = {}, footerStyle = {}, headerRightComponents = [] }) {

    // Footer
    const showLeftFooter = footerLeftActions.length > 0
    const showRightFooter = footerActions.length > 0
    const shouldShowFooter = showLeftFooter || showRightFooter

    return (
        <Modal centered={centered} scrollable={true} className="fast-modal" size={size} show={show} onHide={onHide} style={style} backdrop="static" contentClassName='custom-modal-content-overrides'>
            {/* Header */}
            <_CustomModalHeader {...{ headerRightComponents, heading }} />

            {/* Body */}
            <Modal.Body style={{ backgroundColor: "#f7f9fc", ...bodyStyle }}>
                <div>
                    {children}
                </div>
            </Modal.Body>

            {/* Footer */}
            {
                shouldShowFooter &&
                <Modal.Footer style={{ padding: '10px', paddingRight: '3px', ...footerStyle }}>
                    <_CustomModalFooter {...{ showLeftFooter, showRightFooter, footerLeftActions, footerActions }} />
                </Modal.Footer>
            }
        </Modal>
    )
}

const _CustomModalHeader = ({ headerRightComponents = [], heading }) => {
    return <Modal.Header closeButton>
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Modal.Title as='h5'>{heading}</Modal.Title>
            {headerRightComponents}
        </div>
    </Modal.Header>
}


const _CustomModalFooter = ({ showLeftFooter, showRightFooter, footerLeftActions, footerActions }) => {

    const FooterItems = ({ actions, uid, secondary = false }) => {
        const btnVariant = (variant) => {
            if (variant)
                return variant
            if (secondary)
                return 'secondary'
            return 'primary'
        }
        return <div>
            {
                actions.map(({ onClick, label, eventLabel, variant, disabled = false }, i) => (
                    <TrackedButton eventLabel={eventLabel ?? label} variant={btnVariant(variant)} size="sm" key={`${uid}-${i}`} onClick={onClick} disabled={disabled}>{label}</TrackedButton>
                ))
            }
        </div>
    }

    return (
        <div style={{ width: "100%", display: "flex", justifyContent: !showLeftFooter ? "flex-end" : "space-between" }}>
            {/* Left Footer */}
            {showLeftFooter && <FooterItems actions={footerLeftActions} uid="c-modal-left-action" secondary />}

            {/* Right Footer */}
            {showRightFooter && <FooterItems actions={footerActions} uid="c-modal-right-action" />}
        </div>
    )
}

// ---------- Without Bootstrap's Modal Component ------------

// export default function CustomModal({ children, heading = "", size="lg", show = false, footerActions=[], onHide }) {
//     return (
// <div className={`c-modal-container smooth-transition ${show ? 'smooth-transition-animate' : ''}`}>
//     <div className={`c-modal-box c-modal-box-lg`}>
//         <div className={`c-modal-header`}>
//             <span>{heading}</span>
//             <div className="c-modal-header-close" onClick={onHide}><i className="feather icon-x"  /></div>
//         </div>
//         <div className={`c-modal-body`}>
//             {children}
//         </div>
//         <div className={`c-modal-footer`}>
//             <Button size="sm" className="m-0" variant="light" onClick={onHide}>Close</Button>
//             {
//                 footer ? footer : <></>
//             }
//         </div>
//     </div>
// </div>
// )}