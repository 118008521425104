import API from "./api";

export class ErrorBoundaryService extends API {
    constructor() {
        super('ui_error');
    }

    async sendErrorReport(error, errorMessage, url, params) {
        return await this._post({
            body: { error, error_log: errorMessage, url, params },
        })
    }
}