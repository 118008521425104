import React from "react";
import { NavLink } from "react-router-dom";

function TabButtons({
  tabs,
  activeTab,
  containerClasses = "",
  onTabChange,
  useNavLink = false,
}) {

  return (
    <div className={`tw-flex tw-gap-4 tw-border-b ${containerClasses}`}>
      {Object.entries(tabs).map(([key, tab]) => {
        // When `useNavLink` is true, render NavLink; otherwise, render a button
        return useNavLink ? (
          <NavLink
            key={key}
            to={tab.route}
            className={({ isActive }) =>
              `tw-pt-2 tw-pb-2.5 tw-text-left tw-text-xs tw-capitalize tw-font-medium focus:!tw-outline-0 ${
                isActive || activeTab.name === tab.name
                  ? "tw-border-b-2 tw-border-brand-blue tw-text-brand-blue"
                  : "tw-text-brand-text-gray"
              }`
            }
          >
            {tab.name.toLowerCase()}
          </NavLink>
        ) : (
          <button
            key={key}
            className={`tw-pt-2 tw-pb-2.5 tw-text-left tw-text-xs tw-capitalize tw-font-medium focus:!tw-outline-0 ${
              activeTab.name === tab.name
                ? "tw-border-b-2 tw-border-brand-blue tw-text-brand-blue"
                : "tw-text-brand-text-gray"
            }`}
            onClick={() => onTabChange(tab)}
          >
            {tab.name.toLowerCase()}
          </button>
        );
      })}
    </div>
  );
}

export default TabButtons;