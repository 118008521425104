import { get_env } from "../Util"

const _isSharedEnv = get_env() == 'shared' 

/**
 * Adds heade key-values exctracted from browser URL's path
 * @param {Headers} headerObject 
 */
export const appendHeaderFromAppURLPath = (headerObject) => {
    let paths = window.location.pathname || "/"
    paths = paths.split("/").slice(1)
    if(_isSharedEnv)
        headerObject.append("X-SHARED-ENTITY-ID", paths[1])
}

export const getRedirectUrl = () => {
    const search = window.location.search
    if (search.length > 0) {
        let queries = new URLSearchParams(search);
        return atob(queries.get('redirect') || btoa("/"))
    }
    return "/"
}