import { COLORS } from "../constants"

export const TARGETING_METHOD_VALUE_TO_LABEL = {
    "behavioral": "Targeted",
    "untargeted": "Untargeted"
}

export const TARGETING_METHOD_TEXT_COLOR = {
    "behavioral": COLORS["green"],
    "untargeted": COLORS["red"],
}