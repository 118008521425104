import React, {useState, useEffect} from 'react';

export default function TextInput({v, updateOnChange=false, onBlur, onEnterPress, onChange, refs, isNumberInput=false, readonly=null, ...props}) {

    const [val, setVal] = useState()
    const [editing, setEditing] = useState(false)

    const style = {
        border: "1px solid #ced4da8a",
        ...(props.style || {})
    }
    props.style = style

    const handleOnBlur = () => {
        if (onChange) {
            onChange(val)
        }
        if (onBlur) {
            onBlur(val)
        }
        setEditing(false)
    }

    useEffect(() => {
        setVal(v)
    }, [editing, v])

    const handleChange = (e) => {
        if (isNumberInput){
            e.target.value = e.target.value.replace(/^(?:0+(?=[1-9])|0+(?=0$))/mg, '');
        }
        setVal(e.target.value)
        if (updateOnChange) {
            onChange(e.target.value)
        }
    }

    const checkEnter = (e) => {
        if (e.key === 'Enter' && onEnterPress) {
            onEnterPress(e.target.value)
        }
    }
    return (
        <input ref={refs} className='form-control' type={"text"} value={val} onChange={handleChange} readonly={readonly} {...props} onKeyUp={checkEnter} onBlur={handleOnBlur} onFocus={() => setEditing(true)} />
    )
}