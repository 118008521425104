import React from 'react';

const GuestSignIn = React.lazy(() => import('./features/GuestSignIn'));
const SignIn = React.lazy(() => import('./features/SignIn/SignIn'));
const SignUp = React.lazy(() => import('./features/SignUp/SignUp'));
const ResetEmail = React.lazy(() => import('./features/forgot_password/ResetEmail'))
const ResetPassword = React.lazy(() => import('./features/forgot_password/ResetPassword'))
const ResetNewPassword = React.lazy(() => import('./features/forgot_password/NewPassword'))
const OtpVerification = React.lazy(() => import('./features/otp_verification/otp_verification'))

const routes = {
    "app": [
        { path: '/auth/signin', exact: true, name: 'SignIn', component: SignIn },
        { path: '/auth/signup', exact: true, name: 'SignUp', component: SignUp, defaultProps: { 'invite': false } },
        { path: '/auth/signup/:orgId/:userId/:token', exact: true, name: 'SignUp', component: SignUp, defaultProps: { 'invite': true } },
        { path: '/auth/reset-email', exact: true, name: 'ResetEmail', component: ResetEmail },
        { path: '/auth/reset-password/:id', exact: true, name: 'ResetEmail', component: ResetNewPassword },
        { path: '/auth/:email/:token', exact: true, name: 'ResetPassword', component: ResetPassword },
        { path: '/auth/signin-otp', exact: true, name: 'OtpVerification', component: OtpVerification }
    ],
    "shared": [
        { path: '/guest/auth/signin', exact: true, name: 'Guest SignIn', component: GuestSignIn }
    ]
};

export default routes;