import React, { useState } from "react";

import PropTypes from "prop-types";

import { AdbListBox, AdbListBoxButton, AdbListBoxOptions } from "./AdbListBox";
import TabButtons from "./TabButtons";
import AdbDropdown from "./AdbDropdown";

function TabsWithContent({tabsConfig, handleTabChange, activeTab}) {
  return (
    <div>
      {/* Tabs */}
      <TabButtons
        tabs={tabsConfig}
        onTabChange={handleTabChange}
        activeTab={activeTab}
        containerClasses="tw-px-3.5"
      />

      {/* Conent */}
      {activeTab.content}
    </div>
  )
}

/**
 * Adds dropdown button which opens a menu with tabs
 * To make this dropdown behave as listbox pass selectedOption and onOptionSelect props.
 * To make this dropdown behave as normal menu do not pass selectedOption and onOptionSelect props.
 */
function DropdownWithTabs({
  tabsConfig,
  selectedOption,
  onOptionSelect,
  dropdownItemsProps,
  btnConfig,
}) {
  const [activeTab, setActiveTab] = useState(Object.values(tabsConfig)[0]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="tw-relative tw-inline-block tw-min-w-60 tw-text-left">
      {onOptionSelect && (
        <AdbListBox value={selectedOption} onChange={onOptionSelect}>
          <div className="tw-relative">
            <AdbListBoxButton
              buttonStyles={`tw-w-full tw-px-3 tw-py-[5px] tw-text-left tw-text-sm tw-font-medium tw-text-gray-700 tw-shadow-sm ${btnConfig.buttonOptions?.buttonStyles}`}
              defaultIconStyles={`!tw-ml-2 ${btnConfig.buttonOptions?.defaultIconStyles}`}
              {...btnConfig.buttonOptions}
            >
              {selectedOption
                ? btnConfig.buttonJSXWhenOptionSelected
                : btnConfig.buttonJSXDefault}
            </AdbListBoxButton>

            <AdbListBoxOptions>
              <TabsWithContent
                tabsConfig={tabsConfig}
                handleTabChange={handleTabChange}
                activeTab={activeTab}
              />
            </AdbListBoxOptions>
          </div>
        </AdbListBox>
      )}
      {!onOptionSelect && (
        <AdbDropdown
          dropdownButton={btnConfig.buttonJSXDefault}
          dropdownItemsProps={dropdownItemsProps}
        >
          <TabsWithContent
            tabsConfig={tabsConfig}
            handleTabChange={handleTabChange}
            activeTab={activeTab}
          />
        </AdbDropdown>
      )}
    </div>
  );
}

DropdownWithTabs.propTypes = {
  tabsConfig: PropTypes.object.isRequired,
  selectedOption: PropTypes.object,
  onOptionSelect: PropTypes.func,
  dropdownItemsProps: PropTypes.object,
  btnConfig: PropTypes.object.isRequired,
};

export default DropdownWithTabs;