import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom';

import './app.scss';
import Aux from '../../hoc/_Aux';
import WrapperComponent from './WrapperComponent';
import AuthedContextWrapped from '../AuthedContextWrapped';
import { useAuth } from '../../context/AuthContext';
import { getEncodedRedirectParam } from '../../utils/locationUtils';

const DefaultPage = () => {
    return <div className='d-flex justify-content-center align-items-center p-4' style={{ flexDirection: 'column', gap: 6, height: "100vh" }}>
        <div style={{ fontSize: 50 }}>
            <i className="adb-muted fa fa-inbox" />
        </div>
        <div className='adb-muted adb-heavy' style={{ fontSize: 22 }}>Empty Link</div>
    </div>
}

const GuestLayout = () => {
    const { authenticated, isGuestUser } = useAuth()

    useEffect(() => {
        if (!authenticated || !isGuestUser) {
            window.location = `/guest/auth/signin?${getEncodedRedirectParam()}`;
        }
    }, [])

    return (
        <Aux>
            <Switch>
                <Route
                    path={'/default'}
                    exact={true}
                    name={'Empty'}
                    render={(props) => (
                        <DefaultPage />
                    )} />
                <Route
                    path={'/s/:sharedEntityId'}
                    exact={true}
                    name={'Dashboard'}
                    render={(props) => (
                        <Redirect to={`/s/${props.match.params.sharedEntityId}/dashboard`} />
                    )} />
                <Route
                    path={'/s/:sharedEntityId/dashboard/:page?'}
                    exact={true}
                    name={'Dashboard'}
                    render={(props) => (
                        <AuthedContextWrapped>
                            <WrapperComponent routePath={props} title='Dashboard'/>
                        </AuthedContextWrapped>
                    )} />
                <Redirect to={'/default'} />
            </Switch>
        </Aux>

    )
}

export default GuestLayout