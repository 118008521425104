import React, {useState, useRef} from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Aux from '../../hoc/_Aux';
import { isNumeric } from '../../Util';
import TextInput from './input';
import HelpTip from './help_tooltip';
import TableCellText from './TableCellText';
import Spinner from './spinner';

export default function TextEdit({value, anchorStyles={}, submitOnBlur=false, onChangeSubmit, popOverText, formatValue, id, singleTooltipID, textInputWidth="70px", textInputStyles={}, text , editIcon=false, link, wrap=false, popover=false, type="text"}) {
    const [editing, setEditing] = useState(false)
    const inputRef = useRef(null)
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (value) => {
            setLoading(true)
            await onChangeSubmit(value, id)
            setEditing(false)
            setLoading(false)
    }

    const handleClick = () => {
        setEditing(true);
        setTimeout(() => {
            if (inputRef && inputRef.current) {
                inputRef.current.focus()
            }
        }, 100);
    }

    let anchorProps = {}

    if (link) 
        anchorProps.href = link
    else
        anchorProps.onClick = handleClick

    const getAnchor = () => {
        const a =   <a className='inline-edit-anchor' style={{ cursor: "pointer", ...anchorStyles }}  {...anchorProps}>{value}</a>
        if (popover) {
            return <HelpTip id={singleTooltipID ? singleTooltipID: value} place="top" text={value} inPlace={singleTooltipID ? false : true}>{a}</HelpTip>
        } else {
            return a
        }
    }
    return (
        <div style={editIcon ? {display: "flex"} : {}}>
            {loading && <Spinner small={true} />}
            {!editing && !loading && value !== undefined && (
                wrap ? <TableCellText>
                    {getAnchor()}
                </TableCellText> : getAnchor()
            )}
            {editing && !loading && value !== undefined && (
                <TextInput type={type} refs={inputRef} style={{ padding: "2px", width: textInputWidth, ...textInputStyles }} v={value} onBlur={(val) => submitOnBlur ? handleSubmit(val) : setEditing(false)} onEnterPress={(val) => handleSubmit(val)} />
            )}
            {    
                editIcon && !editing && ( 
                    <span onClick={handleClick} style={{color: "#48494a",marginLeft: '0.4rem', cursor: "pointer"}} >
                        <HelpTip id="edit-icon" place="right" text="Edit">
                            <i className="feather icon-edit-2" id="edit-icon"></i>
                        </HelpTip>
                    </span>)
            }
            {popOverText && <OverlayTrigger placement="right" overlay={
                <Popover id="popover-basic" style={{ "max-width": "200px" }}>
                    <Popover.Content>
                        { popOverText }
                    </Popover.Content>
                </Popover>}>
                <i class="feather icon-info" style={{ "margin-left": "5px" }}></i>
            </OverlayTrigger>}
        </div>
    )
} 