import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { Dropdown, Form, Row, Col, Modal, Button } from 'react-bootstrap';

import Aux from "../../../../hoc/_Aux";
import DEMO from "../../../../store/constant";

import { useAuth } from "../../../../context/AuthContext";
import { useHistory, useLocation, Link } from 'react-router-dom';
import { getCampaigns, isSharedEnv } from "../../../../service";
import Loader from '../../../../layout/Loader';
import { accountContext } from '../../../../context/AccountContext';
import { DateRangePicker, defaultStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useDateRange } from '../../../../context/DateRangeContext';
import moment from 'moment';
import ReactCountryFlag from 'react-country-flag';
import { getReactFlagCountryCode } from '../../../../Util';
import Support from './Support';
import { useNavContext } from '../../../../context/NavContext';
import { EVENT_ACTIONS, useUsageTracking } from '../../../../context/UsageTrackingContext';
import TypeLabel from '../../../Components/account_type';
import styled from 'styled-components';
import HelpTip from '../../../Components/help_tooltip';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import CustomModal from '../../../Components/CustomModal';
import SearchBox from '../../../Components/search_box';
import { subDays, differenceInCalendarDays, endOfDay, startOfDay, addDays, isSameDay } from 'date-fns';

const StyledModal = styled(Modal)`
    .modal-dialog{
        max-width: 910px;
    }
`


const CustomDateRangePicker = ({rawFromDate, rawToDate, onChange, hide, staticRanges}) => {
    
    const [rangeStart, setRangeStart] = useState(rawFromDate);
    const [rangeEnd, setRangeEnd] = useState(rawToDate);
    const prevDateRange = useRef({ start: rawFromDate, end: rawToDate })

    // const handleDateRangeModalShow = () => {
    //     // Save a copy of current range to be referenced later.
    //     prevDateRange.current = { start: rangeStart, end: rangeEnd }
    //     setShowDateRangeModal(true)
    // }

    const handleDateRangeModalHide = () => {
        onChange(rangeStart, rangeEnd);
        hide()
    }

    /**
     * Reverts newly made changes to the previous one.
     */
    const cancelDateRangeModalChanges = () => {
        hide()
        setRangeStart(prevDateRange.current.start);
        setRangeEnd(prevDateRange.current.end);
    }

    const handleDateRangeChange = (data) => {
        if (data?.startDate != rangeStart) setRangeStart(data?.startDate);
        if (data?.endDate != rangeEnd) setRangeEnd(data?.endDate);
    }

    const inputRanges = [
        {
            label: 'days up to today',
            range(value, p) {
              const skipDaysCurrentValue = p.inputRanges[1].getCurrentValue(p.range)
              return {
                startDate: addDays(startOfDay(new Date()), (Math.max(Number(value), 1) - 1) * -1),
                endDate: subDays(endOfDay(new Date()), (Math.max(Number(skipDaysCurrentValue), 0) ))
              };
            },
            getCurrentValue(range) {
            //   console.log({range})
            //   if (!isSameDay(range.endDate, endOfDay(new Date()))) return '-';
              if (!range.startDate) return '∞';
              return differenceInCalendarDays(endOfDay(new Date()), range.startDate) + 1;
            }
        },
        {
            label: 'latest days excluded',
            range(value, x) {
                const r = {
                    startDate: x.range.startDate,
                    endDate: subDays(endOfDay(new Date()), (Math.max(Number(value), 0) )),
                };
                // console.log({value, rawFromDate, rawToDate, x, r})
                return r
            },
            getCurrentValue(range) {
                const ret = differenceInCalendarDays(startOfDay(new Date()), range.endDate);
                // console.log({ret})
                return ret
            },
            }
    ]

    return (
        <StyledModal show={true} size="lg" onHide={cancelDateRangeModalChanges}>
            <div style={{ padding: '6px', display: 'flex', justifyContent: 'center' }}>
                <DateRangePicker onChange={item => handleDateRangeChange(item.dateRange)}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={[{ startDate: rangeStart, endDate: rangeEnd, key: 'dateRange' }]}
                    direction="horizontal"
                    staticRanges={staticRanges || defaultStaticRanges}
                    inputRanges={inputRanges}
                />
            </div>
            <Modal.Footer>
                <Button variant='light' size='sm' onClick={cancelDateRangeModalChanges}>Cancel</Button>
                <Button variant='primary' size='sm' onClick={handleDateRangeModalHide}>Save</Button>
            </Modal.Footer>
        </StyledModal>
    );
}
function NavRight(props) {

    // const [listOpen, setListOpen] = useState(false);
    const { signout, user = {}, isUserAdmin } = useAuth();
    const history = useHistory();
    const location = useLocation();
    const { rawFromDate, rawToDate, updateDateRange, dateRangeLabel, prevRawFromDate, prevRawToDate, updatePrevDateRange, prevDateRangeLabel, prevDateRangeDefaultStaticRanges, currentDateRangeStaticRanges } = useDateRange()
    const [accountDropdownSearchText, setAccountDropdownSearchText] = useState("")
    const { loading, accounts, selectedAccount, switchAccount, selectedAccountDetails } = useContext(accountContext);
    const [showDateRangeModal, setShowDateRangeModal] = useState(false);
    const [showPrevDateRangeModal, setShowPrevDateRangeModal] = useState(false);
    const { setHelpToolTipRef } = useNavContext();
    const params = new URLSearchParams(window.location.search);
    const showHelp = params.get('showHelp')
    const [listOpen, setListOpen] = useState(false)
    const { sendEvent } = useUsageTracking()
    const MySwal = withReactContent(Swal);
    useEffect(() => {
        if (showHelp) {
            setListOpen(true)
        }
    }, [showHelp])

    const handleAccountSwitch = (id, account_name) => {
        switchAccount(id, account_name);
        window.location.reload(); // if they are on edit pages this will probably give them an error..
    }

    let navItemClass = ['nav-item'];
    if (props.windowWidth <= 575) {
        navItemClass = [...navItemClass, 'd-none'];
    }
    let dropdownRightAlign = false;
    if (props.rtlLayout) {
        dropdownRightAlign = true;
    }

    const handleHelpIconClick = () => {
        setListOpen(true);
        sendEvent(EVENT_ACTIONS.MODAL_OPEN, "HELP_SIDEBAR")
    }

    const handleSuccess = (message) => {
        MySwal.fire({
            text: message,
            icon: "success",
            type: 'success'
        })
    }


    const handleForceRefresh = async () => {
        MySwal.fire({
            title: 'Are you sure?',
            text: "This will fetch your latest campaign data (not performance data) from Amazon. If you've already recently force refreshed, please wait at least 15 minutes before forcing another refresh.",
            icon: "info",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'Yes'
        }).then((apply) => {
            if (apply.value) {
                getCampaigns(undefined, undefined, undefined, undefined, undefined, true)
                sendEvent(EVENT_ACTIONS.BUTTON_CLICK, "FORCE_REFRESH")
                handleSuccess("Your force refresh of data has been successfully scheduled. It may take sometime for the updated data to reflect.")
            }
        })
    }

    const [isAccountsDropdownOpen, setIsAccountsDropdownOpen] = useState(false)
    const handleAccountDropdownToggle = (isOpen) => {
        setIsAccountsDropdownOpen(isOpen)
    }

    const adbrewUserNavOptions = useMemo(() => (
        <Aux>
            <li style={{ marginLeft: '5px' }}>
                <HelpTip id="tip1" text={"Force refresh"} place="bottom">
                    <a href={DEMO.BLANK_LINK} onClick={handleForceRefresh} className="displayChatbox"><i className="icon feather icon-refresh-cw mr-2" /></a>
                </HelpTip>
            </li>
            <li className={props.rtlLayout ? 'm-r-15' : 'm-l-0'}>
                <HelpTip id="tip2" text={"Help & Support"} place="bottom">
                    <a ref={ref => setHelpToolTipRef(ref)} href={DEMO.BLANK_LINK} className="displayChatbox" onClick={handleHelpIconClick}><i className="icon feather icon-help-circle mr-2" style={{ fontSize: 14 }} /></a>
                </HelpTip>
            </li>
            <li>
                <Dropdown alignRight={!props.rtlLayout} className="drp-user header-drpdwn">
                    <Dropdown.Toggle variant={'link'} id="dropdown-basic">
                        <i className="icon feather icon-user" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu renderOnMount={true} alignRight className="profile-notification">
                        <div className="pro-head" style={{background: "linear-gradient(211deg, #c0c7ed -28%, #455DDC 100%)"}}>
                            {/* <img src={Avatar1} className="img-radius" alt="User Profile"/> */}
                            <span>{user ? user.name : ""}</span>
                            <a href={DEMO.BLANK_LINK} onClick={signout} className="dud-logout" title="Logout">
                                <i className="feather icon-log-out" />
                            </a>
                        </div>
                        <ul className="pro-body">
                            <li><Link to="/accounts_manager/" className="dropdown-item"><i className="feather icon-briefcase" /> Accounts Manager</Link></li>
                            {isUserAdmin && <li><Link to="/user_manager/" className="dropdown-item"><i className="feather icon-users" /> User Manager</Link></li>}
                            <li><a href="/profile" className="dropdown-item"><i className="feather icon-user" /> Profile</a></li>
                            <li><a href="/account_setup" className="dropdown-item"><i className="feather icon-settings" /> Account Setup</a></li>
                        </ul>
                    </Dropdown.Menu>
                </Dropdown>
            </li>
        </Aux>
    ), [props.rtlLayout, user, isUserAdmin, signout, handleHelpIconClick, handleForceRefresh])

    return (
        <Aux>
            <ul className="navbar-nav ml-auto">
                {!location.pathname.startsWith("/amc/reports") && 
                    <li>
                        <Form.Group controlId="b">
                            <Button style={{ marginRight: "5px", fontSize: '11px', padding: "6px 10px", backgroundColor: 'white', border: "solid 2px lightgrey", borderRadius: 20 }} variant="primary" disabled={loading} onClick={() => setShowPrevDateRangeModal(true)}>
                                <i className="feather icon-calendar" style={{ marginRight: "1px" }} />
                                Compare With: <span style={{ fontWeight: "600" }}>{prevDateRangeLabel || `${moment(prevRawFromDate).format("DD-MM-YYYY")} - ${moment(prevRawToDate).format("DD-MM-YYYY")}`}</span>
                            </Button>
                            <Button style={{ fontSize: '11px', padding: "6px 10px", backgroundColor: 'white', border: "solid 2px lightgrey", borderRadius: 20 }} variant="primary" disabled={loading} onClick={() => setShowDateRangeModal(true)}>
                                <i className="feather icon-calendar" style={{ marginRight: "1px" }} />
                                Current: <span style={{ fontWeight: "600" }}>{dateRangeLabel || `${moment(rawFromDate).format("DD-MM-YYYY")} - ${moment(rawToDate).format("DD-MM-YYYY")}`}</span>
                            </Button>
                        </Form.Group>
                        {/* { <CustomModal 
                            style={{ width: '900px' }}
                            bodyStyle={{ margin: 0, padding: 0 }}
                            heading={"Select Date Range"} size="lg" 
                            show={showDateRangeModal} onHide={cancelDateRangeModalChanges} 
                            footerActions={[{ onClick: cancelDateRangeModalChanges, label: 'Cancel', variant: 'light'}, { onClick: handleDateRangeModalHide, label: 'Save', variant: 'primary'}]}>
                            <div style={{ padding: '6px', display: 'flex', justifyContent: 'center' }}>
                                <DateRangePicker onChange={item => handleDateRangeChange(item.dateRange)}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={[{ startDate: rangeStart, endDate: rangeEnd, key: 'dateRange' }]}
                                    direction="horizontal" />
                            </div>
                        </CustomModal> } */}
                        {showPrevDateRangeModal && <CustomDateRangePicker staticRanges={prevDateRangeDefaultStaticRanges} rawFromDate={prevRawFromDate} rawToDate={prevRawToDate} onChange={updatePrevDateRange} hide={() => setShowPrevDateRangeModal(false)} />}
                        {showDateRangeModal && <CustomDateRangePicker staticRanges={currentDateRangeStaticRanges} rawFromDate={rawFromDate} rawToDate={rawToDate} onChange={updateDateRange} hide={() => setShowDateRangeModal(false)} />}
                    </li>
                }
                {accounts.length > 0 && <li className={navItemClass.join(' ')} style={{ paddingRight: 24 }}>
                    <Dropdown onToggle={handleAccountDropdownToggle} alignRight={dropdownRightAlign} className="header-drpdwn">
                        <Dropdown.Toggle variant={'link'} id="dropdown-basic">
                            <ReactCountryFlag svg countryCode={getReactFlagCountryCode((selectedAccountDetails || {}).country_code)} style={{ marginTop: '-2px', marginRight: '5px', fontSize: '1.3em', lineHeight: '1.3em' }} aria-label={(selectedAccountDetails || {}).country_code} />
                            <TypeLabel t={selectedAccountDetails?.profile_account_type || selectedAccountDetails?.type} />
                            <span style={{ fontSize: 13, fontWeight: "600" }}>{selectedAccount}</span>
                        </Dropdown.Toggle>
                        <ul>
                            <Dropdown.Menu renderOnMount={true} style={{ maxHeight: "270px", overflowY: "auto" }}>
                                {loading && <Loader />}
                                {isAccountsDropdownOpen && accounts.length > 3 && <div style={{width: "90%", margin: "0 auto", marginBottom: "10px"}}><SearchBox autoFocus={true} placeholder="Account Name" value={accountDropdownSearchText} onChange={(val) => setAccountDropdownSearchText(val)} /></div>}
                                {accounts.filter(({account_name=''}) => (account_name.toLowerCase().includes(accountDropdownSearchText.toLowerCase()))).map(({ id, account_name, country_code, profile_account_type, type }) => (
                                    <li key={id}>
                                        <a key={id} className="dropdown-item" href={DEMO.BLANK_LINK} onClick={() => handleAccountSwitch(id, account_name)}>
                                            <ReactCountryFlag svg countryCode={getReactFlagCountryCode(country_code)} style={{ marginTop: '-2px', marginRight: '5px', fontSize: '1.3em', lineHeight: '1.3em' }} aria-label={country_code} /> <TypeLabel t={profile_account_type || type} /> {account_name}
                                        </a>
                                    </li>
                                ))}
                            </Dropdown.Menu>
                        </ul>
                    </Dropdown>
                </li>}

                {/* 
                <li>
                    <Dropdown alignRight={!props.rtlLayout}>
                        <Dropdown.Toggle variant={'link'} id="dropdown-basic">
                            <i className="icon feather icon-bell"/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight className="notification">
                            <div className="noti-head">
                                <h6 className="d-inline-block m-b-0">Notifications</h6>
                                <div className="float-right">
                                    <a href={DEMO.BLANK_LINK} className="m-r-10">mark as read</a>
                                    <a href={DEMO.BLANK_LINK}>clear all</a>
                                </div>
                            </div>
                            <ul className="noti-body">
                                <li className="n-title">
                                    <p className="m-b-0">NEW</p>
                                </li>
                                <li className="notification">
                                    <div className="media">
                                        <img className="img-radius" src={Avatar1} alt="Generic placeholder"/>
                                        <div className="media-body">
                                            <p><strong>John Doe</strong><span className="n-time text-muted"><i
                                                className="icon feather icon-clock m-r-10"/>30 min</span></p>
                                            <p>New ticket Added</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="n-title">
                                    <p className="m-b-0">EARLIER</p>
                                </li>
                                <li className="notification">
                                    <div className="media">
                                        <img className="img-radius" src={Avatar2} alt="Generic placeholder"/>
                                        <div className="media-body">
                                            <p><strong>Joseph Williams</strong><span className="n-time text-muted"><i
                                                className="icon feather icon-clock m-r-10"/>30 min</span></p>
                                            <p>Prchace New Theme and make payment</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="notification">
                                    <div className="media">
                                        <img className="img-radius" src={Avatar3} alt="Generic placeholder"/>
                                        <div className="media-body">
                                            <p><strong>Sara Soudein</strong><span className="n-time text-muted"><i
                                                className="icon feather icon-clock m-r-10"/>30 min</span></p>
                                            <p>currently login</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div className="noti-footer">
                                <a href={DEMO.BLANK_LINK}>show all</a>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
                */}

                {isSharedEnv ? <li style={{ paddingRight: 24 }}>
                    <a onClick={signout} className="dud-logout" title="Logout" style={{ cursor: "pointer" }}>
                        <i className="feather icon-log-out" />
                    </a>
                </li> : adbrewUserNavOptions}
            </ul>
            <Support listOpen={listOpen} closed={() => { setListOpen(false) }} />
        </Aux>
    );
}

export default NavRight;
