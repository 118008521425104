import PropTypes from "prop-types";
import React, { cloneElement } from "react";

/**
 * To display User avatar with provided image or Default avatar with provided icon.
 */
function AdbAvatar({ image, imageSize, iconStyles, bgStyles }) {
  imageSize = {
    imgSize: "tw-h-9 tw-w-9",
    iconSize: "tw-h-4 tw-w-4",
    ...imageSize,
  };

  iconStyles = iconStyles || "tw-stroke-brand-blue";

  let icon = typeof image !== "string";

  return (
    <>
      {!icon && (
        <img
          alt=""
          src={image}
          className={`${imageSize.imgSize} ${bgStyles} tw-inline-block tw-rounded-md`}
        />
      )}
      {icon && (
        <span
          className={`${imageSize.imgSize} ${bgStyles} tw-flex tw-items-center tw-justify-center tw-rounded-md tw-bg-brand-blue-light`}
        >
          {cloneElement(image, {
            className: `${imageSize.iconSize} ${iconStyles}`,
          })}
        </span>
      )}
    </>
  );
}

AdbAvatar.propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  imageSize: PropTypes.object,
  iconStyles: PropTypes.string,
};

export default AdbAvatar;
