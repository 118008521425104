export const ENVIRONMENTS = {
    BREWING: 'brewing',
    PRODUCTION: 'production',
    DEVELOPMENT: 'development',
    SHARED: 'shared'
}

export const get_env = () => {
    if (process.env.REACT_APP_ADBREW_BREWING_STAGE !== undefined) {
        return ENVIRONMENTS.BREWING
    } else if (process.env.NODE_ENV === ENVIRONMENTS.DEVELOPMENT) {
        return ENVIRONMENTS.DEVELOPMENT
    } else if (process.env.NODE_ENV === ENVIRONMENTS.SHARED) {
        return ENVIRONMENTS.SHARED
    } else {
        return ENVIRONMENTS.PRODUCTION
    }
}